import { defineMessages } from 'react-intl';
import {
  editButton,
  deleteButton,
  newButton,
  cloneButton,
  clientLabel,
  membersLabel,
  projectLabel,
  plannedLabel,
  hourPriceLabel,
  planColumn,
  totalRow,
} from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  newButton,
  totalRow,
  cloneButton,
  clientLabel,
  membersLabel,
  projectLabel,
  plannedLabel,
  hourPriceLabel,
  planColumn,
  pageTitle: {
    id: 'finance.planning.title.planning',
    defaultMessage: 'Planning',
  },
  calculatedColumn: {
    id: 'finance.planning.column.calculated',
    defaultMessage: 'Calculated',
  },
  incomeLabel: {
    id: 'finance.planning.label.income',
    defaultMessage: 'Income',
  },
  finalizedLabel: {
    id: 'finance.planning.label.finalized',
    defaultMessage: 'Finalized',
  },
  finalHours: {
    id: 'finance.planning.label.final.hours',
    defaultMessage: 'Final. Hours',
  },
  finalIncome: {
    id: 'finance.planning.label.final.income',
    defaultMessage: 'Final. Income',
  },
  actualHoursColumn: {
    id: 'finance.planning.label.actual.hours',
    defaultMessage: 'Actual Hours',
  },
  finalizeHours: {
    id: 'finance.planning.column.finalize.hours',
    defaultMessage: 'Finalize Hours',
  },
  finalizedIncome: {
    id: 'finance.planning.column.finalized.income',
    defaultMessage: 'Finalized Income',
  },
  plannedHoursColumn: {
    id: 'finance.planning.column.planned.hours',
    defaultMessage: 'Planned Hours',
  },
  percentOfRegulatoryColumn: {
    id: 'finance.planning.column.percent.of.regulatory',
    defaultMessage: '% of regulatory',
  },
  plannedIncomeColumn: {
    id: 'finance.planning.Column.planned.income',
    defaultMessage: 'Planned Income',
  },
  calculatedIncomeLabel: {
    id: 'finance.planning.label.calculated.income',
    defaultMessage: 'Calculated Income',
  },
  baseRateLabel: {
    id: 'finance.planning.label.base.rate',
    defaultMessage: 'Base Hour price',
  },
  importResourcesBtn: {
    id: 'finance.planning.import.resources.btn.resources',
    defaultMessage: 'Resources',
  },
});
