import { formatValue } from 'react-currency-input-field';
import { CurrencyType } from '../types/finance';

export const RESET_FINANCE_ERRORS = 'RESET_FINANCE_ERRORS';

export const RESET_FINANCE_STATE = 'RESET_FINANCE_STATE';

export const GET_INCOME_TYPES_FULL_LIST = 'GET_INCOME_TYPES_FULL_LIST';

export const GET_INCOME_TYPES_LIST_REQUEST = 'GET_INCOME_TYPES_LIST_REQUEST';
export const GET_INCOME_TYPES_LIST_SUCCESS = 'GET_INCOME_TYPES_LIST_SUCCESS';
export const GET_INCOME_TYPES_LIST_FAILURE = 'GET_INCOME_TYPES_LIST_FAILURE';

export const POST_INCOME_TYPE_REQUEST = 'POST_INCOME_TYPE_REQUEST';
export const POST_INCOME_TYPE_SUCCESS = 'POST_INCOME_TYPE_SUCCESS';
export const POST_INCOME_TYPE_FAILURE = 'POST_INCOME_TYPE_FAILURE';

export const PUT_INCOME_TYPE_REQUEST = 'PUT_INCOME_TYPE_REQUEST';
export const PUT_INCOME_TYPE_SUCCESS = 'PUT_INCOME_TYPE_SUCCESS';
export const PUT_INCOME_TYPE_FAILURE = 'PUT_INCOME_TYPE_FAILURE';

export const DELETE_INCOME_TYPE_REQUEST = 'DELETE_INCOME_TYPE_REQUEST';
export const DELETE_INCOME_TYPE_SUCCESS = 'DELETE_INCOME_TYPE_SUCCESS';
export const DELETE_INCOME_TYPE_FAILURE = 'DELETE_INCOME_TYPE_FAILURE';

export const SET_INCOME_TYPES_PARAMS = 'SET_INCOME_TYPES_PARAMS';

export const GET_CURRENCIES_LIST_REQUEST = 'GET_CURRENCIES_LIST_REQUEST';
export const GET_CURRENCIES_LIST_SUCCESS = 'GET_CURRENCIES_LIST_SUCCESS';
export const GET_CURRENCIES_LIST_FAILURE = 'GET_CURRENCIES_LIST_FAILURE';

export const POST_CURRENCY_REQUEST = 'POST_CURRENCY_REQUEST';
export const POST_CURRENCY_SUCCESS = 'POST_CURRENCY_SUCCESS';
export const POST_CURRENCY_FAILURE = 'POST_CURRENCY_FAILURE';

export const PUT_CURRENCY_REQUEST = 'PUT_CURRENCY_REQUEST';
export const PUT_CURRENCY_SUCCESS = 'PUT_CURRENCY_SUCCESS';
export const PUT_CURRENCY_FAILURE = 'PUT_CURRENCY_FAILURE';

export const DELETE_CURRENCY_REQUEST = 'DELETE_CURRENCY_REQUEST';
export const DELETE_CURRENCY_SUCCESS = 'DELETE_CURRENCY_SUCCESS';
export const DELETE_CURRENCY_FAILURE = 'DELETE_CURRENCY_FAILURE';

export const SET_CURRENCIES_PARAMS = 'SET_CURRENCIES_PARAMS';

export const GET_CLIENTS_LIST_REQUEST = 'GET_CLIENTS_LIST_REQUEST';
export const GET_CLIENTS_LIST_SUCCESS = 'GET_CLIENTS_LIST_SUCCESS';
export const GET_CLIENTS_LIST_FAILURE = 'GET_CLIENTS_LIST_FAILURE';

export const POST_CLIENT_REQUEST = 'POST_CLIENT_REQUEST';
export const POST_CLIENT_SUCCESS = 'POST_CLIENT_SUCCESS';
export const POST_CLIENT_FAILURE = 'POST_CLIENT_FAILURE';

export const PUT_CLIENT_REQUEST = 'PUT_CLIENT_REQUEST';
export const PUT_CLIENT_SUCCESS = 'PUT_CLIENT_SUCCESS';
export const PUT_CLIENT_FAILURE = 'PUT_CLIENT_FAILURE';

export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';

export const CHANGE_CLIENT_STATUS = 'CHANGE_CLIENT_STATUS_REQUEST';

export const SET_CLIENTS_PARAMS = 'SET_CLIENTS_PARAMS';

export const GET_SUPPLIERS_LIST_REQUEST = 'GET_SUPPLIERS_LIST_REQUEST';
export const GET_SUPPLIERS_LIST_SUCCESS = 'GET_SUPPLIERS_LIST_SUCCESS';
export const GET_SUPPLIERS_LIST_FAILURE = 'GET_SUPPLIERS_LIST_FAILURE';

export const POST_SUPPLIER_REQUEST = 'POST_SUPPLIER_REQUEST';
export const POST_SUPPLIER_SUCCESS = 'POST_SUPPLIER_SUCCESS';
export const POST_SUPPLIER_FAILURE = 'POST_SUPPLIER_FAILURE';

export const PUT_SUPPLIER_REQUEST = 'PUT_SUPPLIER_REQUEST';
export const PUT_SUPPLIER_SUCCESS = 'PUT_SUPPLIER_SUCCESS';
export const PUT_SUPPLIER_FAILURE = 'PUT_SUPPLIER_FAILURE';

export const DELETE_SUPPLIER_REQUEST = 'DELETE_SUPPLIER_REQUEST';
export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS';
export const DELETE_SUPPLIER_FAILURE = 'DELETE_SUPPLIER_FAILURE';

export const SET_SUPPLIERS_PARAMS = 'SET_SUPPLIERS_PARAMS';

export const GET_PROJECT_TYPES_LIST_REQUEST = 'GET_PROJECT_TYPES_LIST_REQUEST';
export const GET_PROJECT_TYPES_LIST_SUCCESS = 'GET_PROJECT_TYPES_LIST_SUCCESS';
export const GET_PROJECT_TYPES_LIST_FAILURE = 'GET_PROJECT_TYPES_LIST_FAILURE';

export const POST_PROJECT_TYPE_REQUEST = 'POST_PROJECT_TYPE_REQUEST';
export const POST_PROJECT_TYPE_SUCCESS = 'POST_PROJECT_TYPE_SUCCESS';
export const POST_PROJECT_TYPE_FAILURE = 'POST_PROJECT_TYPE_FAILURE';

export const PUT_PROJECT_TYPE_REQUEST = 'PUT_PROJECT_TYPE_REQUEST';
export const PUT_PROJECT_TYPE_SUCCESS = 'PUT_PROJECT_TYPE_SUCCESS';
export const PUT_PROJECT_TYPE_FAILURE = 'PUT_PROJECT_TYPE_FAILURE';

export const DELETE_PROJECT_TYPE_REQUEST = 'DELETE_PROJECT_TYPE_REQUEST';
export const DELETE_PROJECT_TYPE_SUCCESS = 'DELETE_PROJECT_TYPE_SUCCESS';
export const DELETE_PROJECT_TYPE_FAILURE = 'DELETE_PROJECT_TYPE_FAILURE';

export const SET_PROJECT_TYPES_PARAMS = 'SET_PROJECT_TYPES_PARAMS';

export const GET_EXPENSE_TYPES_FULL_LIST = 'GET_EXPENSE_TYPES_FULL_LIST';

export const GET_EXPENSE_TYPES_LIST_REQUEST = 'GET_EXPENSE_TYPES_LIST_REQUEST';
export const GET_EXPENSE_TYPES_LIST_SUCCESS = 'GET_EXPENSE_TYPES_LIST_SUCCESS';
export const GET_EXPENSE_TYPES_LIST_FAILURE = 'GET_EXPENSE_TYPES_LIST_FAILURE';

export const POST_EXPENSE_TYPE_REQUEST = 'POST_EXPENSE_TYPE_REQUEST';
export const POST_EXPENSE_TYPE_SUCCESS = 'POST_EXPENSE_TYPE_SUCCESS';
export const POST_EXPENSE_TYPE_FAILURE = 'POST_EXPENSE_TYPE_FAILURE';

export const PUT_EXPENSE_TYPE_REQUEST = 'PUT_EXPENSE_TYPE_REQUEST';
export const PUT_EXPENSE_TYPE_SUCCESS = 'PUT_EXPENSE_TYPE_SUCCESS';
export const PUT_EXPENSE_TYPE_FAILURE = 'PUT_EXPENSE_TYPE_FAILURE';

export const DELETE_EXPENSE_TYPE_REQUEST = 'DELETE_EXPENSE_TYPE_REQUEST';
export const DELETE_EXPENSE_TYPE_SUCCESS = 'DELETE_EXPENSE_TYPE_SUCCESS';
export const DELETE_EXPENSE_TYPE_FAILURE = 'DELETE_EXPENSE_TYPE_FAILURE';

export const SET_EXPENSE_TYPES_PARAMS = 'SET_EXPENSE_TYPES_PARAMS';

export const GET_SALARIES_LIST_SUCCESS = 'GET_SALARIES_LIST_SUCCESS';
export const GET_SALARIES_LIST_FAILURE = 'GET_SALARIES_LIST_FAILURE';

export const PUT_SALARY_REQUEST = 'PUT_SALARY_REQUEST';
export const PUT_SALARY_SUCCESS = 'PUT_SALARY_SUCCESS';
export const PUT_SALARY_FAILURE = 'PUT_SALARY_FAILURE';

export const DELETE_SALARY_REQUEST = 'DELETE_SALARY_REQUEST';
export const DELETE_SALARY_SUCCESS = 'DELETE_SALARY_SUCCESS';
export const DELETE_SALARY_FAILURE = 'DELETE_SALARY_FAILURE';

export const GET_SALARY_REQUEST = 'GET_SALARY_REQUEST';
export const GET_SALARY_SUCCESS = 'GET_SALARY_SUCCESS';
export const GET_SALARY_FAILURE = 'GET_SALARY_FAILURE';

export const SET_SALARIES_PARAMS = 'SET_SALARIES_PARAMS';

export const GET_FINANCE_PROJECTS_LIST_REQUEST = 'GET_FINANCE_PROJECTS_LIST_REQUEST';
export const GET_FINANCE_PROJECTS_LIST_SUCCESS = 'GET_FINANCE_PROJECTS_LIST_SUCCESS';
export const GET_FINANCE_PROJECTS_LIST_FAILURE = 'GET_FINANCE_PROJECTS_LIST_FAILURE';

export const POST_FINANCE_PROJECT_REQUEST = 'POST_FINANCE_PROJECT_REQUEST';
export const POST_FINANCE_PROJECT_SUCCESS = 'POST_FINANCE_PROJECT_SUCCESS';
export const POST_FINANCE_PROJECT_FAILURE = 'POST_FINANCE_PROJECT_FAILURE';

export const PUT_FINANCE_PROJECT_REQUEST = 'PUT_FINANCE_PROJECT_REQUEST';
export const PUT_FINANCE_PROJECT_SUCCESS = 'PUT_FINANCE_PROJECT_SUCCESS';
export const PUT_FINANCE_PROJECT_FAILURE = 'PUT_FINANCE_PROJECT_FAILURE';

export const DELETE_FINANCE_PROJECT_REQUEST = 'DELETE_FINANCE_PROJECT_REQUEST';
export const DELETE_FINANCE_PROJECT_SUCCESS = 'DELETE_FINANCE_PROJECT_SUCCESS';
export const DELETE_FINANCE_PROJECT_FAILURE = 'DELETE_FINANCE_PROJECT_FAILURE';

export const CHANGE_FINANCE_PROJECT_STATUS = 'CHANGE_FINANCE_PROJECT_STATUS';

export const SET_FINANCE_PROJECTS_PARAMS = 'SET_FINANCE_PROJECTS_PARAMS';

export const TIME_AND_MATERIAL = 'TIME_AND_MATERIAL';
export const ESTIMATE = 'ESTIMATE';

export const FINANCE_PROJECT_CONFIRMING_MESSAGE = 'Project is already in another finance project';

export const GET_INCOMES_EXPENSES_REPORT_REQUEST = 'GET_INCOMES_EXPENSES_REPORT_REQUEST';
export const GET_INCOMES_EXPENSES_REPORT_SUCCESS = 'GET_INCOMES_EXPENSES_REPORT_SUCCESS';
export const GET_INCOMES_EXPENSES_REPORT_FAILURE = 'GET_INCOMES_EXPENSES_REPORT_FAILURE';

export const SET_INCOMES_EXPENSES_REPORT_PARAMS = 'SET_INCOMES_EXPENSES_REPORT_PARAMS';

export const GET_OFFICES_BALANCE_SUCCESS = 'GET_OFFICES_BALANCE_SUCCESS';
export const GET_OFFICES_BALANCE_FAILURE = 'GET_OFFICES_BALANCE_FAILURE';

export const SET_OFFICES_BALANCE_PARAMS = 'SET_OFFICES_BALANCE_PARAMS';

export const PUT_OFFICE_BALANCE_REQUEST = 'PUT_OFFICE_BALANCE_REQUEST';
export const PUT_OFFICE_BALANCE_SUCCESS = 'PUT_OFFICE_BALANCE_SUCCESS';
export const PUT_OFFICE_BALANCE_FAILURE = 'PUT_OFFICE_BALANCE_FAILURE';

export const GET_CASH_FLOW_REPORT_SUCCESS = 'GET_CASH_FLOW_REPORT_SUCCESS';
export const GET_CASH_FLOW_REPORT_FAILURE = 'GET_CASH_FLOW_REPORT_FAILURE';

export const SET_CASH_FLOW_REPORT_PARAMS = 'SET_CASH_FLOW_REPORT_PARAMS';

export const GET_CASH_FLOW_TOTAL_SETTING_REQUEST = 'GET_CASH_FLOW_TOTAL_SETTING_REQUEST';
export const GET_CASH_FLOW_TOTAL_SETTING_SUCCESS = 'GET_CASH_FLOW_TOTAL_SETTING_SUCCESS';
export const GET_CASH_FLOW_TOTAL_SETTING_FAILURE = 'GET_CASH_FLOW_TOTAL_SETTING_FAILURE';

export const GET_CASH_FLOW_BALANCE_SETTING_REQUEST = 'GET_CASH_FLOW_BALANCE_SETTING_REQUEST';
export const GET_CASH_FLOW_BALANCE_SETTING_SUCCESS = 'GET_CASH_FLOW_BALANCE_SETTING_SUCCESS';
export const GET_CASH_FLOW_BALANCE_SETTING_FAILURE = 'GET_CASH_FLOW_BALANCE_SETTING_FAILURE';

export const PUT_CASH_FLOW_TOTAL_SETTING_REQUEST = 'PUT_CASH_FLOW_TOTAL_SETTING_REQUEST';
export const PUT_CASH_FLOW_TOTAL_SETTING_SUCCESS = 'PUT_CASH_FLOW_TOTAL_SETTING_SUCCESS';
export const PUT_CASH_FLOW_TOTAL_SETTING_FAILURE = 'PUT_CASH_FLOW_TOTAL_SETTING_FAILURE';

export const PUT_CASH_FLOW_BALANCE_SETTING_REQUEST = 'PUT_CASH_FLOW_BALANCE_SETTING_REQUEST';
export const PUT_CASH_FLOW_BALANCE_SETTING_SUCCESS = 'PUT_CASH_FLOW_BALANCE_SETTING_SUCCESS';
export const PUT_CASH_FLOW_BALANCE_SETTING_FAILURE = 'PUT_CASH_FLOW_BALANCE_SETTING_FAILURE';

export const GET_CASH_FLOW_EXPENSE_SETTING_REQUEST = 'GET_CASH_FLOW_EXPENSE_SETTING_REQUEST';
export const GET_CASH_FLOW_INCOME_SETTING_REQUEST = 'GET_CASH_FLOW_INCOME_SETTING_REQUEST';
export const GET_CASH_FLOW_SETTING_SUCCESS = 'GET_CASH_FLOW_SETTING_SUCCESS';
export const GET_CASH_FLOW_SETTING_FAILURE = 'GET_CASH_FLOW_SETTING_FAILURE';

export const PUT_CASH_FLOW_EXPENSE_SETTING_REQUEST = 'PUT_CASH_FLOW_EXPENSE_SETTING_REQUEST';
export const PUT_CASH_FLOW_INCOME_SETTING_REQUEST = 'PUT_CASH_FLOW_INCOME_SETTING_REQUEST';
export const PUT_CASH_FLOW_SETTING_SUCCESS = 'PUT_CASH_FLOW_SETTING_SUCCESS';
export const PUT_CASH_FLOW_SETTING_FAILURE = 'PUT_CASH_FLOW_SETTING_FAILURE';

export const RESET_CASH_FLOW_SETTING = 'RESET_CASH_FLOW_SETTING';

export const GET_PROFIT_LOSS = 'GET_PROFIT_LOSS';
export const GET_PROFIT_LOSS_SUCCESS = 'GET_PROFIT_LOSS_SUCCESS';
export const GET_PROFIT_LOSS_ERROR = 'GET_PROFIT_LOSS_ERROR';

export const GET_PROFIT_LOSS_REVENUE_CLIENT = 'GET_PROFIT_LOSS_REVENUE_CLIENT';
export const GET_PROFIT_LOSS_REVENUE_CLIENT_SUCCESS = 'GET_PROFIT_LOSS_REVENUE_CLIENT_SUCCESS';
export const GET_PROFIT_LOSS_REVENUE_CLIENT_ERROR = 'GET_PROFIT_LOSS_REVENUE_CLIENT_ERROR';

export const PUT_PROFIT_LOSS_REVENUE_CLIENT = 'PUT_PROFIT_LOSS_REVENUE_CLIENT';
export const PUT_PROFIT_LOSS_REVENUE_CLIENT_SUCCESS = 'PUT_PROFIT_LOSS_REVENUE_CLIENT_SUCCESS';
export const PUT_PROFIT_LOSS_REVENUE_CLIENT_ERROR = 'PUT_PROFIT_LOSS_REVENUE_CLIENT_ERROR';

export const GET_PROFIT_LOSS_OPERATING_INCOME = 'GET_PROFIT_LOSS_OPERATING_INCOME';
export const GET_PROFIT_LOSS_OPERATING_INCOME_SUCCESS = 'GET_PROFIT_LOSS_OPERATING_INCOME_SUCCESS';
export const GET_PROFIT_LOSS_OPERATING_INCOME_ERROR = 'GET_PROFIT_LOSS_OPERATING_INCOME_ERROR';

export const PUT_PROFIT_LOSS_OPERATING_INCOME = 'PUT_PROFIT_LOSS_OPERATING_INCOME';
export const PUT_PROFIT_LOSS_OPERATING_INCOME_SUCCESS = 'PUT_PROFIT_LOSS_OPERATING_INCOME_SUCCESS';
export const PUT_PROFIT_LOSS_OPERATING_INCOME_ERROR = 'PUT_PROFIT_LOSS_OPERATING_INCOME_ERROR';

export const GET_PROFIT_LOSS_NET_INCOME = 'GET_PROFIT_LOSS_NET_INCOME';
export const GET_PROFIT_LOSS_NET_INCOME_SUCCESS = 'GET_PROFIT_LOSS_NET_INCOME_SUCCESS';
export const GET_PROFIT_LOSS_NET_INCOME_ERROR = 'GET_PROFIT_LOSS_NET_INCOME_ERROR';

export const PUT_PROFIT_LOSS_NET_INCOME = 'PUT_PROFIT_LOSS_NET_INCOME';
export const PUT_PROFIT_LOSS_NET_INCOME_SUCCESS = 'PUT_PROFIT_LOSS_NET_INCOME_SUCCESS';
export const PUT_PROFIT_LOSS_NET_INCOME_ERROR = 'PUT_PROFIT_LOSS_NET_INCOME_ERROR';

export const GET_PROFIT_LOSS_GROSS_PROFIT = 'GET_PROFIT_LOSS_GROSS_PROFIT';
export const GET_PROFIT_LOSS_GROSS_PROFIT_SUCCESS = 'GET_PROFIT_LOSS_GROSS_PROFIT_SUCCESS';
export const GET_PROFIT_LOSS_GROSS_PROFIT_ERROR = 'GET_PROFIT_LOSS_GROSS_PROFIT_ERROR';

export const PUT_PROFIT_LOSS_GROSS_PROFIT = 'PUT_PROFIT_LOSS_GROSS_PROFIT';
export const PUT_PROFIT_LOSS_GROSS_PROFIT_SUCCESS = 'PUT_PROFIT_LOSS_GROSS_PROFIT_SUCCESS';
export const PUT_PROFIT_LOSS_GROSS_PROFIT_ERROR = 'PUT_PROFIT_LOSS_GROSS_PROFIT_ERROR';

export const GET_PROFIT_LOSS_REVENUE_EXPENSE = 'GET_PROFIT_LOSS_REVENUE_EXPENSE';
export const GET_PROFIT_LOSS_REVENUE_EXPENSE_SUCCESS = 'GET_PROFIT_LOSS_REVENUE_EXPENSE_SUCCESS';
export const GET_PROFIT_LOSS_REVENUE_EXPENSE_ERROR = 'GET_PROFIT_LOSS_REVENUE_EXPENSE_ERROR';

export const PUT_PROFIT_LOSS_REVENUE_EXPENSE = 'PUT_PROFIT_LOSS_REVENUE_EXPENSE';
export const PUT_PROFIT_LOSS_REVENUE_EXPENSE_SUCCESS = 'PUT_PROFIT_LOSS_REVENUE_EXPENSE_SUCCESS';
export const PUT_PROFIT_LOSS_REVENUE_EXPENSE_ERROR = 'PUT_PROFIT_LOSS_REVENUE_EXPENSE_ERROR';

export const GET_PROFIT_LOSS_TOTAL_GOODS = 'GET_PROFIT_LOSS_TOTAL_GOODS';
export const GET_PROFIT_LOSS_TOTAL_GOODS_SUCCESS = 'GET_PROFIT_LOSS_TOTAL_GOODS_SUCCESS';
export const GET_PROFIT_LOSS_TOTAL_GOODS_ERROR = 'GET_PROFIT_LOSS_TOTAL_GOODS_ERROR';

export const PUT_PROFIT_LOSS_TOTAL_GOODS = 'PUT_PROFIT_LOSS_TOTAL_GOODS';
export const PUT_PROFIT_LOSS_TOTAL_GOODS_SUCCESS = 'PUT_PROFIT_LOSS_TOTAL_GOODS_SUCCESS';
export const PUT_PROFIT_LOSS_TOTAL_GOODS_ERROR = 'PUT_PROFIT_LOSS_TOTAL_GOODS_ERROR';

export const GET_PROFIT_LOSS_FINANCIAL_REVENUE = 'GET_PROFIT_LOSS_FINANCIAL_REVENUE';
export const GET_PROFIT_LOSS_FINANCIAL_REVENUE_SUCCESS = 'GET_PROFIT_LOSS_FINANCIAL_REVENUE_SUCCESS';
export const GET_PROFIT_LOSS_FINANCIAL_REVENUE_ERROR = 'GET_PROFIT_LOSS_FINANCIAL_REVENUE_ERROR';

export const PUT_PROFIT_LOSS_FINANCIAL_REVENUE = 'PUT_PROFIT_LOSS_FINANCIAL_REVENUE';
export const PUT_PROFIT_LOSS_FINANCIAL_REVENUE_SUCCESS = 'PUT_PROFIT_LOSS_FINANCIAL_REVENUE_SUCCESS';
export const PUT_PROFIT_LOSS_FINANCIAL_REVENUE_ERROR = 'PUT_PROFIT_LOSS_FINANCIAL_REVENUE_ERROR';

export const GET_PROFIT_LOSS_COST_PROJECT = 'GET_PROFIT_LOSS_COST_PROJECT';
export const GET_PROFIT_LOSS_COST_PROJECT_SUCCESS = 'GET_PROFIT_LOSS_COST_PROJECT_SUCCESS';
export const GET_PROFIT_LOSS_COST_PROJECT_ERROR = 'GET_PROFIT_LOSS_COST_PROJECT_ERROR';

export const PUT_PROFIT_LOSS_COST_PROJECT = 'PUT_PROFIT_LOSS_COST_PROJECT';
export const PUT_PROFIT_LOSS_COST_PROJECT_SUCCESS = 'PUT_PROFIT_LOSS_COST_PROJECT_SUCCESS';
export const PUT_PROFIT_LOSS_COST_PROJECT_ERROR = 'PUT_PROFIT_LOSS_COST_PROJECT_ERROR';

export const GET_PROFIT_LOSS_FINANCIAL_EXPENSE = 'GET_PROFIT_LOSS_FINANCIAL_EXPENSE';
export const GET_PROFIT_LOSS_FINANCIAL_EXPENSE_SUCCESS = 'GET_PROFIT_LOSS_FINANCIAL_EXPENSE_SUCCESS';
export const GET_PROFIT_LOSS_FINANCIAL_EXPENSE_ERROR = 'GET_PROFIT_LOSS_FINANCIAL_EXPENSE_ERROR';

export const PUT_PROFIT_LOSS_FINANCIAL_EXPENSE = 'PUT_PROFIT_LOSS_FINANCIAL_EXPENSE';
export const PUT_PROFIT_LOSS_FINANCIAL_EXPENSE_SUCCESS = 'PUT_PROFIT_LOSS_FINANCIAL_EXPENSE_SUCCESS';
export const PUT_PROFIT_LOSS_FINANCIAL_EXPENSE_ERROR = 'PUT_PROFIT_LOSS_FINANCIAL_EXPENSE_ERROR';

export enum IncomesExpensesReportLayouts {
  TABLES = 'TABLES',
  CAHRTS = 'CAHRTS',
}

export enum IncomesTotalOptions {
  ACTUAL_TOTAL = 'ACTUAL_TOTAL',
  PLANNED_TOTAL = 'PLANNED_TOTAL',
}

export const INCOMES_EXPENSES = 'incomesExpenses';

export const getIncomesExpensesOptions = (baseCurrency: CurrencyType | undefined) => ({
  maintainAspectRatio: false,
  responsive: true,
  type: 'bar',
  categoryPercentage: 0.45,
  interaction: {
    mode: 'index' as const,
    position: 'cursor',
  },
  borderSkipped: false,
  barPercentage: 0.93,
  plugins: {
    tooltip: {
      usePointStyle: true,
      boxPadding: 5,
      yAlign: 'top' as const,
      padding: {
        right: 10,
        left: 10,
        top: 10,
        bottom: 10,
      },
      callbacks: {
        label: (context: any) => {
          return formatValue({
            value: context.parsed.y.toString(),
            suffix: ` ${baseCurrency?.name}`,
          });
        },
      },
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: 'rgba(74, 74, 74, 0.5)',
      },
    },
    y: {
      min: 0,
      suggestedMax: 8,
      ticks: {
        stepSize: 2,
        backdropPadding: 100,
        backdropColor: 'black',
        callback: function (value: any) {
          return formatValue({
            value: value.toString(),
            suffix: ` ${baseCurrency?.name}`,
          });
        },
        color: 'rgba(74, 74, 74, 0.5)',
        opacity: 0.5,
        beginAtZero: true,
      },
      grid: {
        drawBorder: false,
        color: '#F0F0F0',
      },
    },
  },
});
export const GET_TRANSACTIONS_LIST_REQUEST = 'GET_TRANSACTIONS_LIST_REQUEST';
export const GET_TRANSACTIONS_LIST_SUCCESS = 'GET_TRANSACTIONS_LIST_SUCCESS';
export const GET_TRANSACTIONS_LIST_FAILURE = 'GET_TRANSACTIONS_LIST_FAILURE';

export const POST_TRANSACTION_REQUEST = 'POST_TRANSACTION_REQUEST';
export const POST_TRANSACTION_SUCCESS = 'POST_TRANSACTION_SUCCESS';
export const POST_TRANSACTION_FAILURE = 'POST_TRANSACTION_FAILURE';

export const IMPORT_TRANSACTIONS_REQUEST = 'IMPORT_TRANSACTIONS_REQUEST';
export const IMPORT_TRANSACTIONS_SUCCESS = 'IMPORT_TRANSACTIONS_SUCCESS';
export const IMPORT_TRANSACTIONS_FAILURE = 'IMPORT_TRANSACTIONS_FAILURE';

export const PUT_TRANSACTION_REQUEST = 'PUT_TRANSACTION_REQUEST';
export const PUT_TRANSACTION_SUCCESS = 'PUT_TRANSACTION_SUCCESS';
export const PUT_TRANSACTION_FAILURE = 'PUT_TRANSACTION_FAILURE';

export const DELETE_TRANSACTION_REQUEST = 'DELETE_TRANSACTION_REQUEST';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILURE = 'DELETE_TRANSACTION_FAILURE';

export const SET_TRANSACTIONS_PARAMS = 'SET_TRANSACTIONS_PARAMS';

export const GET_FINANCE_PLANS_LIST_REQUEST = 'GET_FINANCE_PLANS_LIST_REQUEST';
export const GET_FINANCE_PLANS_LIST_SUCCESS = 'GET_FINANCE_PLANS_LIST_SUCCESS';
export const GET_FINANCE_PLANS_LIST_FAILURE = 'GET_FINANCE_PLANS_LIST_FAILURE';

export const GET_FINANCE_PLAN_REQUEST = 'GET_FINANCE_PLAN_REQUEST';
export const GET_FINANCE_PLAN_SUCCESS = 'GET_FINANCE_PLAN_SUCCESS';
export const GET_FINANCE_PLAN_FAILURE = 'GET_FINANCE_PLAN_FAILURE';

export const SET_FINANCE_PLANS_PARAMS = 'SET_FINANCE_PLANS_PARAMS';

export const POST_FINANCE_PLAN_REQUEST = 'POST_FINANCE_PLAN_REQUEST';
export const POST_FINANCE_PLAN_SUCCESS = 'POST_FINANCE_PLAN_SUCCESS';
export const POST_FINANCE_PLAN_FAILURE = 'POST_FINANCE_PLAN_FAILURE';

export const PUT_FINANCE_PLAN_REQUEST = 'PUT_FINANCE_PLAN_REQUEST';
export const PUT_FINANCE_PLAN_SUCCESS = 'PUT_FINANCE_PLAN_SUCCESS';
export const PUT_FINANCE_PLAN_FAILURE = 'PUT_FINANCE_PLAN_FAILURE';

export const CLONE_FINANCE_PLAN_REQUEST = 'CLONE_FINANCE_PLAN_REQUEST';
export const CLONE_FINANCE_PLAN_SUCCESS = 'CLONE_FINANCE_PLAN_SUCCESS';
export const CLONE_FINANCE_PLAN_FAILURE = 'CLONE_FINANCE_PLAN_FAILURE';

export const SET_CLONE_FINANCE_PLAN_CHECKED_INFO = 'SET_CLONE_FINANCE_PLAN_CHECKED_INFO';
export const RESET_CLONE_FINANCE_PLAN_CHECKED_INFO = 'RESET_CLONE_FINANCE_PLAN_CHECKED_INFO';

export const DELETE_FINANCE_PLAN_REQUEST = 'DELETE_FINANCE_PLAN_REQUEST';
export const DELETE_FINANCE_PLAN_SUCCESS = 'DELETE_FINANCE_PLAN_SUCCESS';
export const DELETE_FINANCE_PLAN_FAILURE = 'DELETE_FINANCE_PLAN_FAILURE';

export const GET_FINANCE_PLAN_EMPLOYEES_REQUEST = 'GET_FINANCE_PLAN_EMPLOYEES_REQUEST';
export const GET_FINANCE_PLAN_EMPLOYEES_SUCCESS = 'GET_FINANCE_PLAN_EMPLOYEES_SUCCESS';
export const GET_FINANCE_PLAN_EMPLOYEES_FAILURE = 'GET_FINANCE_PLAN_EMPLOYEES_FAILURE';

export const GET_EMPLOYEE_HOURS_REQUEST = 'GET_EMPLOYEE_HOURS_REQUEST';
export const GET_EMPLOYEE_HOURS_SUCCESS = 'GET_EMPLOYEE_HOURS_SUCCESS';
export const GET_EMPLOYEE_HOURS_FAILURE = 'GET_EMPLOYEE_HOURS_FAILURE';

export const RESET_EMPLOYEE_HOURS = 'RESET_EMPLOYEE_HOURS';

export const SET_FINANCE_PLAN_MONTH = 'SET_FINANCE_PLAN_MONTH';
export const SET_FINANCE_PLAN_EMPLOYEES_PARAMS = 'SET_FINANCE_PLAN_EMPLOYEES_PARAMS';

export const POST_FINANCE_PLAN_EMPLOYEE_REQUEST = 'POST_FINANCE_PLAN_EMPLOYEE_REQUEST';
export const POST_FINANCE_PLAN_EMPLOYEE_SUCCESS = 'POST_FINANCE_PLAN_EMPLOYEE_SUCCESS';
export const POST_FINANCE_PLAN_EMPLOYEE_FAILURE = 'POST_FINANCE_PLAN_EMPLOYEE_FAILURE';

export const PUT_FINANCE_PLAN_EMPLOYEE_REQUEST = 'PUT_FINANCE_PLAN_EMPLOYEE_REQUEST';
export const PUT_FINANCE_PLAN_EMPLOYEE_SUCCESS = 'PUT_FINANCE_PLAN_EMPLOYEE_SUCCESS';
export const PUT_FINANCE_PLAN_EMPLOYEE_FAILURE = 'PUT_FINANCE_PLAN_EMPLOYEE_FAILURE';

export const PUT_FINANCE_PLAN_EMPLOYEE_FINALIZE_REQUEST = 'PUT_FINANCE_PLAN_EMPLOYEE_FINALIZE_REQUEST';

export const GET_DATES_OF_EXISTING_PLANS_REQUEST = 'GET_DATES_OF_EXISTING_PLANS_REQUEST';
export const GET_DATES_OF_EXISTING_PLANS_SUCCESS = 'GET_DATES_OF_EXISTING_PLANS_SUCCESS';
export const GET_DATES_OF_EXISTING_PLANS_FAILURE = 'GET_DATES_OF_EXISTING_PLANS_FAILURE';

export const RESET_DATES_OF_EXISTING_PLANS = 'RESET_DATES_OF_EXISTING_PLANS';

export const DELETE_FINANCE_PLAN_EMPLOYEE_REQUEST = 'DELETE_FINANCE_PLAN_EMPLOYEE_REQUEST';
export const DELETE_FINANCE_PLAN_EMPLOYEE_SUCCESS = 'DELETE_FINANCE_PLAN_EMPLOYEE_SUCCESS';
export const DELETE_FINANCE_PLAN_EMPLOYEE_FAILURE = 'DELETE_FINANCE_PLAN_EMPLOYEE_FAILURE';

export const IMPORT_RESOURCES_IN_FINANCE_PLAN_REQUEST = 'IMPORT_RESOURCES_IN_FINANCE_PLAN_REQUEST';
export const IMPORT_RESOURCES_IN_FINANCE_PLAN_SUCCESS = 'IMPORT_RESOURCES_IN_FINANCE_PLAN_SUCCESS';
export const IMPORT_RESOURCES_IN_FINANCE_PLAN_FAILURE = 'IMPORT_RESOURCES_IN_FINANCE_PLAN_FAILURE';

export const SET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO = 'SET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO';
export const RESET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO =
  'RESET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO';

export const SET_PLANNED_EXPENSE_PARAMS = 'SET_PLANNED_EXPENSE_PARAMS';

export const GET_PLANNED_EXPENSE_SUCCESS = 'GET_PLANNED_EXPENSE_SUCCESS';
export const GET_PLANNED_EXPENSE_FAILURE = 'GET_PLANNED_EXPENSE_FAILURE';

export const PUT_PLANNED_EXPENSE_REQUEST = 'PUT_PLANNED_EXPENSE_REQUEST';
export const PUT_PLANNED_EXPENSE_SUCCESS = 'PUT_PLANNED_EXPENSE_SUCCESS';
export const PUT_PLANNED_EXPENSE_FAILURE = 'PUT_PLANNED_EXPENSE_FAILURE';

export const DELETE_PLANNED_EXPENSE_REQUEST = 'DELETE_PLANNED_EXPENSE_REQUEST';
export const DELETE_PLANNED_EXPENSE_SUCCESS = 'DELETE_PLANNED_EXPENSE_SUCCESS';
export const DELETE_PLANNED_EXPENSE_FAILURE = 'DELETE_PLANNED_EXPENSE_FAILURE';

export const incomesExpensesUnsavedParams = ['monthFrom', 'monthTo'];
export const financePlansUnsavedParams = ['month'];
export const salariesUnsavedParams = ['dateFrom', 'dateTo'];
export const transactionsUnsavedParams = ['dateFrom', 'dateTo'];
export const plannedExpenseUnsavedParams = ['startDate', 'endDate'];
export const officesBalanceUnsavedParams = ['dateFrom', 'dateTo'];
