import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import * as filtersActions from '../../actions/filters.actions';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { FinancePlan } from '../../enums/finance/finance.enum';
import Table from '../../components/Table';
import { FinancePlanParams } from '../../enums/params/finance.params';
import { useDataForTable } from './useDataForTable';
import ModalNewPlan from '../../components/Planning/Modals/ModalNewPlan';
import ModalDeletePlan from '../../components/Planning/Modals/ModalDeletePlan';
import ModalEditPlan from '../../components/Planning/Modals/ModalEditPlan';
import { useParamsChange } from '../../utils/hooks.utils';
import PlansFilters from '../../components/Planning/Filters/PlansFilters';
import InlineDatePicker from '../../components/InlineDatePicker';
import moment from 'moment';
import { InlineDatePickerPeriods } from '../../components/InlineDatePicker/constants';
import { DATE_FORMAT } from '../../constants/date.constants';
import AccessChecker from '../../components/AccessChecker';
import { UPDATE_FINANCE_PLAN } from '../../constants/policies.constants';
import Pagination from '../../components/Pagination';
import ModalClonePlan from '../../components/Planning/Modals/ModalClonePlan';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterTypes } from '../../constants/filters.constants';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import { DatesOfExistingPlansParams } from '../../types/finance';
import { financePlansUnsavedParams } from '../../constants/finance.constants';
import { getBaseCurrency } from '../../utils';

function Planning({
  tableData,
  clients,
  isLoading,
  errors,
  getProjectsList,
  getClientsList,
  getCurrenciesList,
  resetState,
  resetErrors,
  getDatesOfExistingPlans,
  resetDatesOfExistingPlans,
  projects,
  datesOfExistingPlans,
  createPlan,
  setPlansParams,
  deletePlan,
  params,
  currencies,
  getCurrenciesFilter,
  getPlansList,
  editPlan,
  clonePlan,
  resetClonePlanCheckedInfo,
  clonePlanCheckedInfo,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();

  const [modalNewPlanIsOpen, setModalNewPlanIsOpen] = useState(false);
  const [modalEditPlanIsOpen, setModalEditPlanIsOpen] = useState(false);
  const [modalClonePlanIsOpen, setModalClonePlanIsOpen] = useState(false);
  const [modalDeletePlanIsOpen, setModalDeletePlanIsOpen] = useState(false);
  const [planClicked, setPlanClicked] = useState(new FinancePlan());

  useEffect(() => {
    getClientsList();
    getProjectsList();
    getCurrenciesFilter();

    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.PLANNING_FILTER);

    currentSavedFilter ? setPlansParams(new FinancePlanParams(currentSavedFilter)) : getPlansList();

    setSavedFiltersParams({ filterType: FilterTypes.PLANNING_FILTER });

    return () => {
      resetState();
    };
  }, []);

  const openNewPlanModal = useCallback(() => {
    setModalNewPlanIsOpen(true);
  }, []);

  const closeNewProjectModal = useCallback(() => {
    setModalNewPlanIsOpen(false);
    resetErrors();
  }, []);

  const closeEditPlanModal = useCallback(() => {
    setModalEditPlanIsOpen(false);
    resetErrors();
  }, []);

  const closeClonePlanModal = useCallback(() => {
    setModalClonePlanIsOpen(false);
    resetErrors();
  }, []);

  const closeDeletePlanModal = useCallback(() => {
    setModalDeletePlanIsOpen(false);
    resetErrors();
  }, []);

  const handleSort = useCallback((sortBy, direction) => setPlansParams({ sortBy, direction }), []);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const { tableColumns, tableActions, tableHeaderItems } = useDataForTable(
    baseCurrency,
    setPlanClicked,
    setModalEditPlanIsOpen,
    setModalDeletePlanIsOpen,
    setModalClonePlanIsOpen,
  );

  const handleMultiParamsChange = useParamsChange(setPlansParams, dispatch);

  const filters = useMemo(
    () => ({
      clients,
      projects,
    }),
    [clients, projects],
  );

  const handleDataChange = useCallback((start: string) => {
    setPlansParams({ month: moment(start).format(DATE_FORMAT.YYYY_MM_DD) });
  }, []);

  const handlePageChange = useCallback(({ selected }) => {
    setPlansParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setPlansParams({ size: data, page: 0 });
  }, []);

  const convertedData = useMemo(() => {
    if (tableData && tableData?.content?.length) {
      const converted = tableData.content;
      const totalMembersPlanned = tableData.content.reduce(
        (sum: any, current: FinancePlan) => {
          return {
            workHours:
              sum.workHours +
              (current.employees
                ? current.employees.filter(item => item.workHours !== null && Number(item.workHours) > 0).length
                : 0),
            workHoursFinalized:
              sum.workHoursFinalized +
              (current.employees
                ? current.employees.filter(
                    item => item.workHoursFinalized !== null && Number(item.workHoursFinalized) > 0,
                  ).length
                : 0),
            calculatedPlannedIncome: sum.calculatedPlannedIncome + Number(current.calculatedPlannedIncome || 0),
            plannedIncome: sum.plannedIncome + Number(current.plannedIncome?.float || 0),
            finalizedIncome: sum.finalizedIncome + Number(current.finalizedIncome || 0),
          };
        },
        { workHours: 0, workHoursFinalized: 0, finalizedIncome: 0, calculatedPlannedIncome: 0, plannedIncome: 0 },
      );

      return [...converted, { total: true, hideActions: true, ...totalMembersPlanned }];
    }

    return [];
  }, [tableData, baseCurrency]);

  const handleFiltersControlChange = useCallback(
    value => {
      setPlansParams(
        new FinancePlanParams({
          ...convertSavedFieldsToParams(value.fields),
          month: params.month,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setPlansParams(new FinancePlanParams({ month: params.month }));
  }, [params]);

  const showClearButton = useMemo(() => !checkParamsMatch(params, new FinancePlanParams(), financePlansUnsavedParams), [
    params,
  ]);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__profile-panel__label-options">
              <InlineDatePicker
                onDateChange={handleDataChange}
                defaultPeriod={InlineDatePickerPeriods.MONTH_PERIOD}
                defaultPeriodStart={moment(params.month).startOf('month').format(DATE_FORMAT.YYYY_MM_DD)}
                defaultPeriodEnd={moment(params.month).endOf('month').format(DATE_FORMAT.YYYY_MM_DD)}
                isCustomDatePicker={false}
                hideTodayButton
                hidePeriodSelector
              />
            </div>
          </div>
          <div className="pagination page__panel-top__control__pagination"></div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_FINANCE_PLAN]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewPlanModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
              </div>
              <div className="page__panel-bottom__wrapper--people">
                <div className="page__panel-bottom__wrapper--left">
                  <PlansFilters
                    handleMultiParamsChange={handleMultiParamsChange}
                    filters={filters}
                    values={params}
                    createNewSavedFilter={createNewSavedFilter}
                    savedFiltersData={savedFiltersData}
                    authUserId={authUserId}
                    deleteSavedFilter={deleteSavedFilter}
                    editSavedFilter={editSavedFilter}
                    handleFiltersControlChange={handleFiltersControlChange}
                    handleClear={handleClear}
                    resetSavedFilterErrors={resetSavedFilterErrors}
                    showClearButton={showClearButton}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content active_employees_page">
        <div className="page__wrapper">
          <Table
            externalClass={'tablei plans-table'}
            tableColumns={tableColumns}
            tableData={convertedData}
            loading={isLoading.getPlansList}
            tableHeaderItems={tableHeaderItems}
            error={errors}
            tableActions={tableActions}
            onSort={handleSort}
            params={params}
          />
          <Pagination
            pageable={{
              ...tableData?.pageable,
              ...tableData?.sort,
              totalElements: tableData?.totalElements,
              numberOfElements: tableData?.numberOfElements,
              totalPages: tableData?.totalPages,
            }}
            onPageChange={(data: any) => handlePageChange(data)}
            onPageSizeChange={(data: any) => handleSizeChange(data)}
          />
        </div>
      </div>
      {modalNewPlanIsOpen && (
        <ModalNewPlan
          isOpen
          onCloseRequest={closeNewProjectModal}
          createPlan={createPlan}
          error={errors}
          isLoading={isLoading.createPlan}
          getClientsList={getClientsList}
          clients={clients}
          projects={projects}
          getProjectsList={getProjectsList}
          currencies={currencies}
          datesOfExistingPlans={datesOfExistingPlans}
          loadingDates={isLoading.getDatesOfExistingPlans}
          getCurrenciesList={getCurrenciesList}
          getDatesOfExistingPlans={getDatesOfExistingPlans}
          resetDatesOfExistingPlans={resetDatesOfExistingPlans}
        />
      )}
      {modalEditPlanIsOpen && (
        <ModalEditPlan
          isOpen
          onCloseRequest={closeEditPlanModal}
          editPlan={editPlan}
          error={errors}
          isLoading={isLoading.editPlan}
          loadingDates={isLoading.getDatesOfExistingPlans}
          datesOfExistingPlans={datesOfExistingPlans}
          getDatesOfExistingPlans={getDatesOfExistingPlans}
          resetDatesOfExistingPlans={resetDatesOfExistingPlans}
          getClientsList={getClientsList}
          clients={clients}
          projects={projects}
          getProjectsList={getProjectsList}
          financePlan={planClicked}
          currencies={currencies}
          getCurrenciesList={getCurrenciesList}
        />
      )}
      {modalClonePlanIsOpen && (
        <ModalClonePlan
          isOpen
          onCloseRequest={closeClonePlanModal}
          clonePlan={clonePlan}
          error={errors}
          isLoading={isLoading.clonePlan}
          clonePlanCheckedInfo={clonePlanCheckedInfo}
          financePlan={planClicked}
          currencies={currencies}
          loadingDates={isLoading.getDatesOfExistingPlans}
          datesOfExistingPlans={datesOfExistingPlans}
          getDatesOfExistingPlans={getDatesOfExistingPlans}
          resetDatesOfExistingPlans={resetDatesOfExistingPlans}
          getCurrenciesList={getCurrenciesList}
          resetClonePlanCheckedInfo={resetClonePlanCheckedInfo}
        />
      )}
      {modalDeletePlanIsOpen && (
        <ModalDeletePlan
          isOpen
          onCloseRequest={closeDeletePlanModal}
          onDeleteRequest={deletePlan}
          isLoading={isLoading.deletePlan}
          error={errors}
          financePlan={planClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance, filters, auth }: RootState) => ({
  errors: finance.errors.planError,
  isLoading: finance.loading,
  tableData: finance.plansListData,
  projects: filters.financeProjectsFilter.financeProjects,
  clients: filters.clientsFilter.clients,
  currencies: filters.currenciesFilter.currencies,
  params: finance.planParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
  clonePlanCheckedInfo: finance.clonePlanCheckedInfo,
  datesOfExistingPlans: finance.datesOfExistingPlans,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPlansList: () => dispatch(financeActions.getPlansList()),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),
  getDatesOfExistingPlans: (data: DatesOfExistingPlansParams) => dispatch(financeActions.getDatesOfExistingPlans(data)),
  resetDatesOfExistingPlans: () => dispatch(financeActions.resetDatesOfExistingPlans()),
  getProjectsList: () => dispatch(filtersActions.getFinanceProjectsFilter()),
  getClientsList: () => dispatch(filtersActions.getClientsFilter()),
  getCurrenciesList: () => dispatch(filtersActions.getCurrenciesFilter()),
  createPlan: (data: { data: FinancePlan; callback: () => void }) => dispatch(financeActions.createPlan(data)),
  editPlan: (data: { data: FinancePlan; callback: () => void }) => dispatch(financeActions.editPlan(data)),
  clonePlan: (data: {
    data: FinancePlan;
    callback: (id: string) => void;
    closeConfirmModal: () => void;
    isConfirmModal: boolean;
  }) => dispatch(financeActions.clonePlan(data)),
  deletePlan: (data: { id: string; callback: () => void }) => dispatch(financeActions.deletePlan(data)),
  setPlansParams: (data: Partial<FinancePlanParams>) => dispatch(financeActions.setPlansParams(data)),
  resetClonePlanCheckedInfo: () => dispatch(financeActions.resetClonePlanCheckedInfo()),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Planning);
