import React from 'react';

import Icon from '../../../Icon';

type ModalDraggableSettingsProps = {
  item: any;
  dragHandleProps: any;
  data: any;
  removeType: (index: number) => any;
};

export const Item = ({ item, dragHandleProps, data, removeType }: ModalDraggableSettingsProps) => {
  const index = data.findIndex((it: any) => it.id === item.id);

  const { onMouseDown, onTouchStart } = dragHandleProps;

  return (
    <div className="form__group-wrapper form__group-wrapper--with-gap cash-flow-modal-draggable" key={index}>
      {data.length > 1 ? (
        <button
          className="cash-flow-modal-draggable__button"
          type={'button'}
          onTouchStart={e => {
            e.preventDefault();
            onTouchStart(e);
          }}
          onMouseDown={e => {
            onMouseDown(e);
          }}
        >
          <Icon iconName={'drag-indicator'} externalClass={'cash-flow-drag'} />
        </button>
      ) : null}
      {item.subTypes?.length ? (
        <span className="cash-flow-expense-block">
          <span>{item.name}</span>&nbsp;
          <span className="cash-flow-placeholder">{`(incl. ${item.subTypes.length} sub-type${
            item.subTypes.length > 1 ? 's' : ''
          })`}</span>
        </span>
      ) : (
        <span className="cash-flow-expense-block">{item.name}</span>
      )}
      <button className="form__btn-clean-inputs" type={'button'} onClick={removeType(index)}>
        <Icon iconName={'trash'} externalClass={'form__btn-clean'} />
      </button>
    </div>
  );
};
