import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatValue } from 'react-currency-input-field';

import moment from 'moment';
import classNames from 'classnames';

import { OfficeBalanceType, OfficesBalanceItem } from '../../../enums/finance/finance.enum';
import { useTableData } from '../../../utils/hooks.utils';
import HierarchicalTable from '../../HierarchicalTable';
import messages from '../messages';
import { CurrencyType } from '../../../types/finance';
import { OfficeInfo } from '../../../enums/libraries.enum';
import Button from '../../Button';
import Icon from '../../Icon';
import { getMonths } from './utils';
import { getTableCell } from '../../../utils/table.utils';
import { DepthLevels } from '../../../constants/tables.constants';
import { DATE_FORMAT } from '../../../constants/date.constants';
import ModalEditCashFlowNameSettings from '../Modals/ModalEditBlockNameSetting';

type OfficeBlockTableProps = {
  customTitle?: string;
  tableData: any;
  months: any[];
  offices: OfficeInfo[];
  errors: string | null;
  isLoading: boolean;
  baseCurrency: CurrencyType | undefined;
  settingsData: any;
  settingsLoading: boolean;
  settingsError: string | null;
  putBalanceSettings: (data: any) => void;
  onCloseSettings: () => void;
};

const OfficeBlockTable = ({
  customTitle,
  tableData,
  offices,
  months,
  baseCurrency,
  errors,
  isLoading,
  settingsData,
  putBalanceSettings,
  onCloseSettings,
}: OfficeBlockTableProps) => {
  const intl = useIntl();

  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const convertedData = useMemo(() => {
    if (settingsData.length && tableData.length && offices.length) {
      const openSettings = settingsData.map((set: any) => ({
        ...set,
        cashFlowDate: set.balanceDate,
        cashFlowAmount: Number(set.customOpenBalance) || Number(set.automaticOpenBalance),
      }));
      const closeSettings = settingsData.map((set: any) => ({
        ...set,
        cashFlowDate: set.balanceDate,
        cashFlowAmount: Number(set.customCloseBalance) || Number(set.automaticCloseBalance),
      }));

      const openData = {
        type: 'OPEN',
        months: getMonths(openSettings, months),
        offices: offices.map((item: OfficeInfo) => ({
          officeId: item.id,
          office: item,
          type: 'OPEN',
          months: getMonths(
            openSettings.filter((row: any) => row.officeId === item.id),
            months,
          ),
        })),
      };

      const closeData = {
        type: 'CLOSE',
        months: getMonths(closeSettings, months),
        offices: offices.map((item: OfficeInfo) => ({
          officeId: item.id,
          office: item,
          type: 'CLOSE',
          months: getMonths(
            closeSettings.filter((row: any) => row.officeId === item.id),
            months,
          ),
        })),
      };

      const netCashData = {
        type: 'PLUS_NET_CASH',
        months: openData.months.map((month: any, index: number) => ({
          ...month,
          value: closeData.months[index].value - month.value,
        })),
        offices: offices.map((item: OfficeInfo, index: number) => {
          return {
            officeId: item.id,
            office: item,
            type: 'PLUS_NET_CASH',
            months: openData.offices[index].months.map((month: any, monthIndex: number) => ({
              ...month,
              value: closeData.offices[index].months[monthIndex].value - month.value,
            })),
          };
        }),
      };

      return [openData, netCashData, closeData];
    }

    return [];
  }, [tableData, offices]);

  const tableColumns = useMemo(() => {
    if (convertedData[0]?.offices.length) {
      const typesColumns = [
        {
          id: 'name',
          Header: intl.formatMessage(messages.balanceColumn),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: OfficesBalanceItem) => (
                  <FormattedMessage
                    {...messages[
                      row.type === 'OPEN'
                        ? 'openingBalanceColumn'
                        : row.type === 'PLUS_NET_CASH'
                        ? 'plusNetBalanceColumn'
                        : 'closingBalanceColumn'
                    ]}
                  />
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: OfficeBalanceType) => row.office.name,
              },
            ]),
        },
      ];

      const monthColumns = convertedData[0].offices[0].months?.map((data: any, index: number) => ({
        id: `month-${index}`,
        Header: (
          <div className="day-display">
            {moment(data.month).format(DATE_FORMAT.MMMM)}
            {(index === 0 || moment(data.cashFlowDate).startOf('year').isSame(data.cashFlowDate)) && (
              <div className={classNames('year-display')}>{moment(data.cashFlowDate).format(DATE_FORMAT.YYYY)}</div>
            )}
          </div>
        ),
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: any) => (
                <span>
                  {row?.months[index] ? (
                    <>
                      {formatValue({
                        value: (row?.months[index].value).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: any) =>
                row?.months[index] ? (
                  <span>
                    {formatValue({
                      value: (row?.months[index].value || 0).toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </span>
                ) : (
                  '-'
                ),
            },
          ]);
        },
      }));

      return [...typesColumns, ...monthColumns];
    }
    return [];
  }, [convertedData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: ' header_row',
      colspan: months.length,
      sortName: '',
    },
  ];

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: any) => {
    putBalanceSettings(params);
  };

  return (
    <>
      <div className="cash-flow-report__table-head">
        <h2 className="cash-flow__block-title">
          {customTitle?.length ? customTitle : <FormattedMessage {...messages.balanceBlock} />}
        </h2>
        <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
          <Icon iconName="pencil" externalClass="button__icon" />
        </Button>
      </div>
      <div className="page__scrollable-table-wrapper">
        <div className="page__scrollable-table-wrapper__inner offices-balance-wrapper">
          <HierarchicalTable
            tableData={useTableData(convertedData, ['offices'])}
            tableColumns={tableColumns}
            loading={isLoading}
            error={errors}
            tableHeaderItems={tableHeaderItems}
          />
        </div>
      </div>
      {openSettingsModal && (
        <ModalEditCashFlowNameSettings
          isOpen={openSettingsModal}
          onCloseRequest={closeSetting}
          editCashFlowSetting={editSetting}
          name={customTitle}
          type="BALANCE"
        />
      )}
    </>
  );
};

export default OfficeBlockTable;
