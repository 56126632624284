import React from 'react';
import { useIntl } from 'react-intl';

import { SortParams } from '../../../enums/params.enum';
import SortSelect from '../../SortSelect';
import messages from '../messages';

type ExpenseTypesFiltersProps = {
  values: SortParams;
  handleSort: (sortBy: string, direction: string) => void;
};

function ExpenseTypesFilter({ values, handleSort }: ExpenseTypesFiltersProps) {
  const intl = useIntl();

  return (
    <SortSelect
      sortOptions={[{ label: intl.formatMessage(messages.nameColumn), value: 'name' }]}
      params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
      onSort={handleSort}
    />
  );
}

export default React.memo(ExpenseTypesFilter);
