import { omit, isString } from 'lodash-es';
import moment from 'moment';
import { DATE_FORMAT } from '../constants/date.constants';
import { ImportTransaction, Transaction } from '../enums/finance/finance.enum';
import { RateType } from '../types/finance';

export const getCurrentRate = (rates: RateType[]) => {
  const currentRate = getCurrentRateDate(rates);

  return currentRate
    ? `${currentRate?.rate} ${currentRate?.currency.name} (${moment(currentRate?.startDate).format(
        DATE_FORMAT.MMM_D_YYYY,
      )})`
    : '';
};

export const getCurrentRateDate = (rates: RateType[], currentDate?: string) => {
  return rates
    ?.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
    .find(item => moment(item.startDate).isSameOrBefore(currentDate ? currentDate : moment()));
};

export const getFormattedLastUpdateDate = (lastUpdateDate: string) => {
  return moment(lastUpdateDate).format(DATE_FORMAT.MMM_D_YYYY);
};

export const getFormattedTransaction = (transaction: Transaction | ImportTransaction) => {
  return {
    ...transaction,
    amount: transaction.amount.float?.toString() || '',
    rate: transaction.rate.float?.toString() || '',
    unifiedAmount: transaction.unifiedAmount.float?.toString() || '',
  };
};

export const getFormattedImportTransaction = (transaction: ImportTransaction) => {
  return omit(
    {
      ...getFormattedTransaction(transaction),
    },
    [
      'operationType',
      'importTransaction',
      'transactionDateOriginalValue',
      'isTransactionDateParsedCorrectly',
      'transactionDateHasParseError',
      'transactionDateHasParseError',
      'amountOriginalValue',
      'isAmountParsedCorrectly',
      'amountHasParseError',
      'operationTypeOriginalValue',
      'isOperationTypeParsedCorrectly',
      'operationTypeHasParseError',
    ],
  );
};

export const getForamtedNumber = (number: string | number) => {
  if (number) {
    const numberArray = isString(number) ? number.split('.') : new String(number).split('.');
    return +numberArray[1] > 0 ? number : +numberArray[0];
  }
  return 0;
};

export const getReportMonths = (data: any, months: any, dateName?: string, amountName?: string) => {
  return months.map((item: any) => ({
    month: item,
    value: data
      .filter((tab: any) => moment(tab[dateName ?? 'cashFlowDate']).isSame(moment(item), 'months'))
      .map((it: any) => Number(it[amountName ?? 'cashFlowAmount']))
      .reduce((partialSum: any, a: any) => partialSum + a, 0),
  }));
};
