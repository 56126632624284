import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import InlineDatePicker from '../../components/InlineDatePicker';
import messages from './messages';
import * as filtersActions from '../../actions/filters.actions';
import * as financeActions from '../../actions/finance.actions';
import HierarchicalTable from '../../components/HierarchicalTable';
import { useDataForTable } from './useDataForTable';
import { resetParamsChange, useParamsChange, useTableData } from '../../utils/hooks.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { SavedFilter } from '../../enums/filters.enum';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { PlannedExpenseParams } from '../../enums/params/finance.params';
import PlannedExpenseFilters from '../../components/PlannedExpense/Filters/PlannedExpenseFilters';
import PoliciesContext from '../../PoliciesContext';
import { VIEW_PLANNED_EXPENSE } from '../../constants/policies.constants';
import { isEqual } from 'lodash-es';
import { MonthReportParts, PlannedExpenseClass, PlannedExpenseReportItem } from '../../enums/finance/finance.enum';
import ModalNewPlannedExpense from '../../components/PlannedExpense/Modals/ModalNewPlannedExpense';
import { getBaseCurrency } from '../../utils';
import { ExpenseType, SubExpenseType } from '../../types/finance';
import ModalEditPlannedExpense from '../../components/PlannedExpense/Modals/ModalEditPlannedExpense';
import ModalDeletePlannedExpense from '../../components/PlannedExpense/Modals/ModalDeletePlannedExpense';
import { plannedExpenseUnsavedParams } from '../../constants/finance.constants';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants/date.constants';

function PlannedExpense({
  tableData,
  officesFilter,
  isLoading,
  errors,
  params,
  getOfficesFilter,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
  setPlannedExpenseParams,
  expenseTypesFilter,
  getExpenseTypesFilter,
  resetState,
  resetErrors,
  editPlannedExpense,
  deletePlannedExpense,
  currencies,
  getCurrenciesFilter,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_PLANNED_EXPENSE), [policies]);

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.PLANNED_EXPENSES_FILTER);

    if (currentSavedFilter && viewPolicy?.isOfficeSpecific) {
      currentSavedFilter.officeIds = currentSavedFilter.officeIds?.filter(
        (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
      );
    }

    setPlannedExpenseParams(
      currentSavedFilter
        ? new PlannedExpenseParams(currentSavedFilter)
        : {
            officeIds: officesFilter.value?.filter(
              (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
            ),
          },
    );
  }, [viewPolicy]);

  useEffect(() => {
    getOfficesFilter();
    getExpenseTypesFilter();
    getCurrenciesFilter();
    setSavedFiltersParams({ filterType: FilterTypes.PLANNED_EXPENSES_FILTER });

    return () => {
      resetState();
    };
  }, []);

  const [modalNewPlannedExpenseIsOpen, setModalNewPlannedExpenseIsOpen] = useState(false);
  const [modalEditPlannedExpenseIsOpen, setModalEditPlannedExpenseIsOpen] = useState(false);
  const [modalDeletePlannedExpenseIsOpen, setModalDeletePlannedExpenseIsOpen] = useState(false);
  const [officeMonthClicked, setOfficeMonthClicked] = useState<{
    office: { id: string; name: string; isDeleted: boolean };
    monthData: MonthReportParts;
    expenseType: ExpenseType;
    subExpenseType: SubExpenseType;
  } | null>(null);

  const openNewPlannedExpenseModal = useCallback(() => {
    setModalNewPlannedExpenseIsOpen(true);
  }, []);

  const closeNewPlannedExpenseModal = useCallback(() => {
    setModalNewPlannedExpenseIsOpen(false);
    setOfficeMonthClicked(null);
    resetErrors();
  }, []);

  const openEditPlannedExpenseModal = useCallback(() => {
    setModalEditPlannedExpenseIsOpen(true);
  }, []);

  const closeEditPlannedExpenseModal = useCallback(() => {
    setModalEditPlannedExpenseIsOpen(false);
    setOfficeMonthClicked(null);
    resetErrors();
  }, []);

  const openDeletePlannedExpenseModal = useCallback(() => {
    setModalDeletePlannedExpenseIsOpen(true);
  }, []);

  const closeDeletePlannedExpenseModal = useCallback(() => {
    setModalDeletePlannedExpenseIsOpen(false);
    setOfficeMonthClicked(null);
    resetErrors();
  }, []);

  const tableDataWithTotal = useMemo(() => {
    if (tableData?.expenseReportParts?.length) {
      const newData = [...tableData?.expenseReportParts];

      newData?.push(
        new PlannedExpenseReportItem({
          totalItem: true,
          total: tableData.total,
          monthReportParts: tableData.monthReportParts,
        }),
      );

      return newData;
    }
    return tableData?.departments;
  }, [tableData]);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const { tableColumns, tableHeaderItems } = useDataForTable(
    tableData,
    baseCurrency,
    setOfficeMonthClicked,
    openNewPlannedExpenseModal,
    openEditPlannedExpenseModal,
    openDeletePlannedExpenseModal,
  );

  const handleMultiParamsChange = useParamsChange(setPlannedExpenseParams, dispatch);

  const handleDataChange = useCallback((start: string, end: string) => {
    setPlannedExpenseParams({
      startDate: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      endDate: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  }, []);

  const filters = useMemo(
    () => ({
      offices: officesFilter.offices,
      expenseTypes: expenseTypesFilter.expenseTypes,
    }),
    [expenseTypesFilter.expenseTypes, officesFilter.offices],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      const fields = convertSavedFieldsToParams(value?.fields);

      if (viewPolicy?.isOfficeSpecific) {
        fields.officeIds = fields.officeIds?.filter(
          (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
        );
      }
      setPlannedExpenseParams(
        new PlannedExpenseParams({ ...fields, startDate: params.startDate, endDate: params.endDate }),
      );
    },
    [viewPolicy, params],
  );

  const handleClear = useCallback(() => {
    setPlannedExpenseParams(new PlannedExpenseParams());
    resetParamsChange([FilterParamsName.OFFICE_IDS], dispatch);
  }, []);

  const filteredSavedFiltersData = useMemo(
    () =>
      viewPolicy?.isOfficeSpecific
        ? {
            ...savedFiltersData,
            filtersList: savedFiltersData.filtersList
              .map((filter: SavedFilter) => {
                const fields = convertSavedFieldsToParams(filter.fields);

                if (fields.officeIds) {
                  fields.officeIds = fields.officeIds.filter(
                    (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
                  );

                  if (isEqual(fields, { officeIds: [] })) {
                    return null;
                  }
                }
                return filter;
              })
              .filter((filter: SavedFilter | null) => filter),
          }
        : savedFiltersData,
    [savedFiltersData, viewPolicy],
  );

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new PlannedExpenseParams(), plannedExpenseUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.startDate}
                  defaultPeriodEnd={params.endDate}
                  todayBtn={false}
                  weekBtn={false}
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <PlannedExpenseFilters
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={filteredSavedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  policyOfficiesIds={viewPolicy?.officeIds}
                  isOfficeSpecific={!!viewPolicy?.isOfficeSpecific}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <div className="page__scrollable-table-wrapper">
            <div className="page__scrollable-table-wrapper__inner planned-expense-wrapper">
              <HierarchicalTable
                tableData={useTableData(tableDataWithTotal, ['subExpenseReportParts', 'officeReportParts'])}
                tableColumns={tableColumns}
                loading={isLoading.getPlannedExpense}
                error={errors}
                tableHeaderItems={tableHeaderItems}
              />
            </div>
          </div>
        </div>
      </div>
      {modalNewPlannedExpenseIsOpen && (
        <ModalNewPlannedExpense
          isOpen
          onCloseRequest={closeNewPlannedExpenseModal}
          createPlannedExpense={editPlannedExpense}
          error={errors}
          isLoading={isLoading.createPlannedExpense}
          officeMonthClicked={officeMonthClicked}
          baseCurrency={baseCurrency}
        />
      )}
      {modalEditPlannedExpenseIsOpen && (
        <ModalEditPlannedExpense
          isOpen
          onCloseRequest={closeEditPlannedExpenseModal}
          editPlannedExpense={editPlannedExpense}
          error={errors}
          isLoading={isLoading.createPlannedExpense}
          officeMonthClicked={officeMonthClicked}
          baseCurrency={baseCurrency}
        />
      )}
      {modalDeletePlannedExpenseIsOpen && (
        <ModalDeletePlannedExpense
          isOpen
          onCloseRequest={closeDeletePlannedExpenseModal}
          onDeleteRequest={deletePlannedExpense}
          isLoading={isLoading.deleteSalary}
          error={errors}
          officeMonthClicked={officeMonthClicked}
          baseCurrency={baseCurrency}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ filters, auth, finance }: RootState) => ({
  tableData: finance.plannedExpense,
  officesFilter: filters.officesFilter,
  expenseTypesFilter: filters.expenseTypesFilter,
  isLoading: finance.loading,
  errors: finance.errors.plannedExpenseError,
  params: finance.plannedExpenseParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
  currencies: filters.currenciesFilter.currencies,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getExpenseTypesFilter: () => dispatch(filtersActions.getExpenseTypesFilter()),
  setPlannedExpenseParams: (data: Partial<PlannedExpenseParams>) =>
    dispatch(financeActions.setPlannedExpenseParams(data)),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),
  editPlannedExpense: (data: { data: PlannedExpenseClass; callback: () => void }) =>
    dispatch(financeActions.editPlannedExpense(data)),
  deletePlannedExpense: (data: { id: string; deleteFutureExpenses: boolean; callback: () => void }) =>
    dispatch(financeActions.deletePlannedExpense(data)),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PlannedExpense);
