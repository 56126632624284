import { defineMessages } from 'react-intl';
import {
  deleteButton,
  addButton,
  cancelButton,
  saveButton,
  editButton,
  newButton,
  totalRow,
  clientLabel,
  projectLabel,
  nameColumn,
  required,
} from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  required,
  newButton,
  addButton,
  cancelButton,
  saveButton,
  totalRow,
  clientLabel,
  projectLabel,
  nameColumn,
  REVENUE_FROM_CLIENT: {
    id: 'finance.profit.loss.title.revenue.client',
    defaultMessage: 'Revenue From Client',
  },
  OPERATING_INCOME: {
    id: 'finance.profit.loss.title.operating.income',
    defaultMessage: 'Operating Income',
  },
  NET_INCOME: {
    id: 'finance.profit.loss.title.net.income',
    defaultMessage: 'Net Income',
  },
  GROSS_PROFIT: {
    id: 'finance.profit.loss.title.gross.profit',
    defaultMessage: 'Gross Profit',
  },
  REVENUE_EXPENSE: {
    id: 'finance.profit.loss.title.revenue.expense',
    defaultMessage: 'Total Financial revenues /Financial Expenses',
  },
  TOTAL_GOODS: {
    id: 'finance.profit.loss.title.total.goods',
    defaultMessage: 'Total Goods',
  },
  FINANCIAL_REVENUE: {
    id: 'finance.profit.loss.title.financial.revenue',
    defaultMessage: 'Other Financial Revenues',
  },
  COST_PROJECT: {
    id: 'finance.profit.loss.title.cost.project',
    defaultMessage: 'Cost of Project',
  },
  COST_OF_GOODS: {
    id: 'finance.profit.loss.title.cost.project1',
    defaultMessage: 'Cost of Project #1',
  },
  COST_OF_PRODUCTS: {
    id: 'finance.profit.loss.title.cost.project2',
    defaultMessage: 'Cost of Project #2',
  },
  COST_OF_INTERNAL_ACTIVITY: {
    id: 'finance.profit.loss.title.cost.project3',
    defaultMessage: 'Cost of Project #3',
  },
  FINANCIAL_EXPENSE: {
    id: 'finance.profit.loss.title.financial.expense',
    defaultMessage: 'Financial Expense',
  },
  OPERATING_EXPENSES: {
    id: 'finance.profit.loss.title.operating.expenses',
    defaultMessage: 'Operating Expenses',
  },
  OTHER_FINANCIAL_EXPENSES: {
    id: 'finance.profit.loss.title.other.financial.expenses',
    defaultMessage: 'Other Financial Expenses',
  },

  expenseColumn: {
    id: 'finance.profit.loss.column.expense',
    defaultMessage: 'Expense Type / Expense Sub-Type',
  },
  incomeColumn: {
    id: 'finance.profit.loss.column.income',
    defaultMessage: 'Income Type / Payer',
  },
  expenseTypeLabel: {
    id: 'finance.profit.loss.modal.label.expense',
    defaultMessage: 'Expense Type',
  },
  incomeTypeLabel: {
    id: 'finance.profit.loss.modal.label.income',
    defaultMessage: 'Income Type',
  },
  showEmptyRecords: {
    id: 'finance.profit.loss.modal.label.show.empty.records',
    defaultMessage: 'Show Empty Records',
  },
  groupByClient: {
    id: 'finance.profit.loss.modal.label.group.client',
    defaultMessage: 'Group By Client',
  },
  balanceColumn: {
    id: 'finance.planned.expense.column.offices.balance',
    defaultMessage: 'Balance / Offices',
  },
});
