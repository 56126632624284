export const GET_POLICY_GROUP_REQUEST = 'GET_POLICY_GROUP_REQUEST';
export const GET_POLICY_GROUP_SUCCESS = 'GET_POLICY_GROUP_SUCCESS';
export const GET_POLICY_GROUP_FAILURE = 'GET_POLICY_GROUP_FAILURE';

export const GET_POLICY_GROUP_WITH_USERS_REQUEST = 'GET_POLICY_GROUP_WITH_USERS_REQUEST';
export const GET_POLICY_GROUP_WITH_USERS_SUCCESS = 'GET_POLICY_GROUP_WITH_USERS_SUCCESS';
export const GET_POLICY_GROUP_WITH_USERS_FAILURE = 'GET_POLICY_GROUP_WITH_USERS_FAILURE';

export const SET_POLICY_GROUP_PARAMS = 'SET_POLICY_GROUP_PARAMS';

export const GET_POLICY_MODULE_SUCCESS = 'GET_POLICY_MODULE_SUCCESS';
export const GET_POLICY_MODULE_FAILURE = 'GET_POLICY_MODULE_FAILURE';

export const SET_POLICY_MODULE_PARAMS = 'SET_POLICY_MODULE_PARAMS';
export const RESET_POLICY_MODULE_PARAMS = 'RESET_POLICY_MODULE_PARAMS';

export const RESET_POLICY_MODULE_DATA = 'RESET_POLICY_MODULE_DATA';

export const GET_CURRENT_USER_POLICIES_REQUEST = 'GET_CURRENT_USER_POLICIES_REQUEST';
export const GET_CURRENT_USER_POLICIES_SUCCESS = 'GET_CURRENT_USER_POLICIES_SUCCESS';
export const GET_CURRENT_USER_POLICIES_FAILURE = 'GET_CURRENT_USER_POLICIES_FAILURE';

export const PUT_USER_POLICIES_REQUEST = 'PUT_USER_POLICIES_REQUEST';
export const PUT_USER_POLICIES_SUCCESS = 'PUT_USER_POLICIES_SUCCESS';
export const PUT_USER_POLICIES_FAILURE = 'PUT_USER_POLICIES_FAILURE';

export const PATCH_USERS_POLICIES_REQUEST = 'PATCH_USERS_POLICIES_REQUEST';
export const PATCH_USERS_POLICIES_SUCCESS = 'PATCH_USERS_POLICIES_SUCCESS';
export const PATCH_USERS_POLICIES_FAILURE = 'PATCH_USERS_POLICIES_FAILURE';

export const RESET_POLICIES_ERRORS = 'RESET_POLICIES_ERRORS';

export const GET_AUTH_USER_POLICIES_REQUEST = 'GET_AUTH_USER_POLICIES_REQUEST';
export const GET_AUTH_USER_POLICIES_SUCCESS = 'GET_AUTH_USER_POLICIES_SUCCESS';
export const GET_AUTH_USER_POLICIES_FAILURE = 'GET_AUTH_USER_POLICIES_FAILURE';

export const VIEW_DEPARTMENT = 'VIEW_DEPARTMENT';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';

export const VIEW_OFFICE = 'VIEW_OFFICE';
export const UPDATE_OFFICE = 'UPDATE_OFFICE';
export const DELETE_OFFICE = 'DELETE_OFFICE';

export const VIEW_COMPANY_POSITION = 'VIEW_COMPANY_POSITION';
export const UPDATE_COMPANY_POSITION = 'UPDATE_COMPANY_POSITION';
export const DELETE_COMPANY_POSITION = 'DELETE_COMPANY_POSITION';

export const VIEW_SPECIALIZATION = 'VIEW_SPECIALIZATION';
export const UPDATE_SPECIALIZATION = 'UPDATE_SPECIALIZATION';
export const DELETE_SPECIALIZATION = 'DELETE_SPECIALIZATION';

export const VIEW_GRADE = 'VIEW_GRADE';
export const UPDATE_GRADE = 'UPDATE_GRADE';
export const DELETE_GRADE = 'DELETE_GRADE';

export const VIEW_MAIL_SETTING = 'VIEW_MAIL_SETTING';
export const UPDATE_MAIL_SETTING = 'UPDATE_MAIL_SETTING';
export const DELETE_MAIL_SETTING = 'DELETE_MAIL_SETTING';

export const VIEW_EMPLOYEE_GROUP = 'VIEW_EMPLOYEE_GROUP';
export const UPDATE_EMPLOYEE_GROUP = 'UPDATE_EMPLOYEE_GROUP';
export const DELETE_EMPLOYEE_GROUP = 'DELETE_EMPLOYEE_GROUP';

export const VIEW_NOTIFICATION = 'VIEW_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export const VIEW_PORTAL_BRANDING = 'VIEW_PORTAL_BRANDING';
export const UPDATE_PORTAL_BRANDING = 'UPDATE_PORTAL_BRANDING';

export const VIEW_USER_EXTENDED = 'VIEW_USER_EXTENDED';
export const UPDATE_USER_EXTENDED = 'UPDATE_USER_EXTENDED';
export const DELETE_ACTIVE_EMPLOYEE = 'DELETE_ACTIVE_EMPLOYEE';

export const VIEW_USER = 'VIEW_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const VIEW_RECOGNITION_EXTENDED = 'VIEW_RECOGNITION_EXTENDED';
export const UPDATE_RECOGNITION_EXTENDED = 'UPDATE_RECOGNITION_EXTENDED';
export const DELETE_RECOGNITION_EXTENDED = 'DELETE_RECOGNITION_EXTENDED';

export const VIEW_RECOGNITION_TYPE = 'VIEW_RECOGNITION_TYPE';
export const UPDATE_RECOGNITION_TYPE = 'UPDATE_RECOGNITION_TYPE';
export const DELETE_RECOGNITION_TYPE = 'DELETE_RECOGNITION_TYPE';

export const VIEW_RESOURCE = 'VIEW_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';

export const VIEW_PROJECT_GROUP = 'VIEW_PROJECT_GROUP';
export const UPDATE_PROJECT_GROUP = 'UPDATE_PROJECT_GROUP';
export const DELETE_PROJECT_GROUP = 'DELETE_PROJECT_GROUP';

export const VIEW_ACTIVE_EMPLOYEE = 'VIEW_ACTIVE_EMPLOYEE';
export const UPDATE_ACTIVE_EMPLOYEE = 'UPDATE_ACTIVE_EMPLOYEE';

export const VIEW_USER_HOUR = 'VIEW_USER_HOUR';

export const VIEW_RESUME = 'VIEW_RESUME';
export const UPDATE_RESUME = 'UPDATE_RESUME';

export const VIEW_TECHNICAL_SKILL = 'VIEW_TECHNICAL_SKILL';
export const UPDATE_TECHNICAL_SKILL = 'UPDATE_TECHNICAL_SKILL';
export const DELETE_TECHNICAL_SKILL = 'DELETE_TECHNICAL_SKILL';

export const VIEW_SKILL_LEVEL = 'VIEW_SKILL_LEVEL';
export const UPDATE_SKILL_LEVEL = 'UPDATE_SKILL_LEVEL';
export const DELETE_SKILL_LEVEL = 'DELETE_SKILL_LEVEL';

export const VIEW_FOREIGN_LANGUAGE = 'VIEW_FOREIGN_LANGUAGE';
export const UPDATE_FOREIGN_LANGUAGE = 'UPDATE_FOREIGN_LANGUAGE';
export const DELETE_FOREIGN_LANGUAGE = 'DELETE_FOREIGN_LANGUAGE';

export const VIEW_LANGUAGE_LEVEL = 'VIEW_LANGUAGE_LEVEL';
export const UPDATE_LANGUAGE_LEVEL = 'UPDATE_LANGUAGE_LEVEL';
export const DELETE_LANGUAGE_LEVEL = 'DELETE_LANGUAGE_LEVEL';

export const VIEW_PROJECT = 'VIEW_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export const VIEW_COMPETENCE_TYPE = 'VIEW_COMPETENCE_TYPE';
export const UPDATE_COMPETENCE_TYPE = 'UPDATE_COMPETENCE_TYPE';
export const DELETE_COMPETENCE_TYPE = 'DELETE_COMPETENCE_TYPE';

export const VIEW_COMPETENCE = 'VIEW_COMPETENCE';
export const UPDATE_COMPETENCE = 'UPDATE_COMPETENCE';
export const DELETE_COMPETENCE = 'DELETE_COMPETENCE';

export const VIEW_SPECIALIZATION_COMPETENCE = 'VIEW_SPECIALIZATION_COMPETENCE';
export const UPDATE_SPECIALIZATION_COMPETENCE = 'UPDATE_SPECIALIZATION_COMPETENCE';

export const VIEW_SKILL_QUESTION = 'VIEW_SKILL_QUESTION';
export const UPDATE_SKILL_QUESTION = 'UPDATE_SKILL_QUESTION';

export const VIEW_ASSESSMENT = 'VIEW_ASSESSMENT';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const DELETE_ASSESSMENT = 'DELETE_ASSESSMENT';

export const VIEW_BONUS_BY_GRADES = 'VIEW_BONUS_BY_GRADES';
export const UPDATE_BONUS_BY_GRADES = 'UPDATE_BONUS_BY_GRADES';
export const DELETE_BONUS_BY_GRADES = 'DELETE_BONUS_BY_GRADES';

export const VIEW_BONUS_SETTINGS = 'VIEW_BONUS_SETTINGS';
export const UPDATE_BONUS_SETTINGS = 'UPDATE_BONUS_SETTINGS';

export const VIEW_EVENT_EXTENDED = 'VIEW_EVENT_EXTENDED';
export const UPDATE_EVENT_EXTENDED = 'UPDATE_EVENT_EXTENDED';
export const DELETE_EVENT_EXTENDED = 'DELETE_EVENT_EXTENDED';

export const VIEW_EVENT_TYPE = 'VIEW_EVENT_TYPE';
export const UPDATE_EVENT_TYPE = 'UPDATE_EVENT_TYPE';
export const DELETE_EVENT_TYPE = 'DELETE_EVENT_TYPE';

export const VIEW_EVENT = 'VIEW_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const VIEW_PUBLIC_EVENT = 'VIEW_PUBLIC_EVENT';
export const UPDATE_PUBLIC_EVENT = 'UPDATE_PUBLIC_EVENT';
export const DELETE_PUBLIC_EVENT = 'DELETE_PUBLIC_EVENT';

export const VIEW_COMMENT = 'VIEW_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const VIEW_SECRET_COMMENT = 'VIEW_SECRET_COMMENT';
export const UPDATE_SECRET_COMMENT = 'UPDATE_SECRET_COMMENT';
export const DELETE_SECRET_COMMENT = 'DELETE_SECRET_COMMENT';

export const VIEW_TEMPLATE = 'VIEW_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

export const VIEW_TEMPLATE_CATEGORY = 'VIEW_TEMPLATE_CATEGORY';
export const UPDATE_TEMPLATE_CATEGORY = 'UPDATE_TEMPLATE_CATEGORY';
export const DELETE_TEMPLATE_CATEGORY = 'DELETE_TEMPLATE_CATEGORY';

export const VIEW_POLL_EXTENDED = 'VIEW_POLL_EXTENDED';
export const UPDATE_POLL_EXTENDED = 'UPDATE_POLL_EXTENDED';
export const DELETE_POLL = 'DELETE_POLL';

export const UPDATE_POLL_ANSWER_EXTENDED = 'UPDATE_POLL_ANSWER_EXTENDED';

export const VIEW_RESOURCE_LINKS_MENU = 'VIEW_RESOURCE_LINKS_MENU';
export const UPDATE_RESOURCE_LINK = 'UPDATE_RESOURCE_LINK';
export const DELETE_RESOURCE_LINK = 'DELETE_RESOURCE_LINK';

export const VIEW_POLL = 'VIEW_POLL';
export const UPDATE_POLL = 'UPDATE_POLL';

export const VIEW_CLIENT = 'VIEW_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';

export const VIEW_SALARY = 'VIEW_SALARY';
export const UPDATE_SALARY = 'UPDATE_SALARY';
export const DELETE_SALARY = 'DELETE_SALARY';

export const VIEW_CURRENCY = 'VIEW_CURRENCY';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const DELETE_CURRENCY = 'DELETE_CURRENCY';

export const VIEW_EXPENSE_TYPE = 'VIEW_EXPENSE_TYPE';
export const UPDATE_EXPENSE_TYPE = 'UPDATE_EXPENSE_TYPE';
export const DELETE_EXPENSE_TYPE = 'DELETE_EXPENSE_TYPE';

export const VIEW_INCOME_TYPE = 'VIEW_INCOME_TYPE';
export const UPDATE_INCOME_TYPE = 'UPDATE_INCOME_TYPE';
export const DELETE_INCOME_TYPE = 'DELETE_INCOME_TYPE';

export const VIEW_SUPPLIER = 'VIEW_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';

export const VIEW_PROJECT_TYPE = 'VIEW_PROJECT_TYPE';
export const UPDATE_PROJECT_TYPE = 'UPDATE_PROJECT_TYPE';
export const DELETE_PROJECT_TYPE = 'DELETE_PROJECT_TYPE';

export const VIEW_FINANCE_PROJECT = 'VIEW_FINANCE_PROJECT';
export const UPDATE_FINANCE_PROJECT = 'UPDATE_FINANCE_PROJECT';
export const DELETE_FINANCE_PROJECT = 'DELETE_FINANCE_PROJECT';

export const VIEW_EMPLOYEE_REPORT = 'VIEW_EMPLOYEE_REPORT';

export const VIEW_EMPLOYEE_TIMESHEET_REPORT = 'VIEW_EMPLOYEE_TIMESHEET_REPORT';

export const VIEW_EMPLOYEES_AND_ISSUE_TYPE = 'VIEW_EMPLOYEES_AND_ISSUE_TYPE';

export const VIEW_USERS_HOURS_ABSENCE_REPORT = 'VIEW_USERS_HOURS_ABSENCE_REPORT';

export const VIEW_ABSENCE_PERIOD = 'VIEW_ABSENCE_PERIOD';

export const VIEW_PROJECT_REPORT = 'VIEW_PROJECT_REPORT';

export const VIEW_ACTIVE_INACTIVE_HOURS_REPORT = 'VIEW_ACTIVE_INACTIVE_HOURS_REPORT';

export const VIEW_ABSENCE_MANUAL_INFO = 'VIEW_ABSENCE_MANUAL_INFO';
export const UPDATE_ABSENCE_MANUAL_INFO = 'UPDATE_ABSENCE_MANUAL_INFO';

export const VIEW_POLICY = 'VIEW_POLICY';
export const UPDATE_POLICY = 'UPDATE_POLICY';

export const VIEW_ALL_EMPLOYEE_ACTIVITY = 'VIEW_ALL_EMPLOYEE_ACTIVITY';

export const VIEW_PERSONAL_ACTIVITY = 'VIEW_PERSONAL_ACTIVITY';

export const VIEW_CONTACT_INFORMATION = 'VIEW_CONTACT_INFORMATION';

export const VIEW_SOFTWARE = 'VIEW_SOFTWARE';
export const UPDATE_SOFTWARE = 'UPDATE_SOFTWARE';
export const DELETE_SOFTWARE = 'DELETE_SOFTWARE';

export const VIEW_NOTIFICATION_TEMPLATE = 'VIEW_NOTIFICATION_TEMPLATE';
export const UPDATE_NOTIFICATION_TEMPLATE = 'UPDATE_NOTIFICATION_TEMPLATE';
export const DELETE_NOTIFICATION_TEMPLATE = 'DELETE_NOTIFICATION_TEMPLATE';

export const VIEW_INCOMES_EXPENSES_REPORT = 'VIEW_INCOMES_EXPENSES_REPORT';

export const VIEW_TRANSACTION = 'VIEW_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';

export const VIEW_FINANCE_PLAN = 'VIEW_FINANCE_PLAN';
export const UPDATE_FINANCE_PLAN = 'UPDATE_FINANCE_PLAN';
export const DELETE_FINANCE_PLAN = 'DELETE_FINANCE_PLAN';

export const VIEW_CANDIDATE_STATUS = 'VIEW_CANDIDATE_STATUS';
export const UPDATE_CANDIDATE_STATUS = 'UPDATE_CANDIDATE_STATUS';
export const DELETE_CANDIDATE_STATUS = 'DELETE_CANDIDATE_STATUS';

export const VIEW_CANDIDATE_SPECIALIZATION = 'VIEW_CANDIDATE_SPECIALIZATION';
export const UPDATE_CANDIDATE_SPECIALIZATION = 'UPDATE_CANDIDATE_SPECIALIZATION';
export const DELETE_CANDIDATE_SPECIALIZATION = 'DELETE_CANDIDATE_SPECIALIZATION';

export const VIEW_CANDIDATE_TECHNOLOGY = 'VIEW_CANDIDATE_TECHNOLOGY';
export const UPDATE_CANDIDATE_TECHNOLOGY = 'UPDATE_CANDIDATE_TECHNOLOGY';
export const DELETE_CANDIDATE_TECHNOLOGY = 'DELETE_CANDIDATE_TECHNOLOGY';

export const VIEW_CANDIDATE_RECEIVING_SOURCE = 'VIEW_RECEIVING_SOURCE';
export const UPDATE_CANDIDATE_RECEIVING_SOURCE = 'UPDATE_RECEIVING_SOURCE';
export const DELETE_CANDIDATE_RECEIVING_SOURCE = 'DELETE_RECEIVING_SOURCE';

export const VIEW_CANDIDATE = 'VIEW_CANDIDATE';
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE';
export const DELETE_CANDIDATE = 'DELETE_CANDIDATE';

export const VIEW_CANDIDATE_COMMENT = 'VIEW_CANDIDATE_COMMENT';
export const UPDATE_CANDIDATE_COMMENT = 'UPDATE_CANDIDATE_COMMENT';
export const DELETE_CANDIDATE_COMMENT = 'DELETE_CANDIDATE_COMMENT';

export const VIEW_PUBLIC_FILTER = 'VIEW_PUBLIC_FILTER';
export const UPDATE_PUBLIC_FILTER = 'UPDATE_PUBLIC_FILTER';
export const DELETE_PUBLIC_FILTER = 'DELETE_PUBLIC_FILTER';

export const VIEW_PLANNED_EXPENSE = 'VIEW_PLANNED_EXPENSE';
export const UPDATE_PLANNED_EXPENSE = 'UPDATE_PLANNED_EXPENSE';
export const DELETE_PLANNED_EXPENSE = 'DELETE_PLANNED_EXPENSE';

export const VIEW_OFFICE_BALANCE = 'VIEW_OFFICE_BALANCE';
export const UPDATE_OFFICE_BALANCE = 'UPDATE_OFFICE_BALANCE';

export const VIEW_CASH_FLOW = 'VIEW_CASH_FLOW';
export const VIEW_CASH_FLOW_SETTINGS = 'VIEW_CASH_FLOW_SETTINGS';
export const UPDATE_CASH_FLOW_SETTINGS = 'UPDATE_CASH_FLOW_SETTINGS';

export const VIEW_PROFIT_LOSS_REPORT = 'VIEW_PROFIT_LOSS_REPORT';
export const VIEW_PROFIT_LOSS_REPORT_SETTINGS = 'VIEW_PROFIT_LOSS_REPORT_SETTINGS';
export const UPDATE_PROFIT_LOSS_REPORT_SETTINGS = 'UPDATE_PROFIT_LOSS_REPORT_SETTINGS';
