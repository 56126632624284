import { defineMessages } from 'react-intl';
import {
  addButton,
  cancelButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  nameColumn,
  personalLabel,
  saveButton,
  typeLabel,
} from '../../i18n/defaultMessage';

export default defineMessages({
  cancelButton,
  deleteButton,
  saveButton,
  deleteModalWithItemNameTitle,
  addButton,
  nameColumn,
  typeLabel,
  personalLabel,
  newExpenseTypeTitle: {
    id: 'finance.expense.type.modal.new.expense.type.title.new.expense',
    defaultMessage: 'New Expense',
  },
  expenseTypeLabel: {
    id: 'finance.expense.type.modal.edit.expense.type.label.expence.group.name',
    defaultMessage: 'Expense Group Name',
  },
  subExpenseTypeLabel: {
    id: 'finance.expense.type.modal.edit.expense.type.label.expence',
    defaultMessage: 'Expence',
  },
  editExpenseTypeTitle: {
    id: 'finance.expense.type.modal.edit.expense.type.title.edit.expense',
    defaultMessage: 'Edit Expense',
  },
  companyLabel: {
    id: 'finance.expense.type.modal.edit.expense.type.label.company',
    defaultMessage: 'Company',
  },
});
