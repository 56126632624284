import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import { CurrencyType, SubExpenseType } from '../../../types/finance';
import Button from '../../Button';
import Icon from '../../Icon';
// import ModalEditCashFlowSettings from '../Modals/ModalEditCashFlowSettings';
// import { getMonths } from './utils';
import { EProfitLossExpense } from '../../../pages/ProfitLossReport';
import ModalEditFinancialExpense from '../Modal/FinancialExpense/FinancialExpenseModal';
import { getReportMonths } from '../../../utils/finance.utils';
import { useDataForTable } from './useDataForTable';
import HierarchicalTable from '../../HierarchicalTable';
import { useTableData } from '../../../utils/hooks.utils';

type ProfitLossTableProps = {
  title: EProfitLossExpense;
  tableData: any;
  months: any[];
  errors: string | null;
  isLoading: boolean;
  baseCurrency: CurrencyType | undefined;
  settingsData: any;
  types: any;
  settingsLoading: boolean;
  settingsError: string | null;
  getTypesList: (data?: any) => void;
  getSettings: (data: EProfitLossExpense) => void;
  putSettings: (data: any) => void;
  onCloseSettings: () => void;
};

const FinancialExpenseTable = ({
  title,
  tableData,
  months,
  baseCurrency,
  errors,
  isLoading,
  settingsData,
  settingsError,
  settingsLoading,
  getTypesList,
  getSettings,
  putSettings,
  onCloseSettings,
}: ProfitLossTableProps) => {
  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const convertedExpenseData = useMemo(() => {
    if (tableData && tableData.length) {
      const types = tableData
        ?.map((data: any) =>
          data.expenseType.subExpenseTypes.length === data.profitLossReportSubExpenseBlockSettings.length
            ? data.expenseType
            : data.profitLossReportSubExpenseBlockSettings[0].subExpenseType,
        )
        .reduce((item: any, o: any) => {
          if (!item.some((obj: any) => obj.id === o.id)) {
            item.push(o);
          }

          return item;
        }, []);

      if (types?.length) {
        const tableTypes = types.map((type: any) => {
          const filtered = tableData
            .find(
              (data: any) =>
                (type.subExpenseTypes
                  ? data.expenseTypeId
                  : data.profitLossReportSubExpenseBlockSettings[0].subExpenseTypeId) === type.id,
            )
            .plannedExpenses.filter(
              (data: any) => (type.subExpenseTypes ? data.expenseTypeId : data.subExpenseTypeId) === type.id,
            );
          const offices = !type.subExpenseTypes ? Array.from(new Set(filtered.map((it: any) => it.officeId))) : [];

          return {
            id: type.id,
            name: type.name,
            total: filtered.map((it: any) => Number(it.amount)).reduce((partialSum: any, a: any) => partialSum + a, 0),
            months: getReportMonths(filtered, months, 'startDate', 'amount'),
            subTypes: type.subExpenseTypes
              ? type.subExpenseTypes?.map((sub: SubExpenseType) => {
                  const subs = filtered.filter((item: any) => item.subExpenseTypeId === sub.id);
                  const uniqueOffices = Array.from(new Set(subs.map((it: any) => it.officeId))).filter(it => !!it);

                  return {
                    name: sub.name,
                    id: sub.id,
                    months: getReportMonths(subs, months, 'startDate', 'amount'),
                    total: subs
                      .map((it: any) => Number(it.amount))
                      .reduce((partialSum: any, a: any) => partialSum + a, 0),
                    subTypes: uniqueOffices.map(unique => {
                      const cuttedOffices = subs.filter((sub: any) => sub.officeId === unique);

                      return {
                        name: cuttedOffices[0].office.name,
                        id: unique,
                        total: cuttedOffices
                          .map((it: any) => Number(it.amount))
                          .reduce((partialSum: any, a: any) => partialSum + a, 0),
                        months: getReportMonths(cuttedOffices, months, 'startDate', 'amount'),
                      };
                    }),
                  };
                  sub;
                })
              : offices.map((office: any) => {
                  const officeObject = filtered.find((it: any) => it.officeId === office);
                  if (officeObject)
                    return {
                      id: officeObject.office.id,
                      name: officeObject.office.name,
                      total: filtered
                        .filter((it: any) => it.officeId === office)
                        .map((it: any) => Number(it.amount))
                        .reduce((partialSum: any, a: any) => partialSum + a, 0),
                      months: getReportMonths(
                        filtered.filter((it: any) => it.officeId === office),
                        months,
                        'startDate',
                        'amount',
                      ),
                    };
                }),
          };
        });

        tableTypes?.push({
          totalItem: true,
          total: tableData
            .map((it: any) => it.plannedExpenses)
            .flat()
            .map((it: any) => Number(it.amount))
            .reduce((partialSum: any, a: any) => partialSum + a, 0),
          months: getReportMonths(tableData.map((it: any) => it.plannedExpenses).flat(), months, 'startDate', 'amount'),
        });

        return tableTypes;
      }
    }

    return [];
  }, [tableData]);

  const openSetting = () => {
    getSettings(title);
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: any) => {
    putSettings(params);
  };

  const { tableColumns, tableHeaderItems } = useDataForTable(convertedExpenseData, baseCurrency, 'expenseColumn');

  return (
    <>
      <div className="cash-flow-report__table-head">
        <h2 className="cash-flow__block-title">
          {settingsData && settingsData[title] && settingsData[title].name?.length ? (
            settingsData[title].name
          ) : (
            <FormattedMessage {...messages[title]} />
          )}
        </h2>
        <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
          <Icon iconName="pencil" externalClass="button__icon" />
        </Button>
      </div>
      <div className="page__scrollable-table-wrapper cash-flow-report">
        <div className="page__scrollable-table-wrapper__inner cash-flow-report-wrapper">
          <HierarchicalTable
            tableData={useTableData(convertedExpenseData, ['subTypes', 'subTypes'])}
            tableColumns={tableColumns}
            loading={isLoading}
            error={errors}
            tableHeaderItems={tableHeaderItems}
          />
        </div>
      </div>
      {openSettingsModal && (
        <ModalEditFinancialExpense
          isOpen={openSettingsModal}
          getTypesList={getTypesList}
          type={title}
          onCloseRequest={closeSetting}
          editSetting={editSetting}
          loading={settingsLoading}
          error={settingsError}
          data={settingsData ? settingsData[title] : null}
        />
      )}
    </>
  );
};

export default FinancialExpenseTable;
