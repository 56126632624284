import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatValue } from 'react-currency-input-field';

import moment from 'moment';
import classNames from 'classnames';

import messages from '../messages';
import Button from '../../Button';
import Icon from '../../Icon';
import { EProfitLossCost, EProfitLossExpense, EProfitLossTitles } from '../../../pages/ProfitLossReport';
import ModalEditProfitLossNameSettings from '../Modal/ProfitLossNameModal';
import { getReportMonths } from '../../../utils/finance.utils';
import Table from '../../Table';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { CurrencyType } from '../../../types/finance';

type TotalTableProps = {
  title: EProfitLossTitles;
  tableData: any;
  errors: string | null;
  isLoading: boolean;
  baseCurrency: CurrencyType | undefined;
  months: any;
  settingsData: any;
  settingsLoading: boolean;
  settingsError: string | null;
  putSettings: (data: any) => void;
  onCloseSettings: () => void;
};

const TotalTable = ({
  title,
  tableData,
  months,
  isLoading,
  errors,
  settingsData,
  baseCurrency,
  putSettings,
  onCloseSettings,
}: TotalTableProps) => {
  const intl = useIntl();
  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const convertedData = useMemo(() => {
    if (tableData) {
      const totalMonthsRevenue = getReportMonths(
        tableData.financialRevenues ?? [],
        months,
        'reportDate',
        'reportAmount',
      );
      const totalMonthsFinancialExpenses = getReportMonths(
        tableData.financialExpenses
          ?.find((expense: any) => expense.blockType === EProfitLossExpense.OTHER_FINANCIAL_EXPENSES)
          ?.financialExpenseBlockData?.map((item: any) => item.plannedExpenses)
          .flat() ?? [],
        months,
        'startDate',
        'amount',
      );

      if (title === EProfitLossTitles.REVENUE_EXPENSE) {
        return [
          {
            id: 'total',
            totalItem: true,
            total: months
              .map(
                (it: any, index: number) =>
                  Number(totalMonthsRevenue[index]?.value || 0) -
                  Number(totalMonthsFinancialExpenses[index]?.value || 0),
              )
              .reduce((partialSum: any, a: any) => partialSum + a, 0),
            months: months.map((it: any, index: number) => ({
              month: it,
              value:
                Number(totalMonthsRevenue[index]?.value || 0) - Number(totalMonthsFinancialExpenses[index]?.value || 0),
            })),
          },
        ];
      } else if (
        [
          EProfitLossTitles.TOTAL_GOODS,
          EProfitLossTitles.GROSS_PROFIT,
          EProfitLossTitles.OPERATING_INCOME,
          EProfitLossTitles.NET_INCOME,
        ].includes(title)
      ) {
        const totalMonthsCostGoods = getReportMonths(
          tableData.costOfProjects?.find((cost: any) => cost.blockType === EProfitLossCost.COST_OF_GOODS)
            ?.costOfProjectBlockData ?? [],
          months,
          'reportDate',
          'reportAmount',
        );
        const totalMonthsCostProducts = getReportMonths(
          tableData.costOfProjects?.find((cost: any) => cost.blockType === EProfitLossCost.COST_OF_PRODUCTS)
            ?.costOfProjectBlockData ?? [],
          months,
          'reportDate',
          'reportAmount',
        );

        if (title === EProfitLossTitles.TOTAL_GOODS) {
          return [
            {
              id: 'total',
              totalItem: true,
              total: months
                .map(
                  (it: any, index: number) =>
                    Number(totalMonthsCostGoods[index]?.value || 0) +
                    Number(totalMonthsCostProducts[index]?.value || 0),
                )
                .reduce((partialSum: any, a: any) => partialSum + a, 0),
              months: months.map((it: any, index: number) => ({
                month: it,
                value:
                  Number(totalMonthsCostGoods[index]?.value || 0) + Number(totalMonthsCostProducts[index]?.value || 0),
              })),
            },
          ];
        } else {
          const totalMonthsRevenueClients = getReportMonths(
            tableData.revenueFromClients ?? [],
            months,
            'reportDate',
            'reportAmount',
          );

          if (title === EProfitLossTitles.GROSS_PROFIT) {
            return [
              {
                id: 'total',
                totalItem: true,
                total: months
                  .map(
                    (it: any, index: number) =>
                      Number(totalMonthsRevenueClients[index]?.value || 0) -
                      (Number(totalMonthsCostGoods[index]?.value || 0) +
                        Number(totalMonthsCostProducts[index]?.value || 0)),
                  )
                  .reduce((partialSum: any, a: any) => partialSum + a, 0),
                months: months.map((it: any, index: number) => ({
                  month: it,
                  value:
                    Number(totalMonthsRevenueClients[index]?.value || 0) -
                    (Number(totalMonthsCostGoods[index]?.value || 0) +
                      Number(totalMonthsCostProducts[index]?.value || 0)),
                })),
              },
            ];
          } else {
            const totalMonthsCostActivity = getReportMonths(
              tableData.costOfProjects?.find(
                (cost: any) => cost.blockType === EProfitLossCost.COST_OF_INTERNAL_ACTIVITY,
              )?.costOfProjectBlockData ?? [],
              months,
              'reportDate',
              'reportAmount',
            );
            const totalMonthsOperatingExpense = getReportMonths(
              tableData.financialExpenses
                ?.find((cost: any) => cost.blockType === EProfitLossExpense.OPERATING_EXPENSES)
                ?.financialExpenseBlockData?.map((item: any) => item.plannedExpenses)
                .flat() ?? [],
              months,
              'startDate',
              'amount',
            );

            if (title === EProfitLossTitles.OPERATING_INCOME) {
              return [
                {
                  id: 'total',
                  totalItem: true,
                  total: months
                    .map(
                      (it: any, index: number) =>
                        Number(totalMonthsRevenueClients[index]?.value || 0) -
                        (Number(totalMonthsCostGoods[index]?.value || 0) +
                          Number(totalMonthsCostProducts[index]?.value || 0) +
                          Number(totalMonthsCostActivity[index]?.value || 0) +
                          Number(totalMonthsOperatingExpense[index]?.value || 0)),
                    )
                    .reduce((partialSum: any, a: any) => partialSum + a, 0),
                  months: months.map((it: any, index: number) => ({
                    month: it,
                    value:
                      Number(totalMonthsRevenueClients[index]?.value || 0) -
                      (Number(totalMonthsCostGoods[index]?.value || 0) +
                        Number(totalMonthsCostProducts[index]?.value || 0) +
                        Number(totalMonthsCostActivity[index]?.value || 0) +
                        Number(totalMonthsOperatingExpense[index]?.value || 0)),
                  })),
                },
              ];
            } else {
              return [
                {
                  id: 'total',
                  totalItem: true,
                  total: months
                    .map(
                      (it: any, index: number) =>
                        Number(totalMonthsRevenueClients[index]?.value || 0) +
                        Number(totalMonthsRevenue[index]?.value || 0) -
                        (Number(totalMonthsFinancialExpenses[index]?.value || 0) +
                          Number(totalMonthsCostGoods[index]?.value || 0) +
                          Number(totalMonthsCostProducts[index]?.value || 0) +
                          Number(totalMonthsCostActivity[index]?.value || 0) +
                          Number(totalMonthsOperatingExpense[index]?.value || 0)),
                    )
                    .reduce((partialSum: any, a: any) => partialSum + a, 0),
                  months: months.map((it: any, index: number) => ({
                    month: it,
                    value:
                      Number(totalMonthsRevenueClients[index]?.value || 0) +
                      Number(totalMonthsRevenue[index]?.value || 0) -
                      (Number(totalMonthsFinancialExpenses[index]?.value || 0) +
                        Number(totalMonthsCostGoods[index]?.value || 0) +
                        Number(totalMonthsCostProducts[index]?.value || 0) +
                        Number(totalMonthsCostActivity[index]?.value || 0) +
                        Number(totalMonthsOperatingExpense[index]?.value || 0)),
                  })),
                },
              ];
            }
          }
        }
      }
    }

    return [];
  }, [tableData]);

  const tableColumns = useMemo(() => {
    if (convertedData.length) {
      const typeColumn = [
        {
          id: 'title',
          name: '',
          modifier: () => intl.formatMessage(messages.totalRow),
          className: 'table__row-title',
        },
      ];

      const monthColumns = convertedData[0].months?.map((data: any, index: number) => ({
        id: `month-${index}`,
        name: (
          <div className="day-display">
            {moment(data.month).format(DATE_FORMAT.MMM)}
            {(index === 0 || moment(data.month).startOf('year').isSame(data.month)) && (
              <div className={classNames('year-display')}>{moment(data.month).format(DATE_FORMAT.YYYY)}</div>
            )}
          </div>
        ),
        modifier: (row: any) => {
          return (
            <span>
              {row.months[index]?.value
                ? formatValue({
                    value: row.months[index]?.value?.toString(),
                    suffix: ` ${baseCurrency?.name}`,
                  })
                : '-'}
            </span>
          );
        },
      }));

      const totalColumn = [
        {
          id: `total`,
          name: intl.formatMessage(messages.totalRow),
          className: 'table__row-title',
          modifier: (row: any) => (
            <span>
              {formatValue({
                value: row.total?.toString(),
              })}
              &nbsp;
              {baseCurrency?.name}
            </span>
          ),
        },
      ];

      return [...typeColumn, ...monthColumns, ...totalColumn];
    }
    return [];
  }, [convertedData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: ' header_row',
      colspan: convertedData[0]?.months?.length,
      sortName: '',
    },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
  ];

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: any) => {
    putSettings(params);
  };

  return (
    <>
      <div className="cash-flow-report__table-head">
        <h2 className="cash-flow__block-title">
          {settingsData?.name?.length ? settingsData.name : <FormattedMessage {...messages[title]} />}
        </h2>
        <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
          <Icon iconName="pencil" externalClass="button__icon" />
        </Button>
      </div>
      <div className="page__scrollable-table-wrapper cash-flow-report">
        <div className="page__scrollable-table-wrapper__inner cash-flow-report-wrapper">
          <Table
            externalClass={'table--hierarchical'}
            tableColumns={convertedData.length ? tableColumns : []}
            tableData={convertedData}
            loading={isLoading}
            error={errors}
            tableActions={[]}
            hasRowHover={false}
            tableHeaderItems={convertedData.length ? tableHeaderItems : []}
          />
        </div>
      </div>
      {openSettingsModal && (
        <ModalEditProfitLossNameSettings
          isOpen={openSettingsModal}
          onCloseRequest={closeSetting}
          editSetting={editSetting}
          title={title}
          name={settingsData?.name}
        />
      )}
    </>
  );
};

export default TotalTable;
