import { defineMessages } from 'react-intl';
import {
  cancelButton,
  commentLabel,
  dateLabel,
  deleteButton,
  editButton,
  lastUpdateColumn,
  newButton,
  officeLabel,
  projectLabel,
  rateLabel,
  updaterColumn,
} from '../../i18n/defaultMessage';

export default defineMessages({
  dateLabel,
  newButton,
  officeLabel,
  projectLabel,
  rateLabel,
  editButton,
  deleteButton,
  updaterColumn,
  lastUpdateColumn,
  cancelButton,
  commentLabel,
  pageTitle: {
    id: 'finance.transactions.title.transactions',
    defaultMessage: 'Transactions',
  },
  importPageTitle: {
    id: 'finance.transactions.import.title.import.transactions',
    defaultMessage: 'Import Transactions',
  },
  expenseIncomeTypeColumn: {
    id: 'finance.transactions.column.expense.income.type',
    defaultMessage: 'Expense / Income Type',
  },
  expenseSubTypeColumn: {
    id: 'finance.transactions.column.expense',
    defaultMessage: 'Expense',
  },
  payerRecipientColumn: {
    id: 'finance.transactions.column.payer.recipient.type',
    defaultMessage: 'Payer / Recipient',
  },
  originalAmountColumn: {
    id: 'finance.transactions.column.original.amount',
    defaultMessage: 'Original amount',
  },
  unifiedAmountColumn: {
    id: 'finance.transactions.column.unified.amount',
    defaultMessage: 'Unified amount',
  },
  operationTypeColumn: {
    id: 'finance.transactions.table.column.operation.type',
    defaultMessage: 'Operation Type',
  },
  currencyColumn: {
    id: 'finance.transactions.table.column.currency',
    defaultMessage: 'Currency',
  },
  expenseIncomeColumn: {
    id: 'finance.transactions.table.column.expense.income',
    defaultMessage: 'Expense / Income',
  },
  backBtn: {
    id: 'finance.transactions.import.btn.back',
    defaultMessage: 'Back',
  },
  importBtn: {
    id: 'finance.transactions.import.btn.import',
    defaultMessage: '{count, plural, =0 {Import # records} one {Import # record} other {Import # records}}',
  },
  continueBtn: {
    id: 'finance.transactions.import.btn.continue',
    defaultMessage: 'Continue',
  },
  fileExtensionError: {
    id: 'finance.transactions.import.error.incorrect.file.extension',
    defaultMessage: 'Incorrect file extension',
  },
  parsingError: {
    id: 'finance.transactions.import.error.parsing.error',
    defaultMessage: 'Parsing error. Original value:',
  },
  incomeLabel: {
    id: 'finance.transactions.label.income',
    defaultMessage: 'Income',
  },
  expensesLabel: {
    id: 'finance.transactions.label.expenses',
    defaultMessage: 'Expenses',
  },
  differenceLabel: {
    id: 'finance.transactions.label.difference',
    defaultMessage: 'Difference',
  },
});
