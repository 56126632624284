import { BrandingInfo } from '../enums/branding.enum';
import { isDark } from './color.utils';
import faviconImage from '../assets/svg/logo.svg';

export function getBrandingData(): BrandingInfo {
  const brandingData = localStorage.getItem('brandingData');
  return brandingData ? new BrandingInfo(JSON.parse(brandingData)) : new BrandingInfo();
}

export function setBrandingData(brandingData: BrandingInfo) {
  localStorage.setItem('brandingData', JSON.stringify(brandingData));
}

export function getTextColorData() {
  const colorData = localStorage.getItem('textColorData');
  return colorData;
}

export function setTextColorData(brandingData: BrandingInfo) {
  localStorage.setItem('textColorData', isDark(brandingData.globalAccents || '000') ? '#fff' : '#4A4A4A');
}

export function setFavicon(brandingData: BrandingInfo, favicon: any) {
  if (brandingData.isFaviconUrl && brandingData.faviconUrl) {
    favicon.href = brandingData.faviconUrl;
  } else if (!brandingData.isFaviconUrl && brandingData.faviconFile) {
    favicon.href = brandingData.faviconFile.url;
  } else {
    favicon.href = faviconImage;
  }
}
