import { defineMessages } from 'react-intl';
import { deleteButton, departmentsMembersLabel, editButton, newButton, totalRow } from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  newButton,
  totalRow,
  departmentsMembersLabel,
  pageTitle: {
    id: 'finance.planned.expense.title.offices.balance',
    defaultMessage: 'Offices Balance',
  },
  balanceColumn: {
    id: 'finance.planned.expense.column.offices.balance',
    defaultMessage: 'Balance / Offices',
  },
  openingBalanceColumn: {
    id: 'finance.planned.expense.column.opening.balance',
    defaultMessage: 'Opening Balance',
  },
  closingBalanceColumn: {
    id: 'finance.planned.expense.column.closing.balance',
    defaultMessage: 'Closing Balance',
  },
});
