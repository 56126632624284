import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import Icon from '../../../components/Icon';
import { FinancePlanUser } from '../../../enums/finance/finance.enum';
import TableUserAvatar from '../../../components/TableUserAvatar';
import { DELETE_FINANCE_PLAN, UPDATE_FINANCE_PLAN } from '../../../constants/policies.constants';
import { getForamtedNumber } from '../../../utils/finance.utils';
import { formatValue } from 'react-currency-input-field';
import { CurrencyType } from '../../../types/finance';

export const useDataForTable = (
  setEmployeeClicked: (row: FinancePlanUser) => void,
  setModalEditEmployeesIsOpen: (value: boolean) => void,
  setModalDeleteEmployeeIsOpen: (value: boolean) => void,
  currentPlanCurrency: CurrencyType | undefined,
) => {
  const intl = useIntl();

  const tableColumns = useMemo(
    () => [
      {
        name: <div className="column-name">{intl.formatMessage(messages.membersLabel)}</div>,
        sortName: 'user',
        modifier: (row: FinancePlanUser) =>
          !row?.total ? (
            row?.user && <TableUserAvatar users={[row?.user]} fileSize={48} />
          ) : (
            <div>{intl.formatMessage(messages.totalRow)}</div>
          ),
        className: 'table__data--bold',
      },
      {
        name: <div>{intl.formatMessage(messages.hourPriceLabel)}</div>,
        sortName: 'rate',
        modifier: (row: FinancePlanUser) =>
          !row?.total
            ? `${formatValue({
                value: row.rate?.value,
              })} ${currentPlanCurrency?.name}`
            : null,
        className: 'table__data--right',
      },
      {
        name: <div>{intl.formatMessage(messages.plannedHoursColumn)}</div>,
        sortName: 'workHours',
        modifier: (row: FinancePlanUser) => (
          <span style={{ ...(!(Number(row.workHours) > 0) && { color: 'red' }) }}>
            {formatValue({
              value: Number(row.workHours || 0).toFixed(2),
            })}
          </span>
        ),
        className: 'table__data--right',
      },
      {
        name: <div>{intl.formatMessage(messages.percentOfRegulatoryColumn)}</div>,
        sortName: 'hoursPercent',
        modifier: (row: FinancePlanUser) =>
          !row?.total ? (
            <span style={{ ...(!(Number(row.hoursPercent) > 0) && { color: 'red' }) }}>
              {`${getForamtedNumber(row.hoursPercent)}%`}
            </span>
          ) : null,
        className: 'table__data--right',
      },
      {
        name: <div>{intl.formatMessage(messages.plannedIncomeColumn)}</div>,
        modifier: (row: FinancePlanUser) =>
          !row?.total
            ? `${
                formatValue({
                  value: (+row.rate?.value * +(row.workHours || 0)).toFixed(2),
                }) || 0
              } ${currentPlanCurrency?.name}`
            : `${
                formatValue({
                  //@ts-ignore
                  value: row.plannedIncome.toFixed(2),
                }) || 0
              } ${currentPlanCurrency?.name}`,
        className: 'table__data--right',
      },
      {
        name: <div>{intl.formatMessage(messages.hourPriceLabel)}</div>,
        sortName: 'rateFinalized',
        modifier: (row: FinancePlanUser) =>
          !row?.total ? (
            <span
              style={{
                ...(Number(row.rateFinalized || 0) > 0 &&
                  Number(row.rateFinalized) !== Number(row.rate.value) && { color: 'red' }),
              }}
            >
              {`${formatValue({
                value: row.rateFinalized || row.rate?.value,
              })} ${currentPlanCurrency?.name}`}
            </span>
          ) : null,
        className: 'table__data--right',
      },
      {
        name: <div>{intl.formatMessage(messages.actualHoursColumn)}</div>,
        sortName: 'workHoursActual',
        modifier: (row: FinancePlanUser) => (
          <span>
            {formatValue({
              value: Number(row.workHoursActual || 0).toFixed(2),
            })}
          </span>
        ),
        className: 'table__data--right',
      },
      {
        name: <div>{intl.formatMessage(messages.finalHours)}</div>,
        sortName: 'workHoursFinalized',
        modifier: (row: FinancePlanUser) => (
          <span
            style={{
              ...(Number(row.workHoursFinalized) > 0 &&
                Number(row.workHoursFinalized) !== Number(row.workHoursActual) &&
                !row.total && { color: 'red' }),
            }}
          >
            {formatValue({
              value: Number(row.workHoursFinalized || row.workHoursActual || 0).toFixed(2),
            })}
          </span>
        ),
        className: 'table__data--right',
      },
      {
        name: <div>{intl.formatMessage(messages.finalIncome)}</div>,
        modifier: (row: FinancePlanUser) =>
          !row?.total
            ? `${
                formatValue({
                  value: (+Number(row.rateFinalized || 0) * +(row.workHoursFinalized || 0)).toFixed(2),
                }) || 0
              } ${currentPlanCurrency?.name}`
            : `${
                formatValue({
                  //@ts-ignore
                  value: row.finalIncome.toFixed(2),
                }) || 0
              } ${currentPlanCurrency?.name}`,
        className: 'table__data--right',
      },
    ],
    [currentPlanCurrency],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlanUser) => {
          setEmployeeClicked(row);
          setModalEditEmployeesIsOpen(true);
        },
        verifiablePolicies: [UPDATE_FINANCE_PLAN],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlanUser) => {
          setEmployeeClicked(row);
          setModalDeleteEmployeeIsOpen(true);
        },
        verifiablePolicies: [DELETE_FINANCE_PLAN],
      },
    ],
    [],
  );

  const tableHeaderItems = [
    { name: '', className: 'table__head-column one-column', colspan: 1, sortName: '' },
    {
      name: intl.formatMessage(messages.plannedLabel),
      className: 'header_row two-column',
      colspan: 4,
      sortName: '',
    },
    {
      name: intl.formatMessage(messages.finalizedLabel),
      className: 'header_row two-column',
      colspan: 4,
      sortName: '',
    },
    { name: '', className: 'table__head-column one-column', colspan: 1, sortName: '' },
  ];

  return {
    tableColumns,
    tableActions,
    tableHeaderItems,
  };
};
