import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/finance.constants';
import {
  Client,
  EmployeeHours,
  FinancePlan,
  FinanceProject,
  ImportTransaction,
  OfficeBalanceClass,
  PlannedExpenseClass,
  Salary,
  Transaction,
} from '../enums/finance/finance.enum';
import * as paramsTypes from '../enums/params/finance.params';
import * as cashflowTypes from '../enums/params/cashflow.params';
import * as financeTypes from '../types/finance';

export const getIncomeTypesList = (): AnyAction => ({
  type: ActionTypes.GET_INCOME_TYPES_LIST_REQUEST,
});

export const getIncomeTypesFullList = (data: { callback: (results: any) => void }): AnyAction => ({
  type: ActionTypes.GET_INCOME_TYPES_FULL_LIST,
  payload: data,
});

export const createIncomeType = (data: { data: financeTypes.IncomeType; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_INCOME_TYPE_REQUEST,
  payload: data,
});

export const editIncomeType = (data: { data: financeTypes.IncomeType; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_INCOME_TYPE_REQUEST,
  payload: data,
});

export const deleteIncomeType = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_INCOME_TYPE_REQUEST,
  payload: data,
});

export const resetErrors = (): AnyAction => ({
  type: ActionTypes.RESET_FINANCE_ERRORS,
});

export const resetState = (): AnyAction => ({
  type: ActionTypes.RESET_FINANCE_STATE,
});

export const setIncomeTypesParams = (data: Partial<paramsTypes.IncomeTypesParams>): AnyAction => ({
  type: ActionTypes.SET_INCOME_TYPES_PARAMS,
  payload: data,
});

export const getCurrenciesList = (): AnyAction => ({
  type: ActionTypes.GET_CURRENCIES_LIST_REQUEST,
});

export const createCurrency = (data: { data: financeTypes.CurrencyType; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_CURRENCY_REQUEST,
  payload: data,
});

export const editCurrency = (data: { data: financeTypes.CurrencyType; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_CURRENCY_REQUEST,
  payload: data,
});

export const deleteCurrency = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_CURRENCY_REQUEST,
  payload: data,
});

export const setCurrenciesParams = (data: Partial<paramsTypes.CurrenciesParams>): AnyAction => ({
  type: ActionTypes.SET_CURRENCIES_PARAMS,
  payload: data,
});

export const getClientsList = (): AnyAction => ({
  type: ActionTypes.GET_CLIENTS_LIST_REQUEST,
});

export const createClient = (data: { data: Client; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_CLIENT_REQUEST,
  payload: data,
});

export const editClient = (data: { data: Client; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_CLIENT_REQUEST,
  payload: data,
});

export const deleteClient = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_CLIENT_REQUEST,
  payload: data,
});

export const setClientsParams = (data: Partial<paramsTypes.ClientsParams>): AnyAction => ({
  type: ActionTypes.SET_CLIENTS_PARAMS,
  payload: data,
});

export const getSuppliersList = (): AnyAction => ({
  type: ActionTypes.GET_SUPPLIERS_LIST_REQUEST,
});

export const createSupplier = (data: { data: financeTypes.SupplierType; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_SUPPLIER_REQUEST,
  payload: data,
});

export const editSupplier = (data: { data: financeTypes.SupplierType; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_SUPPLIER_REQUEST,
  payload: data,
});

export const deleteSupplier = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_SUPPLIER_REQUEST,
  payload: data,
});

export const setSuppliersParams = (data: Partial<paramsTypes.SuppliersParams>): AnyAction => ({
  type: ActionTypes.SET_SUPPLIERS_PARAMS,
  payload: data,
});

export const getProjectTypesList = (): AnyAction => ({
  type: ActionTypes.GET_PROJECT_TYPES_LIST_REQUEST,
});

export const createProjectType = (data: { data: financeTypes.ProjectType; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_PROJECT_TYPE_REQUEST,
  payload: data,
});

export const editProjectType = (data: { data: financeTypes.ProjectType; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_PROJECT_TYPE_REQUEST,
  payload: data,
});

export const deleteProjectType = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_PROJECT_TYPE_REQUEST,
  payload: data,
});

export const setProjectTypesParams = (data: Partial<paramsTypes.ProjectTypesParams>): AnyAction => ({
  type: ActionTypes.SET_PROJECT_TYPES_PARAMS,
  payload: data,
});

export const getExpenseTypesFullList = (data: { callback: (results: any) => void }): AnyAction => ({
  type: ActionTypes.GET_EXPENSE_TYPES_FULL_LIST,
  payload: data,
});

export const getExpenseTypesList = (): AnyAction => ({
  type: ActionTypes.GET_EXPENSE_TYPES_LIST_REQUEST,
});

export const createExpenseType = (data: { data: financeTypes.ExpenseType; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_EXPENSE_TYPE_REQUEST,
  payload: data,
});

export const editExpenseType = (data: { data: financeTypes.ExpenseType; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_EXPENSE_TYPE_REQUEST,
  payload: data,
});

export const deleteExpenseType = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_EXPENSE_TYPE_REQUEST,
  payload: data,
});

export const setExpenseTypesParams = (data: Partial<paramsTypes.ExpenseTypesParams>): AnyAction => ({
  type: ActionTypes.SET_EXPENSE_TYPES_PARAMS,
  payload: data,
});

export const getSalary = (id: string): AnyAction => ({
  type: ActionTypes.GET_SALARY_REQUEST,
  payload: id,
});

export const editSalary = (data: { data: Salary; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_SALARY_REQUEST,
  payload: data,
});

export const deleteSalary = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_SALARY_REQUEST,
  payload: data,
});

export const setSalariesParams = (data: Partial<paramsTypes.SalariesParams>): AnyAction => ({
  type: ActionTypes.SET_SALARIES_PARAMS,
  payload: data,
});

export const changeClientStatus = (id: string, isActive: string): AnyAction => ({
  type: ActionTypes.CHANGE_CLIENT_STATUS,
  payload: { id, isActive },
});

export const getProjectsList = (): AnyAction => ({
  type: ActionTypes.GET_FINANCE_PROJECTS_LIST_REQUEST,
});

export const createProject = (data: {
  data: FinanceProject;
  callback: () => void;
  forceUpdate?: boolean;
}): AnyAction => ({
  type: ActionTypes.POST_FINANCE_PROJECT_REQUEST,
  payload: data,
});

export const editProject = (data: { data: FinanceProject; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_FINANCE_PROJECT_REQUEST,
  payload: data,
});

export const deleteProject = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_FINANCE_PROJECT_REQUEST,
  payload: data,
});

export const changeProjectStatus = (id: string, isActive: string): AnyAction => ({
  type: ActionTypes.CHANGE_FINANCE_PROJECT_STATUS,
  payload: { id, isActive },
});

export const setProjectsParams = (data: Partial<paramsTypes.FinanceProjectsParams>): AnyAction => ({
  type: ActionTypes.SET_FINANCE_PROJECTS_PARAMS,
  payload: data,
});

export const setIncomesExpensesReportParams = (data: Partial<paramsTypes.IncomesExpensesReportParams>): AnyAction => ({
  type: ActionTypes.SET_INCOMES_EXPENSES_REPORT_PARAMS,
  payload: data,
});

export const getIncomesExpensesReport = (): AnyAction => ({
  type: ActionTypes.GET_INCOMES_EXPENSES_REPORT_REQUEST,
});

export const setProfitLossParams = (data: Partial<paramsTypes.OfficesBalanceParams>): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS,
  payload: data,
});

export const getProfitLossRevenueClient = (): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS_REVENUE_CLIENT,
});

export const putProfitLossRevenueClient = (data: cashflowTypes.ProfitLossRevenueClient): AnyAction => ({
  type: ActionTypes.PUT_PROFIT_LOSS_REVENUE_CLIENT,
  payload: data,
});

export const getProfitLossOperatingIncome = (): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS_OPERATING_INCOME,
});

export const putProfitLossOperatingIncome = (data: string): AnyAction => ({
  type: ActionTypes.PUT_PROFIT_LOSS_OPERATING_INCOME,
  payload: data,
});

export const getProfitLossNetIncome = (): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS_NET_INCOME,
});

export const putProfitLossNetIncome = (data: string): AnyAction => ({
  type: ActionTypes.PUT_PROFIT_LOSS_NET_INCOME,
  payload: data,
});

export const getProfitLossGrossProfit = (): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS_GROSS_PROFIT,
});

export const putProfitLossGrossProfit = (data: string): AnyAction => ({
  type: ActionTypes.PUT_PROFIT_LOSS_GROSS_PROFIT,
  payload: data,
});

export const getProfitLossRevenueExpense = (): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS_REVENUE_EXPENSE,
});

export const putProfitLossRevenueExpense = (data: string): AnyAction => ({
  type: ActionTypes.PUT_PROFIT_LOSS_REVENUE_EXPENSE,
  payload: data,
});

export const getProfitLossTotalGoods = (): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS_TOTAL_GOODS,
});

export const putProfitLossTotalGoods = (data: string): AnyAction => ({
  type: ActionTypes.PUT_PROFIT_LOSS_TOTAL_GOODS,
  payload: data,
});

export const getProfitLossFinancialRevenue = (): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS_FINANCIAL_REVENUE,
});

export const putProfitLossFinancialRevenue = (data: cashflowTypes.CashFlowSettingClass): AnyAction => ({
  type: ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_REVENUE,
  payload: data,
});

export const getProfitLossCostOfProject = (data: string): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS_COST_PROJECT,
  payload: data,
});

export const putProfitLossCostOfProject = (data: any): AnyAction => ({
  type: ActionTypes.PUT_PROFIT_LOSS_COST_PROJECT,
  payload: data,
});

export const getProfitLossFinancialExpense = (data: string): AnyAction => ({
  type: ActionTypes.GET_PROFIT_LOSS_FINANCIAL_EXPENSE,
  payload: data,
});

export const putProfitLossFinancialExpense = (data: any): AnyAction => ({
  type: ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_EXPENSE,
  payload: data,
});

export const setCashFlowReportParams = (data: Partial<cashflowTypes.CashFlowReportParams>): AnyAction => ({
  type: ActionTypes.SET_CASH_FLOW_REPORT_PARAMS,
  payload: data,
});

export const getCashFlowTotalSetting = (): AnyAction => ({
  type: ActionTypes.GET_CASH_FLOW_TOTAL_SETTING_REQUEST,
});

export const putCashFlowTotalSetting = (data: string): AnyAction => ({
  type: ActionTypes.PUT_CASH_FLOW_TOTAL_SETTING_REQUEST,
  payload: data,
});

export const getCashFlowBalanceSetting = (): AnyAction => ({
  type: ActionTypes.GET_CASH_FLOW_BALANCE_SETTING_REQUEST,
});

export const putCashFlowBalanceSetting = (data: string): AnyAction => ({
  type: ActionTypes.PUT_CASH_FLOW_BALANCE_SETTING_REQUEST,
  payload: data,
});

export const getCashFlowExpenseSetting = (data: string): AnyAction => ({
  type: ActionTypes.GET_CASH_FLOW_EXPENSE_SETTING_REQUEST,
  payload: data,
});

export const getCashFlowIncomeSetting = (data: string): AnyAction => ({
  type: ActionTypes.GET_CASH_FLOW_INCOME_SETTING_REQUEST,
  payload: data,
});

export const putCashFlowExpenseSetting = (data: any): AnyAction => ({
  type: ActionTypes.PUT_CASH_FLOW_EXPENSE_SETTING_REQUEST,
  payload: data,
});

export const putCashFlowIncomeSetting = (data: any): AnyAction => ({
  type: ActionTypes.PUT_CASH_FLOW_INCOME_SETTING_REQUEST,
  payload: data,
});

export const resetCashFlowReportSetting = (): AnyAction => ({
  type: ActionTypes.RESET_CASH_FLOW_SETTING,
});

export const setOfficeBalanceParams = (data: Partial<paramsTypes.OfficesBalanceParams>): AnyAction => ({
  type: ActionTypes.SET_OFFICES_BALANCE_PARAMS,
  payload: data,
});

export const editOfficeBalance = (data: { data: OfficeBalanceClass; callback: () => void }): AnyAction => {
  return {
    type: ActionTypes.PUT_OFFICE_BALANCE_REQUEST,
    payload: data,
  };
};

export const getTransactionsList = (): AnyAction => ({
  type: ActionTypes.GET_TRANSACTIONS_LIST_REQUEST,
});

export const createTransactions = (data: { data: Transaction; callback: () => void }): AnyAction => ({
  type: ActionTypes.POST_TRANSACTION_REQUEST,
  payload: data,
});

export const batchCreateTransactions = (data: { data: ImportTransaction[]; callback: () => void }): AnyAction => ({
  type: ActionTypes.IMPORT_TRANSACTIONS_REQUEST,
  payload: data,
});

export const editTransaction = (data: { data: Transaction; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_TRANSACTION_REQUEST,
  payload: data,
});

export const deleteTransaction = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_TRANSACTION_REQUEST,
  payload: data,
});

export const setTransactionsParams = (data: Partial<paramsTypes.TransactionsParams>): AnyAction => ({
  type: ActionTypes.SET_TRANSACTIONS_PARAMS,
  payload: data,
});

export const getPlansList = (): AnyAction => ({
  type: ActionTypes.GET_FINANCE_PLANS_LIST_REQUEST,
});

export const getPlan = (id: string): AnyAction => ({
  type: ActionTypes.GET_FINANCE_PLAN_REQUEST,
  payload: id,
});

export const setPlansParams = (data: Partial<paramsTypes.FinancePlanParams>): AnyAction => ({
  type: ActionTypes.SET_FINANCE_PLANS_PARAMS,
  payload: data,
});

export const createPlan = (data: { data: FinancePlan; callback: (financePlanId?: string) => void }): AnyAction => ({
  type: ActionTypes.POST_FINANCE_PLAN_REQUEST,
  payload: data,
});

export const editPlan = (data: { data: FinancePlan; callback: () => void; noGetPlans?: boolean }): AnyAction => ({
  type: ActionTypes.PUT_FINANCE_PLAN_REQUEST,
  payload: data,
});

export const clonePlan = (data: {
  data: FinancePlan;
  callback: (id: string) => void;
  noGetPlans?: boolean;
  closeConfirmModal: () => void;
  isConfirmModal: boolean;
}): AnyAction => ({
  type: ActionTypes.CLONE_FINANCE_PLAN_REQUEST,
  payload: data,
});

export const resetClonePlanCheckedInfo = (): AnyAction => ({
  type: ActionTypes.RESET_CLONE_FINANCE_PLAN_CHECKED_INFO,
});

export const deletePlan = (data: { id: string; callback: () => void; noGetPlans?: boolean }): AnyAction => ({
  type: ActionTypes.DELETE_FINANCE_PLAN_REQUEST,
  payload: data,
});

export const getPlanEmployees = (data: { planId: string }): AnyAction => ({
  type: ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_REQUEST,
  payload: data,
});

export const getEmployeeHours = (id: string, month: string): AnyAction => ({
  type: ActionTypes.GET_EMPLOYEE_HOURS_REQUEST,
  payload: { id, month },
});

export const resetEmployeeHours = (): AnyAction => ({
  type: ActionTypes.RESET_EMPLOYEE_HOURS,
});

export const setPlanMonth = (month: string, cb: (id?: string) => void): AnyAction => ({
  type: ActionTypes.SET_FINANCE_PLAN_MONTH,
  payload: {
    month,
    cb,
  },
});

export const setPlanEmployeesParams = (data: Partial<paramsTypes.FinancePlanEmployeesParams>): AnyAction => ({
  type: ActionTypes.SET_FINANCE_PLAN_EMPLOYEES_PARAMS,
  payload: data,
});

export const createPlanEmployee = (data: {
  id: string;
  data: EmployeeHours & { currentLoad: financeTypes.EmployeeHoursPlanLoadType };
  callback: () => void;
}): AnyAction => ({
  type: ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_REQUEST,
  payload: data,
});

export const editPlanEmployee = (data: {
  id: string;
  data: EmployeeHours & {
    currentLoad: financeTypes.EmployeeHoursPlanLoadType;
  };
  callback: () => void;
}): AnyAction => ({
  type: ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_REQUEST,
  payload: data,
});

export const editPlanEmployeeFinalize = (data: { id: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_FINALIZE_REQUEST,
  payload: data,
});

export const getDatesOfExistingPlans = (data: financeTypes.DatesOfExistingPlansParams): AnyAction => ({
  type: ActionTypes.GET_DATES_OF_EXISTING_PLANS_REQUEST,
  payload: data,
});

export const resetDatesOfExistingPlans = () => ({
  type: ActionTypes.RESET_DATES_OF_EXISTING_PLANS,
});

export const deletePlanEmployee = (data: { planId: string; employeeId: string; callback: () => void }): AnyAction => ({
  type: ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_REQUEST,
  payload: data,
});

export const importResources = (data: {
  data: { financePlanId: string; month: string; projectGroupId: string };
  callback: () => void;
  closeConfirmModal: () => void;
  isConfirmModal: boolean;
}): AnyAction => ({
  type: ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_REQUEST,
  payload: data,
});

export const resetImportResourcesCheckedInfo = (): AnyAction => ({
  type: ActionTypes.RESET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO,
});

export const setPlannedExpenseParams = (data: Partial<paramsTypes.PlannedExpenseParams>): AnyAction => ({
  type: ActionTypes.SET_PLANNED_EXPENSE_PARAMS,
  payload: data,
});

export const editPlannedExpense = (data: { data: PlannedExpenseClass; callback: () => void }): AnyAction => {
  return {
    type: ActionTypes.PUT_PLANNED_EXPENSE_REQUEST,
    payload: data,
  };
};

export const deletePlannedExpense = (data: {
  id: string;
  deleteFutureExpenses: boolean;
  callback: () => void;
}): AnyAction => {
  return {
    type: ActionTypes.DELETE_PLANNED_EXPENSE_REQUEST,
    payload: data,
  };
};
