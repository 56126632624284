import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import { CurrencyType } from '../../../types/finance';
import Button from '../../Button';
import Icon from '../../Icon';
// import ModalEditCashFlowSettings from '../Modals/ModalEditCashFlowSettings';
// import { getMonths } from './utils';
import { EProfitLossCost } from '../../../pages/ProfitLossReport';
import ModalEditCostOfProject from '../Modal/CostOfProjectModal';
import { getReportMonths } from '../../../utils/finance.utils';
import { useDataForTable } from './useDataForTable';
import HierarchicalTable from '../../HierarchicalTable';
import { useTableData } from '../../../utils/hooks.utils';

type ProfitLossTableProps = {
  title: EProfitLossCost;
  tableData: any;
  months: any[];
  errors: string | null;
  isLoading: boolean;
  baseCurrency: CurrencyType | undefined;
  settingsData: any;
  types: any;
  settingsLoading: boolean;
  settingsError: string | null;
  getTypesList: () => void;
  getSettings: (data: EProfitLossCost) => void;
  putSettings: (data: any) => void;
  onCloseSettings: () => void;
};

const CostOfGoodsTable = ({
  title,
  tableData,
  months,
  baseCurrency,
  errors,
  isLoading,
  settingsData,
  types,
  settingsError,
  settingsLoading,
  getTypesList,
  getSettings,
  putSettings,
  onCloseSettings,
}: ProfitLossTableProps) => {
  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const convertedData = useMemo(() => {
    if (tableData) {
      const isGrouped = settingsData ? settingsData[title]?.groupByClient : false;
      let tableTypes = [];
      const clients = Array.from(
        new Set(
          tableData
            .map((data: any) => data.clientId)
            .filter((value: any, index: number, a: any[]) => a.some((v2, i2) => value === v2 && index !== i2)),
        ),
      );

      if (isGrouped && clients.length) {
        const types = tableData
          ?.map((data: any) => (clients.includes(data.clientId) ? data.client : data.financeProject))
          .reduce((item: any, o: any) => {
            if (!item.some((obj: any) => obj.id === o.id)) {
              item.push(o);
            }

            return item;
          }, []);

        tableTypes = types.map((type: any) => {
          const isClient = !type.clientId;
          const filtered = tableData.filter(
            (data: any) => (isClient ? data.clientId : data.financeProjectId) === type.id,
          );
          const projects = isClient
            ? Array.from(new Set(filtered.map((it: any) => it.financeProjectId))).filter(it => !!it)
            : null;
          const offices = !isClient
            ? Array.from(new Set(filtered.map((it: any) => it.officeId))).filter(it => !!it)
            : null;

          return {
            id: type.id,
            name: type.name,
            total: filtered
              .map((it: any) => Number(it.reportAmount))
              .reduce((partialSum: any, a: any) => partialSum + a, 0),
            months: getReportMonths(filtered, months, 'reportDate', 'reportAmount'),
            subTypes: isClient
              ? projects?.map((item: any) => {
                  const project = filtered.find((it: any) => it.financeProjectId === item);
                  const projects = filtered.filter((it: any) => it.financeProjectId === item);
                  const uniqueOffices = Array.from(new Set(projects.map((it: any) => it.officeId))).filter(of => !!of);

                  return {
                    name: project.financeProject.name,
                    id: project.financeProject.id,
                    months: getReportMonths(projects, months, 'reportDate', 'reportAmount'),
                    total: projects
                      .map((it: any) => Number(it.reportAmount))
                      .reduce((partialSum: any, a: any) => partialSum + a, 0),
                    subTypes: uniqueOffices.map(unique => {
                      const cuttedOffices = projects.filter((office: any) => office.officeId === unique);

                      return {
                        name: cuttedOffices[0].office.name,
                        officeId: cuttedOffices[0].officeId,
                        total: cuttedOffices
                          .map((it: any) => Number(it.reportAmount))
                          .reduce((partialSum: any, a: any) => partialSum + a, 0),
                        months: getReportMonths(cuttedOffices, months, 'reportDate', 'reportAmount'),
                      };
                    }),
                  };
                })
              : offices?.map((office: any) => {
                  const officeObject = filtered.find((it: any) => it.officeId === office);
                  if (officeObject)
                    return {
                      id: officeObject.office.id,
                      name: officeObject.office.name,
                      total: filtered
                        .filter((it: any) => it.officeId === office)
                        .map((it: any) => Number(it.reportAmount))
                        .reduce((partialSum: any, a: any) => partialSum + a, 0),
                      months: getReportMonths(
                        filtered.filter((it: any) => it.officeId === office),
                        months,
                        'reportDate',
                        'reportAmount',
                      ),
                    };
                }),
          };
        });
      } else {
        const types = tableData
          ?.map((data: any) => data.financeProject)
          .reduce((item: any, o: any) => {
            if (!item.some((obj: any) => obj.id === o.id)) {
              item.push(o);
            }

            return item;
          }, []);

        if (types?.length) {
          tableTypes = types.map((type: any) => {
            const filtered = tableData.filter((data: any) => data.financeProjectId === type.id);

            const uniqueOffices = Array.from(new Set(filtered.map((it: any) => it.officeId))).filter(it => !!it);

            return {
              id: type.id,
              name: type.name,
              total: filtered
                .map((it: any) => Number(it.reportAmount))
                .reduce((partialSum: any, a: any) => partialSum + a, 0),
              months: getReportMonths(filtered, months, 'reportDate', 'reportAmount'),
              subTypes: uniqueOffices.map((office: any) => {
                const officeObject = filtered.find((it: any) => it.officeId === office);
                if (officeObject)
                  return {
                    id: officeObject.office.id,
                    name: officeObject.office.name,
                    total: filtered
                      .filter((it: any) => it.officeId === office)
                      .map((it: any) => Number(it.reportAmount))
                      .reduce((partialSum: any, a: any) => partialSum + a, 0),
                    months: getReportMonths(
                      filtered.filter((it: any) => it.officeId === office),
                      months,
                      'reportDate',
                      'reportAmount',
                    ),
                  };
              }),
            };
          });
        }
      }
      tableTypes?.push({
        totalItem: true,
        total: tableData
          .map((it: any) => Number(it.reportAmount))
          .reduce((partialSum: any, a: any) => partialSum + a, 0),
        months: getReportMonths(tableData, months, 'reportDate', 'reportAmount'),
      });

      return tableTypes;
    }

    return [];
  }, [tableData]);

  const openSetting = () => {
    getSettings(title);
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: any) => {
    putSettings(params);
  };

  const { tableColumns, tableHeaderItems } = useDataForTable(convertedData, baseCurrency, 'expenseColumn');

  return (
    <>
      <div className="cash-flow-report__table-head">
        <h2 className="cash-flow__block-title">
          {settingsData && settingsData[title] && settingsData[title].name?.length ? (
            settingsData[title].name
          ) : (
            <FormattedMessage {...messages[title]} />
          )}
        </h2>
        <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
          <Icon iconName="pencil" externalClass="button__icon" />
        </Button>
      </div>
      <div className="page__scrollable-table-wrapper cash-flow-report">
        <div className="page__scrollable-table-wrapper__inner cash-flow-report-wrapper">
          <HierarchicalTable
            tableData={useTableData(convertedData, ['subTypes', 'subTypes'])}
            tableColumns={tableColumns}
            loading={isLoading}
            error={errors}
            tableHeaderItems={tableHeaderItems}
          />
        </div>
      </div>
      {openSettingsModal && (
        <ModalEditCostOfProject
          isOpen={openSettingsModal}
          getTypesList={getTypesList}
          typesList={types}
          type={title}
          onCloseRequest={closeSetting}
          editSetting={editSetting}
          loading={settingsLoading}
          error={settingsError}
          data={settingsData ? settingsData[title] : null}
        />
      )}
    </>
  );
};

export default CostOfGoodsTable;
