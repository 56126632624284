import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import moment from 'moment';

import * as filtersActions from '../../actions/filters.actions';
import * as financeActions from '../../actions/finance.actions';
import InlineDatePicker from '../../components/InlineDatePicker';
import OfficesBalanceFilters from '../../components/OfficesBalance/Filters/OfficesBalanceFilters';
import { DATE_FORMAT } from '../../constants/date.constants';
import { FilterParamsName } from '../../constants/filters.constants';
import { officesBalanceUnsavedParams } from '../../constants/finance.constants';
import { VIEW_CASH_FLOW, VIEW_OFFICE_BALANCE } from '../../constants/policies.constants';
import { OfficeBalanceClass } from '../../enums/finance/finance.enum';
import { OfficesBalanceParams } from '../../enums/params/finance.params';
import { CashFlowReportParams } from '../../enums/params/cashflow.params';
import PoliciesContext from '../../PoliciesContext';
import { getBaseCurrency } from '../../utils';
import { checkParamsMatch } from '../../utils/filters.utils';
import { resetParamsChange, useParamsChange } from '../../utils/hooks.utils';
import messages from './messages';
import CashFlowTable, { ECashFlowTitle } from '../../components/CashFlowReport/Table/CashFlowTable';
import { OfficeInfo } from '../../enums/libraries.enum';
import OfficeBlockTable from '../../components/CashFlowReport/Table/OfficeBalanceTable';
import TotalBlockTable from '../../components/CashFlowReport/Table/TotalBlockTable';

function CashFlowReport({
  tableData,
  officesFilter,
  isLoading,
  errors,
  params,
  finance,
  getOfficesFilter,
  resetState,
  resetErrors,
  currencies,
  getCurrenciesFilter,
  getTotalSettings,
  getBalanceSettings,
  putTotalSettings,
  putBalanceSettings,
  setCashFlowReportParams,
  setOfficesBalanceParams,
  getExpenseSettings,
  getIncomeSettings,
  putExpenseSettings,
  putIncomeSettings,
  getExpenseFullList,
  getIncomeFullList,
  resetSettings,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_CASH_FLOW), [policies]);

  const viewOfficePolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_OFFICE_BALANCE), [
    policies,
  ]);

  useEffect(() => {
    setCashFlowReportParams({
      officeIds: officesFilter.value?.filter(
        (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
      ),
    });
  }, [viewPolicy]);

  useEffect(() => {
    setOfficesBalanceParams({
      officeIds: officesFilter.value?.filter(
        (el: string) => !viewOfficePolicy?.isOfficeSpecific || viewOfficePolicy?.officeIds?.some(id => id === el),
      ),
    });
  }, [viewOfficePolicy]);

  useEffect(() => {
    getOfficesFilter();
    getCurrenciesFilter();

    getTotalSettings();
    getBalanceSettings();

    return () => {
      resetState();
    };
  }, []);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const handleMultiParamsChange = useParamsChange(data => {
    setCashFlowReportParams(data);
    setOfficesBalanceParams(data);
  }, dispatch);

  const handleDataChange = useCallback((start: string, end: string) => {
    setCashFlowReportParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
    setOfficesBalanceParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  }, []);

  const months = useMemo(() => {
    const startDate = moment(params.dateFrom);
    const endDate = moment(params.dateTo);

    const diff = endDate.diff(startDate, 'months', true);
    const range = [];

    for (let i = 0; i < diff; i++) {
      range.push(moment(params.dateFrom).add(i, 'month').format('YYYY-MM-DD'));
    }
    return range;
  }, [params.dateFrom, params.dateTo]);

  const filters = useMemo(
    () => ({
      offices: officesFilter.offices,
    }),
    [officesFilter.offices],
  );

  const handleClear = useCallback(() => {
    setCashFlowReportParams(new CashFlowReportParams());
    setOfficesBalanceParams(new OfficesBalanceParams());

    resetParamsChange([FilterParamsName.OFFICE_IDS], dispatch);
  }, []);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new CashFlowReportParams(), officesBalanceUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                  todayBtn={false}
                  weekBtn={false}
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <OfficesBalanceFilters
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleClear={handleClear}
                  policyOfficiesIds={viewPolicy?.officeIds}
                  isOfficeSpecific={!!viewPolicy?.isOfficeSpecific}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          {[
            ECashFlowTitle.FIRST_INCOME_BLOCK,
            ECashFlowTitle.FIRST_EXPENSE_BLOCK,
            ECashFlowTitle.SECOND_INCOME_BLOCK,
            ECashFlowTitle.SECOND_EXPENSE_BLOCK,
          ].map((item: ECashFlowTitle) => (
            <CashFlowTable
              key={item}
              title={item}
              tableData={tableData}
              months={months}
              errors={errors}
              blockType={item}
              settingsData={finance.cashFlowReportSetting}
              settingsLoading={finance.loading.getCashFlowReportSetting}
              settingsError={finance.errors.cashFlowReportSettingError}
              isLoading={isLoading.getCashFlowReport}
              baseCurrency={baseCurrency}
              getExpenseSettings={getExpenseSettings}
              putExpenseSettings={putExpenseSettings}
              getExpenseList={getExpenseFullList}
              getIncomeList={getIncomeFullList}
              getIncomeSettings={getIncomeSettings}
              putIncomeSettings={putIncomeSettings}
              onCloseSettings={resetErrors}
              resetSettings={resetSettings}
            />
          ))}
          <TotalBlockTable
            tableData={tableData}
            customTitle={finance.cashFlowReportTotalSetting}
            months={months}
            errors={errors}
            settingsData={finance.cashFlowReportSetting}
            settingsLoading={finance.loading.getCashFlowReportSetting}
            settingsError={finance.errors.cashFlowReportSettingError}
            isLoading={isLoading.getCashFlowReport}
            baseCurrency={baseCurrency}
            getExpenseSettings={getExpenseSettings}
            putSettings={putTotalSettings}
            onCloseSettings={resetErrors}
          />
          <OfficeBlockTable
            offices={(params.officeIds.length
              ? officesFilter.offices.filter((office: OfficeInfo) => params.officeIds.includes(office.id))
              : officesFilter.offices
            )?.filter(
              (el: OfficeInfo) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el.id),
            )}
            customTitle={finance.cashFlowReportBalanceSetting}
            months={months}
            tableData={tableData}
            errors={finance.errors.officesBalanceError}
            settingsData={finance.officesBalanceListData}
            settingsLoading={finance.loading.getOfficesBalance}
            settingsError={finance.errors.officesBalanceError}
            isLoading={finance.loading.getOfficesBalance}
            baseCurrency={baseCurrency}
            putBalanceSettings={putBalanceSettings}
            onCloseSettings={resetErrors}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ filters, auth, finance }: RootState) => ({
  finance: finance,
  tableData: finance.cashFlowReportListData,
  officesFilter: filters.officesFilter,
  isLoading: finance.loading,
  errors: finance.errors.cashFlowReportError,
  params: finance.cashFlowReportParams,
  authUserId: auth.currentUserInfo.id,
  currencies: filters.currenciesFilter.currencies,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  setCashFlowReportParams: (data: Partial<CashFlowReportParams>) =>
    dispatch(financeActions.setCashFlowReportParams(data)),
  setOfficesBalanceParams: (data: Partial<OfficesBalanceParams>) =>
    dispatch(financeActions.setOfficeBalanceParams(data)),
  editOfficeBalance: (data: { data: OfficeBalanceClass; callback: () => void }) =>
    dispatch(financeActions.editOfficeBalance(data)),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  getExpenseSettings: (data: string) => dispatch(financeActions.getCashFlowExpenseSetting(data)),
  getIncomeSettings: (data: string) => dispatch(financeActions.getCashFlowIncomeSetting(data)),
  getTotalSettings: () => dispatch(financeActions.getCashFlowTotalSetting()),
  getBalanceSettings: () => dispatch(financeActions.getCashFlowBalanceSetting()),
  putTotalSettings: (data: any) => dispatch(financeActions.putCashFlowTotalSetting(data)),
  putBalanceSettings: (data: any) => dispatch(financeActions.putCashFlowBalanceSetting(data)),
  putExpenseSettings: (data: any) => dispatch(financeActions.putCashFlowExpenseSetting(data)),
  putIncomeSettings: (data: any) => dispatch(financeActions.putCashFlowIncomeSetting(data)),
  getExpenseFullList: (data: any) => dispatch(financeActions.getExpenseTypesFullList(data)),
  getIncomeFullList: (data: any) => dispatch(financeActions.getIncomeTypesFullList(data)),
  resetSettings: () => dispatch(financeActions.resetCashFlowReportSetting()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CashFlowReport);
