import { defineMessages } from 'react-intl';
import { cancelButton, membersLabel, officesLabel, saveButton, totalRow } from '../../i18n/defaultMessage';

export default defineMessages({
  membersLabel,
  officesLabel,
  totalRow,
  cancelButton,
  saveButton,
  newOfficeBalanceTitle: {
    id: 'finance.office.balance.modal.new.office.balance.title.new.office.balance',
    defaultMessage: 'New Office Balance',
  },
  reset: {
    id: 'finance.office.balance.modal.label.reset',
    defaultMessage: 'Reset',
  },
  newValue: {
    id: 'finance.office.balance.modal.label.new.value',
    defaultMessage: 'New Value',
  },
  automaticalluCalculatedValue: {
    id: 'finance.office.balance.modal.label.automatically.calculated.value',
    defaultMessage: 'Automatically Calculated Value',
  },
});
