import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Icon from '../../components/Icon';
import { FinancePlan } from '../../enums/finance/finance.enum';
import { NavLink, useHistory } from 'react-router-dom';
import r from '../../constants/routes.constants';
import { DELETE_FINANCE_PLAN, UPDATE_FINANCE_PLAN } from '../../constants/policies.constants';
import { formatValue } from 'react-currency-input-field';
import classNames from 'classnames';
import { CurrencyType } from '../../types/finance';

export const useDataForTable = (
  baseCurrency: CurrencyType | undefined,
  setPlanClicked: (row: FinancePlan) => void,
  setModalEditPlanIsOpen: (value: boolean) => void,
  setModalDeletePlanIsOpen: (value: boolean) => void,
  setModalClonePlanIsOpen: (value: boolean) => void,
) => {
  const intl = useIntl();
  const history = useHistory();

  const tableColumns = useMemo(
    () => [
      {
        name: <div className="column-name">{intl.formatMessage(messages.clientLabel)}</div>,
        sortName: 'client.name',
        modifier: (row: FinancePlan) =>
          !row.total ? (
            <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
              <div className={'table__data-wrapper'}>
                <div className="name-capital-letter-block">
                  <span>{row.client?.name[0]}</span>
                </div>
                <div>{row.client?.name}</div>
              </div>
            </NavLink>
          ) : (
            <div className="table__type-link--bold">{intl.formatMessage(messages.totalRow)}</div>
          ),
        className: 'table__data--bold',
      },
      {
        name: <div className="column-name">{intl.formatMessage(messages.projectLabel)}</div>,
        modifier: (row: FinancePlan) =>
          !row.total ? (
            <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
              <span className={'table_button'}>{row.financeProject?.name}</span>
            </NavLink>
          ) : null,
      },
      {
        name: <div>{intl.formatMessage(messages.plannedLabel)}</div>,
        modifier: (row: FinancePlan) =>
          !row.total ? (
            <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
              {row.employees?.filter(item => item.workHours !== null && Number(item.workHours) > 0)?.length}
            </NavLink>
          ) : (
            //@ts-ignore
            <div className="table__type-link--bold">{row.workHours}</div>
          ),
      },
      {
        name: <div>{intl.formatMessage(messages.finalizedLabel)}</div>,
        modifier: (row: FinancePlan) =>
          !row.total ? (
            <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
              {
                row.employees?.filter(item => item.workHoursFinalized !== null && Number(item.workHoursFinalized) > 0)
                  ?.length
              }
            </NavLink>
          ) : (
            //@ts-ignore
            <div className="table__type-link--bold">{row.workHoursFinalized}</div>
          ),
      },
      {
        name: <div className="column-name">{intl.formatMessage(messages.hourPriceLabel)}</div>,
        sortName: 'baseRate',
        modifier: (row: FinancePlan) =>
          !row.total ? (
            <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
              {formatValue({
                value: row.baseRate?.value,
                suffix: ` ${baseCurrency?.name}`,
              })}
            </NavLink>
          ) : null,
      },
      {
        name: <div>{intl.formatMessage(messages.calculatedColumn)}</div>,
        sortName: 'calculatedPlannedIncome',
        modifier: (row: FinancePlan) => (
          <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
            {formatValue({
              value: Number(row.calculatedPlannedIncome ?? 0)
                ?.toFixed(2)
                ?.toString(),
              suffix: ` ${baseCurrency?.name}`,
            }) || 0}
          </NavLink>
        ),
      },
      {
        name: <div>{intl.formatMessage(messages.plannedLabel)}</div>,
        sortName: 'plannedIncome',
        modifier: (row: FinancePlan) => (
          <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
            <div className={classNames({ 'global-color': +row?.plannedIncome?.value <= 0 })}>
              {formatValue({
                value: Number((!row.total ? row.plannedIncome?.value : row.plannedIncome) || 0)?.toFixed(2),
                suffix: ` ${baseCurrency?.name}`,
              })}
            </div>
          </NavLink>
        ),
      },
      {
        name: <div>{intl.formatMessage(messages.finalizedLabel)}</div>,
        sortName: 'finalizedIncome',
        modifier: (row: FinancePlan) => (
          <NavLink className={'table__type-link'} to={`/planning/project-plan/${row.id}`}>
            {formatValue({
              value: Number(row.finalizedIncome || 0)
                ?.toFixed(2)
                ?.toString(),
              suffix: ` ${baseCurrency?.name}`,
            }) || 0}
          </NavLink>
        ),
      },
    ],
    [baseCurrency],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlan) => {
          setPlanClicked(row);
          setModalEditPlanIsOpen(true);
        },
        verifiablePolicies: [UPDATE_FINANCE_PLAN],
      },
      {
        label: (
          <>
            <Icon iconName={'users'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.membersLabel} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlan) => {
          setPlanClicked(row);
          history.push(r.projectPlan.replace(':id', row.id));
        },
      },
      {
        label: (
          <>
            <Icon iconName={'copy'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.cloneButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlan) => {
          setPlanClicked(row);
          setModalClonePlanIsOpen(true);
        },
        verifiablePolicies: [UPDATE_FINANCE_PLAN],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: FinancePlan) => {
          setPlanClicked(row);
          setModalDeletePlanIsOpen(true);
        },
        verifiablePolicies: [DELETE_FINANCE_PLAN],
      },
    ],
    [],
  );

  const tableHeaderItems = [
    { name: '', className: 'table__head-column one-column', colspan: 1, sortName: '' },
    { name: '', className: 'table__head-column one-column', colspan: 1, sortName: '' },
    {
      name: intl.formatMessage(messages.membersLabel),
      className: 'header_row two-column',
      colspan: 2,
      sortName: '',
    },
    { name: '', className: 'table__head-column one-column', colspan: 1, sortName: '' },
    {
      name: intl.formatMessage(messages.incomeLabel),
      className: 'header_row two-column',
      colspan: 3,
      sortName: '',
    },
    { name: '', className: 'table__head-column one-column', colspan: 1, sortName: '' },
  ];

  return {
    tableColumns,
    tableActions,
    tableHeaderItems,
  };
};
