import { defineMessages } from 'react-intl';
import {
  cancelButton,
  saveButton,
  deleteButton,
  deleteModalWithItemNameTitle,
  clientLabel,
  rateLabel,
  projectLabel,
  memberLabel,
  membersLabel,
  projectsLabel,
  hourPriceLabel,
  confirmButton,
} from '../../i18n/defaultMessage';

export default defineMessages({
  deleteButton,
  cancelButton,
  saveButton,
  deleteModalWithItemNameTitle,
  memberLabel,
  rateLabel,
  clientLabel,
  projectLabel,
  projectsLabel,
  membersLabel,
  hourPriceLabel,
  confirmButton,
  newPlanTitle: {
    id: 'finance.planning.modal.new.plan.title.new.plan',
    defaultMessage: 'New Plan',
  },
  editPlanTitle: {
    id: 'finance.planning.modal.edit.plan.title.edit.plan',
    defaultMessage: 'Edit Plan',
  },
  editIncomeTitle: {
    id: 'finance.planning.modal.edit.income.title.edit.plan',
    defaultMessage: 'Edit Planned Income',
  },
  monthLabel: {
    id: 'finance.planning.modal.edit.plan.label.month',
    defaultMessage: 'Month',
  },
  baseRateLabel: {
    id: 'finance.planning.modal.edit.plan.label.base.rate',
    defaultMessage: 'Base Rate',
  },
  plannedIncomeLabel: {
    id: 'finance.planning.modal.edit.plan.label.planned.income',
    defaultMessage: 'Planned Income',
  },
  addMemberTitle: {
    id: 'finance.planning.modal.add.plan.member.title.add.member',
    defaultMessage: 'Add Member',
  },
  editMemberTitle: {
    id: 'finance.planning.modal.add.plan.member.title.add.member',
    defaultMessage: 'Edit Member',
  },
  regulatoryHoursLabel: {
    id: 'finance.planning.modal.add.plan.member.label.regulatory.hours',
    defaultMessage: 'Regulatory Hours',
  },
  hoursOnOtherProjectsLabel: {
    id: 'finance.planning.modal.add.plan.member.label.hours.on.other.projects',
    defaultMessage: 'Hours on other Projects',
  },
  plannedHoursLabel: {
    id: 'finance.planning.modal.edit.plan.member.label.planned.hours',
    defaultMessage: 'Planed Hours',
  },
  finalizedTitle: {
    id: 'finance.planning.label.finalizing',
    defaultMessage: 'Finalizing Result',
  },
  actualHoursColumn: {
    id: 'finance.planning.label.actual.hours',
    defaultMessage: 'Actual Hours',
  },
  finalizeHours: {
    id: 'finance.planning.column.finalize.hours',
    defaultMessage: 'Finalizing Hours',
  },
  finalizedIncome: {
    id: 'finance.planning.column.finalized.income',
    defaultMessage: 'Finalized Income',
  },
  percentOfRegulatoryColumn: {
    id: 'nance.planning.modal.edit.plan.member.label.percent.of.regulatory',
    defaultMessage: '% of regulatory',
  },
  clientsLabel: {
    id: 'finance.planning.filter.clients',
    defaultMessage: 'Clients',
  },
  clonePlanTitle: {
    id: 'finance.planning.modal.clone.plan.title.edit.plan',
    defaultMessage: 'Clone Plan',
  },
  financeProjectIsNotActiveLabel: {
    id: 'finance.planning.modal.add.plan.member.label.finance.project.is.not.active',
    defaultMessage: ' Finance project is not active',
  },
  originalHourPriceLabel: {
    id: 'finance.planning.modal.edit.plan.label.original.hour.price',
    defaultMessage: 'Original hour price',
  },
  unifiedHourPriceLabel: {
    id: 'finance.planning.modal.edit.plan.label.unified.hour.price',
    defaultMessage: 'Unified hour price',
  },
  importResourcesModalTitle: {
    id: 'finance.planning.modal.import.resources.title.add.resources',
    defaultMessage: 'Add Resources',
  },
  projectGroupLabel: {
    id: 'finance.planning.modal.import.resources.label.project.group',
    defaultMessage: 'Project Group',
  },
  clientIsInactive: {
    id: 'finance.planning.modal.clone.error.client.is.inactive',
    defaultMessage: 'The plan can not be cloned as the client is not active',
  },
  projectIsInactive: {
    id: 'finance.planning.modal.clone.error.project.is.inactive',
    defaultMessage: 'The plan can not be cloned as the project is not active',
  },
  confirmModalTitle: {
    id: 'finance.planning.modal.confirm.clone.title.members.load.updated',
    defaultMessage: 'Members load updated',
  },
  confirmModalSubTitle: {
    id: 'finance.planning.modal.confirm.clone.subtitle.the.following.members.load.will.be.updated',
    defaultMessage:
      'The following members load will be updated for the new plan according to their load in other plans.',
  },
  availableLabel: {
    id: 'finance.planning.modal.employee.label.available',
    defaultMessage: 'Available',
  },
});
