import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import {
  Client,
  FinancePlan,
  PLAN_SCHEMA,
  FinanceProject,
  CurrencyFormatter,
} from '../../../enums/finance/finance.enum';
import Select from '../../Select';
import { getCurrentRateDate } from '../../../utils/finance.utils';
import CurrencyInput from '../../CurrencyInput';
import { CurrencyType, DatesOfExistingPlansParams } from '../../../types/finance';
import { getBaseCurrency, getFormattedDate, scrollToError } from '../../../utils';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import MonthSelect from '../../MonthSelect';

type ModalEditPlanProps = {
  onCloseRequest: () => void;
  editPlan: (data: { data: FinancePlan; callback: () => void }) => void;
  getClientsList: () => void;
  getProjectsList: () => void;
  getCurrenciesList: () => void;
  getDatesOfExistingPlans: (data: DatesOfExistingPlansParams) => void;
  resetDatesOfExistingPlans: () => void;
  loadingDates: boolean;
  datesOfExistingPlans: string[];
  financePlan: FinancePlan;
  clients: Client[];
  projects: FinanceProject[];
  currencies: CurrencyType[];
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
};

function ModalEditPlan({
  onCloseRequest,
  editPlan,
  error,
  isLoading,
  datesOfExistingPlans,
  loadingDates,
  isOpen,
  clients,
  projects,
  financePlan,
  currencies,
  getClientsList,
  getProjectsList,
  getCurrenciesList,
  getDatesOfExistingPlans,
  resetDatesOfExistingPlans,
}: ModalEditPlanProps) {
  const intl = useIntl();

  const { values, errors, touched, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: financePlan,
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: PLAN_SCHEMA,
    onSubmit: data => {
      return editPlan({
        data,
        callback: () => {
          onCloseRequest();
          resetDatesOfExistingPlans();
        },
      });
    },
  });

  useEffect(() => {
    getClientsList();
    getProjectsList();
    getCurrenciesList();
  }, []);

  const existingPlansDates = useMemo(
    () =>
      values.clientId === financePlan.clientId && values.financeProjectId === financePlan.financeProjectId
        ? datesOfExistingPlans.filter(date => date !== getFormattedDate(financePlan.year, financePlan.month))
        : datesOfExistingPlans,
    [datesOfExistingPlans, financePlan, values.clientId, values.financeProjectId],
  );

  useEffect(() => {
    const year = values.year;
    if (values.financeProjectId && values.clientId && year) {
      const startDate = moment(year).startOf('year').format(DATE_FORMAT.YYYY_MM_DD);
      const endDate = moment(year).endOf('year').format(DATE_FORMAT.YYYY_MM_DD);
      getDatesOfExistingPlans({
        projectId: values.financeProjectId,
        clientId: values.clientId,
        startDate,
        endDate,
      });
    } else {
      resetDatesOfExistingPlans();
    }
  }, [values.financeProjectId, values.year]);

  useEffect(() => {
    if (
      values.financeProjectId &&
      existingPlansDates.some(date => moment(date).get('month') === Number(values.month))
    ) {
      setFieldValue('month', null);
    }
  }, [existingPlansDates]);

  const clientsOptions = useMemo(
    () => clients?.filter(item => item.isActive).map(item => ({ label: item.name, value: item })),
    [clients],
  );

  const clientValue = useMemo(() => {
    if (values.client && clients) {
      const client = clients?.find(item => item.id === values.client.id);
      return { label: client?.name, value: client };
    }
  }, [projects, values]);

  const projectsOptions = useMemo(
    () =>
      projects
        ?.filter(item => values.clientId && item.clientId === values.clientId && item.isActive)
        .map(item => ({ label: item.name, value: item })),
    [projects, values.clientId],
  );

  const projectValue = useMemo(() => {
    if (values.financeProject && projects) {
      const project = projects?.find(item => item.id === values.financeProject.id);
      return { label: project?.name, value: project };
    }
    return null;
  }, [projects, values]);

  const currenciesOptions = useMemo(
    () =>
      currencies.map((currency: CurrencyType) => ({
        label: currency.name,
        value: currency,
      })),
    [currencies],
  );

  const currencyValue = useMemo(
    () =>
      values.currency &&
      currenciesOptions?.find(({ value }: { value: { id: string } }) => value.id === values.currencyId),
    [currenciesOptions, values],
  );

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleYearChange = useCallback(({ value }) => {
    setFieldValue('year', value);
  }, []);

  const handleMonthChange = useCallback(
    ({ value }) => {
      setFieldValue('month', value);
      const date = getFormattedDate(values.year, value);
      if (values.financeProject && date) {
        const curentProjectRate = getCurrentRateDate(values.financeProject?.rates, date);

        setFieldValue('currencyId', curentProjectRate ? curentProjectRate.currencyId : baseCurrency?.id);
        setFieldValue('currency', curentProjectRate ? curentProjectRate.currency : baseCurrency);

        const workHourRate = new CurrencyFormatter({
          float: Number(curentProjectRate?.rate) || 0,
          value: curentProjectRate?.rate.toString() || '0.00',
          formatted: curentProjectRate?.rate.toString() || '0.00',
        });

        setFieldValue('currencyWorkHourRate', workHourRate);

        const baseRate = (Number(workHourRate.float) * Number(values.currencyRate.float)).toFixed(2);
        setFieldValue(
          'baseRate',
          new CurrencyFormatter({
            float: Number(baseRate),
            value: baseRate,
            formatted: baseRate,
          }),
        );
      }
    },
    [values.financeProject, values.currencyRate, values.year],
  );

  const handleChangeClient = useCallback(item => {
    setFieldValue(`client`, item.value);
    setFieldValue(`clientId`, item.value?.id);
    setFieldValue(`financeProject`, null);
    setFieldValue(`financeProjectId`, null);
  }, []);

  const handleChangeProject = useCallback(
    item => {
      setFieldValue(`financeProject`, item.value);
      setFieldValue(`financeProjectId`, item.value?.id);

      if (values.month && item.value) {
        const curentProjectRate = getCurrentRateDate(item.value.rates, values.month + '-01');

        setFieldValue('currencyId', curentProjectRate ? curentProjectRate.currencyId : baseCurrency?.id);
        setFieldValue('currency', curentProjectRate ? curentProjectRate.currency : baseCurrency);

        const workHourRate = new CurrencyFormatter({
          float: Number(curentProjectRate?.rate) || 0,
          value: curentProjectRate?.rate.toString() || '0.00',
          formatted: curentProjectRate?.rate.toString() || '0.00',
        });

        setFieldValue('currencyWorkHourRate', workHourRate);

        if (curentProjectRate?.currencyId === baseCurrency?.id) {
          setFieldValue(
            'currencyRate',
            new CurrencyFormatter({
              float: 1,
              value: '1.00',
              formatted: '1.00',
            }),
          );

          setFieldValue('baseRate', workHourRate);
        } else {
          const baseRate = (Number(workHourRate.float) * Number(values.currencyRate.float)).toFixed(2);

          setFieldValue(
            'baseRate',
            new CurrencyFormatter({
              float: Number(baseRate),
              value: baseRate,
              formatted: baseRate,
            }),
          );
        }
      }
    },
    [values],
  );

  const handleCurrenciesChange = useCallback(item => {
    setFieldValue('currencyId', item.value.id);
    setFieldValue('currency', item.value);
  }, []);

  const handleCurrencyRateChange = useCallback(
    item => {
      const currencyRate = new CurrencyFormatter({
        ...item,
        float: item.float || 0,
      });
      setFieldValue('currencyRate', currencyRate);
      const baseRate = (Number(values.currencyWorkHourRate.float) * Number(item.float)).toFixed(2);
      setFieldValue(
        'baseRate',
        new CurrencyFormatter({
          float: Number(baseRate),
          value: baseRate,
          formatted: baseRate,
        }),
      );
    },
    [values.currencyWorkHourRate],
  );

  const handleBaseRateChange = useCallback(item => {
    const baseRate = new CurrencyFormatter({
      ...item,
      float: item.float || 0,
    });
    setFieldValue('baseRate', baseRate);
  }, []);

  const handlePlannedIncomeChange = useCallback(item => {
    const plannedIncome = new CurrencyFormatter({
      ...item,
      float: item.float || 0,
    });
    setFieldValue('plannedIncome', plannedIncome);
  }, []);

  const handleCurrencyWorkHourRateChange = useCallback(
    item => {
      const workHourRate = new CurrencyFormatter({
        ...item,
        float: item.float || 0,
      });
      setFieldValue('currencyWorkHourRate', workHourRate);
      const baseRate = (Number(item.float) * Number(values.currencyRate.float)).toFixed(2);
      setFieldValue(
        'baseRate',
        new CurrencyFormatter({
          float: Number(baseRate),
          value: baseRate,
          formatted: baseRate,
        }),
      );
    },
    [values.currencyRate],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editPlanTitle)}
      classNameModal="new-plan-modal"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.clientLabel)}
              options={clientsOptions}
              value={clientValue}
              handleChange={handleChangeClient}
              hasError={hasError(`clientId`)}
              //@ts-ignore
              errorMessage={errors.clientId || errors.client?.isActive}
              isClearable
            />
            <Select
              label={intl.formatMessage(messages.projectLabel)}
              options={projectsOptions}
              //@ts-ignore
              value={projectValue}
              handleChange={handleChangeProject}
              hasError={hasError(`financeProjectId`)}
              //@ts-ignore
              errorMessage={errors.financeProjectId || errors.financeProject?.isActive}
              isClearable
            />
            <MonthSelect
              selectExternalClass="form__input-block--third"
              year={values.year}
              month={values.month}
              customStartYear={moment().subtract(1, 'year')}
              addYearCount={2}
              lengthYearList={3}
              disabledMonths={existingPlansDates}
              isLoadingMonths={loadingDates}
              errorMessageMonth={errors.month}
              errorMessageYear={errors.year}
              handleYearChange={handleYearChange}
              handleMonthChange={handleMonthChange}
            />
            <div className="form__inputs-subwrapper">
              <Select
                options={currenciesOptions}
                externalClass="select__no-label currency-select"
                value={currencyValue}
                handleChange={handleCurrenciesChange}
                errorMessage={errors?.currencyId}
                hasError={hasError('currencyId')}
              />
              <CurrencyInput
                name="currencyWorkHourRate"
                label={intl.formatMessage(messages.originalHourPriceLabel)}
                value={values.currencyWorkHourRate.value}
                onChange={handleCurrencyWorkHourRateChange}
                //@ts-ignore
                errorMessage={errors?.currencyWorkHourRate?.float || errors?.currencyWorkHourRate}
                hasError={hasError('currencyWorkHourRate')}
                wrapperClass={'rate-input'}
              />
              <CurrencyInput
                name="currencyRate"
                label={intl.formatMessage(messages.rateLabel)}
                decimalsLimit={4}
                value={values.currencyRate.value}
                onChange={handleCurrencyRateChange}
                //@ts-ignore
                errorMessage={errors?.currencyRate?.float || errors?.currencyRate}
                hasError={hasError('currencyRate')}
                wrapperClass={'currency-rate-input'}
              />
              <CurrencyInput
                name="baseRate"
                label={intl.formatMessage(messages.unifiedHourPriceLabel)}
                suffix={baseCurrency?.id ? ` ${baseCurrency.name}` : undefined}
                value={values.baseRate.value}
                onChange={handleBaseRateChange}
                //@ts-ignore
                errorMessage={errors?.baseRate?.float || errors?.baseRate}
                hasError={hasError('baseRate')}
                wrapperClass={'rate-input'}
              />
            </div>
            <div className="form__inputs-subwrapper">
              <CurrencyInput
                name="plannedIncome"
                label={intl.formatMessage(messages.plannedIncomeLabel)}
                suffix={values.currency?.id ? ` ${values.currency.name}` : undefined}
                value={values.plannedIncome.value}
                onChange={handlePlannedIncomeChange}
                //@ts-ignore
                errorMessage={errors?.plannedIncome?.float || errors?.plannedIncome}
                hasError={hasError('plannedIncome')}
                externalClass="month-input"
              />
            </div>
          </div>
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            type={'button'}
            onClick={onCloseRequest}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            externalClass={'button--modal'}
            type={'submit'}
            loading={isLoading}
            disabled={isLoading || loadingDates}
          >
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditPlan;
