import { defineMessages } from 'react-intl';
import {
  deleteButton,
  addButton,
  cancelButton,
  saveButton,
  editButton,
  newButton,
  totalRow,
  required,
} from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  required,
  newButton,
  addButton,
  cancelButton,
  saveButton,
  totalRow,
  FIRST_INCOME_BLOCK: {
    id: 'finance.cash.flow.title.income.block1',
    defaultMessage: 'Income Block #1',
  },
  SECOND_INCOME_BLOCK: {
    id: 'finance.cash.flow.title.income.block2',
    defaultMessage: 'Income Block #2',
  },
  FIRST_EXPENSE_BLOCK: {
    id: 'finance.cash.flow.title.expense.block1',
    defaultMessage: 'Expense Block #1',
  },
  SECOND_EXPENSE_BLOCK: {
    id: 'finance.cash.flow.title.expense.block2',
    defaultMessage: 'Expense Block #2',
  },
  totalBlock: {
    id: 'finance.cash.flow.title.total.block',
    defaultMessage: 'Total Block',
  },
  balanceBlock: {
    id: 'finance.cash.flow.title.balance.block',
    defaultMessage: 'Balance Block',
  },
  expenseColumn: {
    id: 'finance.cash.flow.column.expense',
    defaultMessage: 'Expense Type / Expense Sub-Type',
  },
  incomeColumn: {
    id: 'finance.cash.flow.column.income',
    defaultMessage: 'Income Type / Payer',
  },
  totalTypeName: {
    id: 'finance.cash.flow.modal.title.total',
    defaultMessage: 'Total Block Name',
  },
  balanceTypeName: {
    id: 'finance.cash.flow.modal.title.balance',
    defaultMessage: 'Balance Block Name',
  },
  expenseModalTitle: {
    id: 'finance.cash.flow.modal.title.expense',
    defaultMessage: 'Expense Block',
  },
  incomeModalTitle: {
    id: 'finance.cash.flow.modal.title.income',
    defaultMessage: 'Income Block',
  },
  expenseTypeLabel: {
    id: 'finance.cash.flow.modal.label.expense',
    defaultMessage: 'Expense Type',
  },
  incomeTypeLabel: {
    id: 'finance.cash.flow.modal.label.income',
    defaultMessage: 'Income Type',
  },
  expenseTypeName: {
    id: 'finance.cash.flow.modal.label.expense.name',
    defaultMessage: 'Expense Block Name',
  },
  incomeTypeName: {
    id: 'finance.cash.flow.modal.label.income.name',
    defaultMessage: 'Income Block Name',
  },
  showEmptyRecords: {
    id: 'finance.cash.flow.modal.label.show.empty.records',
    defaultMessage: 'Show Empty Records',
  },
  balanceColumn: {
    id: 'finance.planned.expense.column.offices.balance',
    defaultMessage: 'Balance / Offices',
  },
  openingBalanceColumn: {
    id: 'finance.planned.expense.column.opening.balance',
    defaultMessage: 'Opening Balance',
  },
  closingBalanceColumn: {
    id: 'finance.planned.expense.column.closing.balance',
    defaultMessage: 'Closing Balance',
  },
  plusNetBalanceColumn: {
    id: 'finance.planned.expense.column.plus.net',
    defaultMessage: 'Plus Net Cash Flows',
  },
});
