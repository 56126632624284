import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import { FinancePlan, PLAN_SCHEMA, CurrencyFormatter } from '../../../enums/finance/finance.enum';
import CurrencyInput from '../../CurrencyInput';
import { CurrencyType } from '../../../types/finance';
import { getBaseCurrency, scrollToError } from '../../../utils';

type ModalEditPlannedIncomeProps = {
  onCloseRequest: () => void;
  editPlan: (data: { data: FinancePlan; callback: () => void }) => void;
  financePlan: FinancePlan;
  currencies: CurrencyType[];
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
};

function ModalEditPlannedIncome({
  onCloseRequest,
  editPlan,
  error,
  isLoading,
  isOpen,
  financePlan,
  currencies,
}: ModalEditPlannedIncomeProps) {
  const intl = useIntl();

  const { values, errors, touched, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: financePlan,
    validateOnChange: false,
    validate: scrollToError,
    validationSchema: PLAN_SCHEMA,
    onSubmit: data => {
      return editPlan({
        data,
        callback: () => {
          onCloseRequest();
        },
      });
    },
  });

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handlePlannedIncomeChange = useCallback(item => {
    const plannedIncome = new CurrencyFormatter({
      ...item,
      float: item.float || 0,
    });
    setFieldValue('plannedIncome', plannedIncome);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseRequest}
      title={intl.formatMessage(messages.editIncomeTitle)}
      classNameModal="new-plan-modal"
    >
      <form className="modal__form form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <div className="form__inputs-subwrapper">
              <CurrencyInput
                name="plannedIncome"
                label={intl.formatMessage(messages.plannedIncomeLabel)}
                suffix={baseCurrency?.id ? ` ${baseCurrency.name}` : undefined}
                value={values.plannedIncome.value}
                onChange={handlePlannedIncomeChange}
                //@ts-ignore
                errorMessage={errors?.plannedIncome?.float || errors?.plannedIncome}
                hasError={hasError('plannedIncome')}
                externalClass="month-input"
              />
            </div>
          </div>
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            type={'button'}
            onClick={onCloseRequest}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button externalClass={'button--modal'} type={'submit'} loading={isLoading} disabled={isLoading}>
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditPlannedIncome;
