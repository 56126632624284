import { get } from 'lodash-es';
import { getDateParams } from '../../utils/params.utils';

export class CashFlowReportParams {
  dateFrom: string;
  dateTo: string;
  officeIds: string[];
  blockType: string;

  constructor(params?: unknown) {
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false, period: 'year' }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false, period: 'year' }));
    this.officeIds = get(params, 'officeIds', []);
    this.blockType = get(params, 'blockType', 'FIRST_INCOME_BLOCK');
  }
}

export class CashFlowSettingClass {
  name: string;
  showEmptyRecords: boolean;
  cashFlowExpenseTypeBlockSettings: {
    expenseTypeId: string | undefined;
    positionNumber: number;
  };
  cashFlowIncomeTypeBlockSettings: {
    incomeTypeId: string | undefined;
    positionNumber: number;
  };
  profitLossReportIncomeBlockSettings: {
    incomeTypeId: string | undefined;
    positionNumber: number;
  };

  constructor(params?: unknown) {
    this.name = get(params, 'name');
    this.showEmptyRecords = get(params, 'showEmptyRecords');
    this.cashFlowExpenseTypeBlockSettings = get(params, 'cashFlowExpenseTypeBlockSettings', undefined);
    this.cashFlowIncomeTypeBlockSettings = get(params, 'cashFlowIncomeTypeBlockSettings', undefined);
    this.profitLossReportIncomeBlockSettings = get(params, 'profitLossReportIncomeBlockSettings', undefined);
  }
}

export class ProfitLossCostProjectClass {
  name: string;
  groupByClient: boolean;
  blockType: 'COST_OF_GOODS' | 'COST_OF_PRODUCTS';
  profitLossReportProjectBlockSettings: {
    financeProjectId: string;
    positionNumber: number;
  }[];

  constructor(params?: unknown) {
    this.name = get(params, 'name');
    this.groupByClient = get(params, 'groupByClient');
    this.blockType = get(params, 'blockType', 'COST_OF_GOODS');
    this.profitLossReportProjectBlockSettings = get(params, 'profitLossReportProjectBlockSettings', undefined);
  }
}

export class ProfitLossFinancialExpense {
  name: string;
  showEmptyRecords: boolean;
  blockType: 'OPERATING_EXPENSES' | 'OTHER_OPERATING_EXPENSES';
  profitLossReportExpenseBlockSettings: {
    profitLossReportFinancialExpenseBlockSettingId: string;
    expenseTypeId: string;
    positionNumber: number;
    profitLossReportSubExpenseBlockSettings: {
      profitLossReportExpenseBlockSettingId: string;
      subExpenseTypeId: string;
      positionNumber: number;
    }[];
  }[];

  constructor(params?: unknown) {
    this.name = get(params, 'name');
    this.showEmptyRecords = get(params, 'showEmptyRecords');
    this.blockType = get(params, 'blockType', 'OPERATING_EXPENSES');
    this.profitLossReportExpenseBlockSettings = get(params, 'profitLossReportExpenseBlockSettings', undefined);
  }
}

export class ProfitLossRevenueClient {
  name: string;
  profitLossReportClientBlockSettings: {
    profitLossReportRevenueFromClientBlockSettingId: string;
    clientId: string;
    positionNumber: number;
  }[];

  constructor(params?: unknown) {
    this.name = get(params, 'name');
    this.profitLossReportClientBlockSettings = get(params, 'profitLossReportClientBlockSettings', undefined);
  }
}
