import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatValue } from 'react-currency-input-field';

import moment from 'moment';
import classNames from 'classnames';

import { CashFlowMonthType } from '../../../enums/finance/finance.enum';
import Table from '../../Table';
import messages from '../messages';
import { CurrencyType } from '../../../types/finance';
import Button from '../../Button';
import Icon from '../../Icon';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { getTotalMonths } from './utils';
import ModalEditCashFlowNameSettings from '../Modals/ModalEditBlockNameSetting';

type TotalBlockTableProps = {
  tableData: any;
  customTitle: any;
  months: any[];
  errors: string | null;
  isLoading: boolean;
  baseCurrency: CurrencyType | undefined;
  settingsData: any;
  settingsLoading: boolean;
  settingsError: string | null;
  getExpenseSettings: (data: string) => void;
  putSettings: (data: any) => void;
  onCloseSettings: () => void;
};

const TotalBlockTable = ({
  tableData,
  months,
  customTitle,
  baseCurrency,
  errors,
  isLoading,
  putSettings,
  onCloseSettings,
}: TotalBlockTableProps) => {
  const intl = useIntl();

  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const convertedData = useMemo(() => {
    if (tableData.length) {
      const totalMonths = getTotalMonths(tableData, months);

      return [
        {
          id: 'total',
          totalItem: true,
          total: totalMonths.map((it: any) => Number(it.value)).reduce((partialSum: any, a: any) => partialSum + a, 0),
          months: totalMonths,
        },
      ];
    }

    return [];
  }, [tableData]);

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: any) => {
    putSettings(params);
  };

  const tableColumns = useMemo(() => {
    if (convertedData.length) {
      const typeColumn = [
        {
          id: 'title',
          name: '',
          modifier: () => intl.formatMessage(messages.totalRow),
          className: 'table__row-title',
        },
      ];

      const monthColumns = convertedData[0].months?.map((data: CashFlowMonthType, index: number) => ({
        id: `month-${index}`,
        name: (
          <div className="day-display">
            {moment(data.month).format(DATE_FORMAT.MMM)}
            {(index === 0 || moment(data.month).startOf('year').isSame(data.month)) && (
              <div className={classNames('year-display')}>{moment(data.month).format(DATE_FORMAT.YYYY)}</div>
            )}
          </div>
        ),
        modifier: (row: any) => {
          return (
            <span>
              {row.months[index]?.value
                ? formatValue({
                    value: row.months[index]?.value?.toString(),
                    suffix: ` ${baseCurrency?.name}`,
                  })
                : '-'}
            </span>
          );
        },
      }));

      const totalColumn = [
        {
          id: `total`,
          name: intl.formatMessage(messages.totalRow),
          className: 'table__row-title',
          modifier: (row: any) => (
            <span>
              {formatValue({
                value: row.total?.toString(),
              })}
              &nbsp;
              {baseCurrency?.name}
            </span>
          ),
        },
      ];

      return [...typeColumn, ...monthColumns, ...totalColumn];
    }
    return [];
  }, [convertedData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: ' header_row',
      colspan: convertedData[0]?.months?.length,
      sortName: '',
    },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
  ];

  return (
    <>
      <div className="cash-flow-report__table-head">
        <h2 className="cash-flow__block-title">
          {customTitle?.length ? customTitle : <FormattedMessage {...messages.totalBlock} />}
        </h2>
        <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
          <Icon iconName="pencil" externalClass="button__icon" />
        </Button>
      </div>
      <div className="page__scrollable-table-wrapper cash-flow-report">
        <div className="page__scrollable-table-wrapper__inner cash-flow-report-wrapper">
          <Table
            externalClass={'table--hierarchical'}
            tableColumns={convertedData.length ? tableColumns : []}
            tableData={convertedData}
            loading={isLoading}
            error={errors}
            tableActions={[]}
            hasRowHover={false}
            tableHeaderItems={convertedData.length ? tableHeaderItems : []}
          />
        </div>
      </div>
      {openSettingsModal && (
        <ModalEditCashFlowNameSettings
          isOpen={openSettingsModal}
          onCloseRequest={closeSetting}
          editCashFlowSetting={editSetting}
          name={customTitle}
          type="TOTAL"
        />
      )}
    </>
  );
};

export default TotalBlockTable;
