import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import moment from 'moment';

import * as filtersActions from '../../actions/filters.actions';
import * as financeActions from '../../actions/finance.actions';
import HierarchicalTable from '../../components/HierarchicalTable';
import InlineDatePicker from '../../components/InlineDatePicker';
import OfficesBalanceFilters from '../../components/OfficesBalance/Filters/OfficesBalanceFilters';
import ModalEditOfficeBalance from '../../components/OfficesBalance/Modals/ModalEditOfficeBalance';
import { DATE_FORMAT } from '../../constants/date.constants';
import { FilterParamsName } from '../../constants/filters.constants';
import { officesBalanceUnsavedParams } from '../../constants/finance.constants';
import { VIEW_OFFICE_BALANCE } from '../../constants/policies.constants';
import {
  OfficeBalanceClass,
  OfficeBalanceMonthType,
  OfficeBalanceType,
  OfficesBalanceItem,
} from '../../enums/finance/finance.enum';
import { OfficesBalanceParams } from '../../enums/params/finance.params';
import PoliciesContext from '../../PoliciesContext';
import { getBaseCurrency } from '../../utils';
import { checkParamsMatch } from '../../utils/filters.utils';
import { resetParamsChange, useParamsChange, useTableData } from '../../utils/hooks.utils';
import messages from './messages';
import { useDataForTable } from './useDataForTable';
import { OfficeInfo } from '../../enums/libraries.enum';

function OfficesBalance({
  tableData,
  officesFilter,
  isLoading,
  errors,
  params,
  getOfficesFilter,
  resetState,
  resetErrors,
  currencies,
  getCurrenciesFilter,
  editOfficeBalance,
  setOfficesBalanceParams,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_OFFICE_BALANCE), [policies]);

  const convertedData = useMemo(() => {
    const offices = (params.officeIds.length
      ? officesFilter.offices.filter((office: OfficeInfo) => params.officeIds.includes(office.id))
      : officesFilter.offices
    )?.filter((el: OfficeInfo) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el.id));

    if (tableData.length && offices.length) {
      const newData: OfficesBalanceItem[] = [];
      const totalMonths: any[] = [];
      const table = tableData;

      table.forEach(function (this: any, a: any) {
        if (!this[a.balanceDate]) {
          this[a.balanceDate] = {
            openBalance: 0,
            closeBalance: 0,
          };
          totalMonths.push(this[a.balanceDate]);
        }
        this[a.balanceDate].openBalance += Number(a.customOpenBalance || 0) || Number(a.automaticOpenBalance);
        this[a.balanceDate].closeBalance += Number(a.customCloseBalance || 0) || Number(a.automaticCloseBalance);
      }, Object.create(null));

      newData.push(
        new OfficesBalanceItem({
          type: 'OPEN',
          months: totalMonths.map(total => total.openBalance),
          offices: offices.map((item: OfficeInfo) => ({
            officeId: item.id,
            office: item,
            type: 'OPEN',
            months: tableData
              .filter((row: any) => row.officeId === item.id)
              .map((row: any) => ({
                balanceDate: row.balanceDate,
                automaticBalance: Number(row.automaticOpenBalance),
                customBalance: Number(row.customOpenBalance || 0),
              })),
          })),
        }),
      );

      newData.push(
        new OfficesBalanceItem({
          type: 'CLOSE',
          months: totalMonths.map(total => total.closeBalance),
          offices: offices.map((item: OfficeInfo) => ({
            officeId: item.id,
            office: item,
            type: 'CLOSE',
            months: tableData
              .filter((row: any) => row.officeId === item.id)
              .map((row: any) => {
                return {
                  balanceDate: row.balanceDate,
                  automaticBalance: Number(row.automaticCloseBalance),
                  customBalance: Number(row.customCloseBalance || 0),
                };
              }),
          })),
        }),
      );

      return newData;
    }

    return [];
  }, [tableData]);

  useEffect(() => {
    setOfficesBalanceParams({
      officeIds: officesFilter.value?.filter(
        (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
      ),
    });
  }, [viewPolicy]);

  useEffect(() => {
    getOfficesFilter();
    getCurrenciesFilter();

    return () => {
      resetState();
    };
  }, []);

  const [modalEditOfficeBalanceIsOpen, setModalEditOfficeBalanceIsOpen] = useState(false);
  const [officeMonthClicked, setOfficeMonthClicked] = useState<{
    month: OfficeBalanceMonthType;
    office: OfficeBalanceType;
  } | null>(null);

  const openEditOfficeBalanceModal = useCallback(() => {
    setModalEditOfficeBalanceIsOpen(true);
  }, []);

  const closeEditOfficeBalanceModal = useCallback(() => {
    setModalEditOfficeBalanceIsOpen(false);
    setOfficeMonthClicked(null);
    resetErrors();
  }, []);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const { tableColumns, tableHeaderItems } = useDataForTable(
    convertedData,
    baseCurrency,
    setOfficeMonthClicked,
    openEditOfficeBalanceModal,
  );

  const handleMultiParamsChange = useParamsChange(setOfficesBalanceParams, dispatch);

  const handleDataChange = useCallback((start: string, end: string) => {
    setOfficesBalanceParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  }, []);

  const filters = useMemo(
    () => ({
      offices: officesFilter.offices,
    }),
    [officesFilter.offices],
  );

  const handleClear = useCallback(() => {
    setOfficesBalanceParams(new OfficesBalanceParams());
    resetParamsChange([FilterParamsName.OFFICE_IDS], dispatch);
  }, []);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new OfficesBalanceParams(), officesBalanceUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                  todayBtn={false}
                  weekBtn={false}
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <OfficesBalanceFilters
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleClear={handleClear}
                  policyOfficiesIds={viewPolicy?.officeIds}
                  isOfficeSpecific={!!viewPolicy?.isOfficeSpecific}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <div className="page__scrollable-table-wrapper">
            <div className="page__scrollable-table-wrapper__inner offices-balance-wrapper">
              <HierarchicalTable
                tableData={useTableData(convertedData, ['offices'])}
                tableColumns={tableColumns}
                loading={isLoading.getOfficesBalance}
                error={errors}
                tableHeaderItems={tableHeaderItems}
              />
            </div>
          </div>
        </div>
      </div>
      {modalEditOfficeBalanceIsOpen && (
        <ModalEditOfficeBalance
          isOpen
          onCloseRequest={closeEditOfficeBalanceModal}
          editOfficeBalance={editOfficeBalance}
          error={errors}
          isLoading={isLoading.editOfficeBalance}
          officeMonthClicked={officeMonthClicked}
          baseCurrency={baseCurrency}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ filters, auth, finance }: RootState) => ({
  tableData: finance.officesBalanceListData,
  officesFilter: filters.officesFilter,
  isLoading: finance.loading,
  errors: finance.errors.officesBalanceError,
  params: finance.officesBalanceParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
  currencies: filters.currenciesFilter.currencies,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  setOfficesBalanceParams: (data: Partial<OfficesBalanceParams>) =>
    dispatch(financeActions.setOfficeBalanceParams(data)),
  editOfficeBalance: (data: { data: OfficeBalanceClass; callback: () => void }) =>
    dispatch(financeActions.editOfficeBalance(data)),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(OfficesBalance);
