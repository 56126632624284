import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { getMenuNavigation } from '../../constants/routes.constants';
import Icon from '../Icon';
import BrandingContext from '../../BrandingContext';
import PoliciesContext from '../../PoliciesContext';
import Search from '../Search';
import { distanceBetweenWindowTopAndNavMenu, menuItemHeight } from './utilts';

type MenuProps = {
  open: boolean;
  cancelTimeout: boolean;
  setCancelTimeout: (value: boolean) => void;
  openOnHover: boolean;
  setOpenOnHover: (value: boolean) => void;
};

function Menu({ open, cancelTimeout, setCancelTimeout, openOnHover, setOpenOnHover }: MenuProps) {
  const branding = useContext(BrandingContext);
  const policies = useContext(PoliciesContext);
  const menu = useRef<any>(null);
  const [navigation, setNavigation] = useState(
    getMenuNavigation(policies).map(item => ({
      ...item,
      groups: item.groups.map(group => ({ ...group, open: false })),
    })),
  );
  const [filterdMenu, setFilterdMenu] = useState(navigation);

  const [searchValue, setSearchValue] = useState('');

  const [clickedIndex, setClickedIndex] = useState<null | number>(null);

  useEffect(() => {
    setFilterdMenu(
      getMenuNavigation(policies).map(item => ({
        ...item,
        groups: item.groups.map(group => ({ ...group, open: false })),
      })),
    );
    setNavigation(
      getMenuNavigation(policies).map(item => ({
        ...item,
        groups: item.groups.map(group => ({ ...group, open: false })),
      })),
    );
  }, [policies]);

  const handleItemClick = useCallback(
    (i, item) => {
      !open && setOpenOnHover(true);

      setFilterdMenu(
        filterdMenu.map((men, index) =>
          i === index
            ? {
                ...men,
                groups: men.groups.map(group =>
                  group.groupName === item.groupName
                    ? { ...group, open: open || openOnHover ? !item.open : true }
                    : group,
                ),
              }
            : men,
        ),
      );

      if (!open && !openOnHover) {
        setClickedIndex(i);
      } else {
        setClickedIndex(null);
      }
    },

    [filterdMenu, open, openOnHover],
  );

  useEffect(() => {
    const nav = menu.current;
    if (clickedIndex && nav) {
      const rect = document.getElementsByClassName('menu__item--parent')[clickedIndex].getBoundingClientRect();
      //Scroll to clicked menu item so that the cursor is above it
      nav.scrollTo(0, rect.top - distanceBetweenWindowTopAndNavMenu - clickedIndex * menuItemHeight);
    }
  }, [openOnHover]);

  const chechActiveGroupe = useCallback(
    (sections: { name: string; url: string }[]) => !!sections.find(item => item.url === location.pathname),
    [location.pathname],
  );

  const getFilterMenu = useCallback(
    (
      navigationList: {
        sectionName: string;
        groups: {
          groupName: string | undefined;
          name: string | undefined;
          open: boolean;
          sections: { name: string; url: string }[];
        }[];
      }[],
      searchValue: string,
    ) =>
      searchValue
        ? navigationList
            .map(section => {
              if (section.sectionName.toLowerCase().includes(searchValue.toLowerCase().trim())) {
                return section;
              } else {
                const filteredGroups = section.groups
                  .map(group => {
                    if ((group?.groupName || group?.name)?.toLowerCase().includes(searchValue.toLowerCase())) {
                      return group;
                    } else if (group.sections) {
                      const filteredSections = group.sections
                        .map(section => {
                          if (section?.name.toLowerCase().includes(searchValue.toLowerCase())) {
                            return section;
                          }
                        })
                        .filter((item: any) => item);

                      if (filteredSections.length) {
                        return { ...group, sections: filteredSections, open: true };
                      }
                    }
                  })
                  .filter((item: any) => item);

                if (filteredGroups.length) {
                  return { ...section, groups: filteredGroups };
                }
              }
            })
            .filter((item: any) => item)
        : navigationList,
    [],
  );

  useEffect(() => {
    if (!open && !openOnHover) {
      setSearchValue('');
    }
  }, [open, openOnHover, navigation]);

  useEffect(() => {
    //@ts-ignore
    setFilterdMenu(getFilterMenu(navigation, searchValue));
  }, [searchValue, navigation]);

  const handleSearchClick = () => {
    !open && setOpenOnHover(true);
    setClickedIndex(null);
    const nav = menu.current;
    nav && nav.scroll(0, 0);
  };

  const renderLink = (group: any, i: number) => {
    return (
      <li
        style={{
          // @ts-ignore
          '--global-color': branding?.globalAccents,
          '--global-color-opacity': branding?.globalAccents + '1a',
        }}
        key={i.toString()}
        //@ts-ignore
        className={classNames(
          'menu__item menu__item--parent',
          { open: group.open },
          { active: group.sections ? chechActiveGroupe(group.sections) : false },
        )}
      >
        {group.groupName ? (
          <div
            className="menu__item-link"
            onClick={() => handleItemClick(i, group)}
            onMouseOver={() => setClickedIndex(i)}
          >
            <Icon iconName={group.iconName} externalClass="menu__item-icon" />
            <span className="menu__item-link-name">{group.groupName}</span>
          </div>
        ) : group.url ? (
          <NavLink
            to={group.url}
            className="menu__item-link"
            activeClassName="active"
            activeStyle={{ color: branding?.globalAccents }}
          >
            <Icon iconName={group.iconName} externalClass="menu__item-icon" />
            <span className="menu__item-link-name">{group.name}</span>
          </NavLink>
        ) : null}
        {group.sections ? (
          <>
            <ul className="menu__item-child">
              {/* @ts-ignore */}
              {group.sections.map(({ name, url }, i) => (
                // @ts-ignore
                <li key={i.toString()} className="menu__item">
                  <NavLink
                    to={url}
                    className="menu__item-link"
                    activeClassName="active"
                    activeStyle={{ color: branding?.globalAccents }}
                  >
                    {name}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div onClick={() => handleItemClick(i, open)}>
              <Icon iconName="arrow-open" />
            </div>
          </>
        ) : null}
      </li>
    );
  };

  return (
    <nav
      ref={menu}
      className={classNames('menu', { open: (open && !openOnHover) || (openOnHover && !cancelTimeout) })}
      style={{
        // @ts-ignore
        '--menu-box-shadow': branding?.headerShadow ? '0 10px 20px 0 rgb(51 51 51 / 5%)' : 'none',
        '--menu-right-border': branding?.headerShadow ? 'none' : '1px #e6e6e6 solid',
      }}
      onMouseOver={() => {
        setCancelTimeout(false);
        setTimeout(() => {
          if (!open && !openOnHover) {
            setOpenOnHover(true);
          }
        }, 1000);
      }}
      onMouseLeave={() => {
        setCancelTimeout(true);
        setOpenOnHover(false);
      }}
    >
      <div onClick={handleSearchClick} onMouseOver={() => setClickedIndex(0)}>
        <Search
          //@ts-ignore
          onChange={e => setSearchValue(e.target.value)}
          externalWrapperClass="menu-search"
          value={searchValue}
        />
      </div>
      {filterdMenu.map(({ sectionName, groups }, i) => (
        <>
          <div className="menu__item--group">
            {(open && !openOnHover) || (openOnHover && !cancelTimeout) ? sectionName.toUpperCase() : ''}
          </div>
          <ul className="menu__list">{groups.map(group => renderLink(group, i))}</ul>
        </>
      ))}
    </nav>
  );
}

export default Menu;
