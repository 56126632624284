import React, { useCallback, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import classNames from 'classnames';
import moment from 'moment';

import { formatValue } from 'react-currency-input-field';
import Dropdown from '../../components/Dropdown';
import Icon from '../../components/Icon';
import { DATE_FORMAT } from '../../constants/date.constants';
import { UPDATE_OFFICE_BALANCE } from '../../constants/policies.constants';
import { DepthLevels } from '../../constants/tables.constants';
import { OfficeBalanceMonthType, OfficeBalanceType, OfficesBalanceItem } from '../../enums/finance/finance.enum';
import PoliciesContext from '../../PoliciesContext';
import { CurrencyType } from '../../types/finance';
import { checkPolicies } from '../../utils/policies.utils';
import { getTableCell } from '../../utils/table.utils';
import messages from './messages';

export const useDataForTable = (
  tableData: any,
  baseCurrency: CurrencyType | undefined,
  setOfficeMonthClicked: (data: { month: OfficeBalanceMonthType; office: OfficeBalanceType }) => void,
  openEditOfficeBalanceModal: () => void,
) => {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);
  const updatePolicy = useMemo(() => policies.find(policy => policy.policy.name === UPDATE_OFFICE_BALANCE), [policies]);

  const getTableActions: any = useCallback(
    (month: OfficeBalanceMonthType, office: OfficeBalanceType) =>
      checkPolicies([UPDATE_OFFICE_BALANCE], policies) &&
      (!updatePolicy?.isOfficeSpecific || updatePolicy?.officeIds.some(id => id === office.officeId))
        ? [
            {
              label: (
                <>
                  <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.editButton} />
                </>
              ),
              itemClassName: 'dropdown__list-item__button',
              handler: () => {
                setOfficeMonthClicked({ month, office });
                openEditOfficeBalanceModal();
              },
            },
          ]
        : [],
    [policies, updatePolicy],
  );

  const tableColumns = useMemo(() => {
    if (tableData[0]?.offices.length) {
      const typesColumns = [
        {
          id: 'name',
          Header: intl.formatMessage(messages.balanceColumn),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: OfficesBalanceItem) => (
                  <FormattedMessage
                    {...messages[row.type === 'OPEN' ? 'openingBalanceColumn' : 'closingBalanceColumn']}
                  />
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: OfficeBalanceType) => row.office.name,
              },
            ]),
        },
      ];

      const monthColumns = tableData[0].offices[0].months?.map((data: OfficeBalanceMonthType, index: number) => ({
        id: `month-${index}`,
        Header: (
          <div className="day-display">
            {moment(data.balanceDate).format(DATE_FORMAT.MMMM)}
            {(index === 0 || moment(data.balanceDate).startOf('year').isSame(data.balanceDate)) && (
              <div className={classNames('year-display')}>{moment(data.balanceDate).format(DATE_FORMAT.YYYY)}</div>
            )}
          </div>
        ),
        Cell: ({ row }: any) => {
          return getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: OfficesBalanceItem) => (
                <span>
                  {row?.months[index] ? (
                    <>
                      {formatValue({
                        value: (row?.months[index]).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: OfficeBalanceType) => (
                <Dropdown
                  dropdownClass="dropdown--no-bg"
                  dropdownToggle={
                    <span className={!!row?.months[index]?.customBalance ? 'accent-text' : ''}>
                      {row?.months[index]?.customBalance || row?.months[index]?.automaticBalance ? (
                        <>
                          {formatValue({
                            value: (row?.months[index].customBalance || row?.months[index].automaticBalance).toString(),
                          })}
                          &nbsp;
                          {baseCurrency?.name}
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                  }
                  dropdownList={getTableActions(row.months[index], row)}
                  stopPropagation
                />
              ),
            },
          ]);
        },
      }));

      return [...typesColumns, ...monthColumns];
    }
    return [];
  }, [tableData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: ' header_row',
      colspan: 12,
      sortName: '',
    },
  ];

  return {
    tableColumns,
    tableHeaderItems,
  };
};
