import { version } from '../../package.json';
import isEmpty from 'lodash-es/isEmpty';
import { MutableRefObject } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '../constants/date.constants';
import { intl } from './intl';
import messages from './messages';
import { PhotoCropSetting } from '../enums/users.enum';
import { CurrencyType } from '../types/finance';

export const clientVersion = version;

export const getInitials = (words: string) =>
  words
    .split(' ')
    .slice(0, 2)
    .map(part => part.charAt(0))
    .join('');

export const isContainerEmpty = (str: string | null | undefined) => {
  const elements = Array.from(new DOMParser().parseFromString(str || '', 'text/html').body.children);
  const nonEmptyTags = elements.filter(el => !isEmpty((el.textContent || '').trim()));
  return isEmpty(nonEmptyTags);
};

export function getWeekOfMonth(date: Date) {
  let weekNumber = 0; // returning variable.
  const timestamp = date.getTime(); // get UTC timestamp of date.
  const month = date.getMonth(); // get current month.
  let m = month; // save temp value of month.
  let weekIndex;
  while (m == month) {
    // check if m equals our date's month.
    weekNumber++; // increment our week count.
    // update m to reflect previous week (previous to last value of m).
    m = new Date(timestamp - weekNumber * 604800000).getMonth();
  }
  switch (weekNumber) {
    case 1: {
      weekIndex = 'first';
      break;
    }
    case 2: {
      weekIndex = 'second';
      break;
    }
    case 3: {
      weekIndex = 'thrid';
      break;
    }
    case 4: {
      weekIndex = 'fourth';
      break;
    }
    default: {
      weekIndex = 'fifth(last)';
    }
  }

  return weekIndex;
}
type MergeRefsType<T> = (React.Ref<T> | ((node: T | null) => void))[];

const isMutableRefObject = <T>(thing: any): thing is MutableRefObject<T> =>
  (thing as MutableRefObject<T>) !== undefined;
export const mergeRefs = <T>(...refs: MergeRefsType<T>) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 1) return filteredRefs[0];

  return (inst: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (isMutableRefObject<T>(ref)) {
        ref.current = inst;
      }
    }
  };
};

export const getDateRange = (startDate: string, endDate: string, customDateFormat?: string) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const startFormat = start.format(customDateFormat ? customDateFormat : DATE_FORMAT.MMM_DD_YYYY);
  const endFormat = end.format(customDateFormat ? customDateFormat : DATE_FORMAT.MMM_DD_YYYY);
  return start.isSame(end, 'day') ? startFormat : `${startFormat} - ${endFormat}`;
};

export const setFilterValuesInSessionStorage = (name: string, value: string[]) => {
  sessionStorage.setItem(name, JSON.stringify(value));
};

export const getFilterValuesInSessionStorage = (name: string) => {
  const value = sessionStorage.getItem(name);
  return value ? JSON.parse(value) : [];
};

interface IMapItems {
  prevItems: any[];
  getKey: (item: any) => string;
}

const mapItems = ({ prevItems, getKey }: IMapItems) => {
  const map: any = {};
  for (const item of prevItems) {
    const key = getKey(item);
    map[key] = item;
  }
  return map;
};

interface IDetectedChanges {
  nextItems: any[];
  prevItems: any[];
  getKey?: (item: any) => string;
  compareItems?: (a: any, b: any) => boolean;
}

export const detectChanges = ({
  prevItems,
  nextItems,
  getKey = item => item.id,
  compareItems = (prev, next) => prev.id === next.id,
}: IDetectedChanges) => {
  const mappedItems = mapItems({ prevItems, getKey });
  const addedItems = [];
  const updatedItems = [];
  const removedItems = [];
  const unchangedItems = [];
  for (const nextItem of nextItems) {
    const itemKey = getKey(nextItem);
    if (itemKey in mappedItems) {
      const prevItem = mappedItems[itemKey];
      if (delete mappedItems[itemKey] && compareItems(prevItem, nextItem)) {
        unchangedItems.push(nextItem);
      } else {
        updatedItems.push(nextItem);
      }
    } else {
      addedItems.push(nextItem);
    }
  }
  for (const itemKey in mappedItems) {
    if (itemKey in mappedItems) {
      removedItems.push(mappedItems[itemKey]);
    }
  }
  return { addedItems, updatedItems, removedItems, unchangedItems };
};

export const getDatesInRange = (startDate: string, endDate: string) => {
  let start = moment(startDate);
  const end = moment(endDate);
  const dates = [];

  while (start.isSameOrBefore(end, 'd')) {
    dates.push(moment(start).format(DATE_FORMAT.YYYY_MM_DD));
    start = start.add(1, 'd');
  }

  return dates;
};

export const getMonthInRange = (startDate: string, endDate: string) => {
  let start = moment(startDate);
  const end = moment(moment(endDate).startOf('month').format());
  const dates = <any>[];

  while (start.isSameOrBefore(end, 'd')) {
    dates.push(moment(start).format(DATE_FORMAT.YYYY_MM_DD));
    start = start.add(1, 'month');
  }

  return dates;
};

export const getLang = () => navigator.language || (navigator.languages || ['en'])[0];

export const isBrowserLocale24h = () => !new Intl.DateTimeFormat(getLang(), { hour: 'numeric' }).format(0).match(/AM/);

export const getLocalizedTime = (date: string) => {
  const timeMoment = moment(date);
  return isBrowserLocale24h() ? timeMoment.format(DATE_FORMAT.HH_mm) : timeMoment.format(DATE_FORMAT.hh_mm_A);
};

export const scrollToError = () => {
  const cb = () => {
    const el = document.querySelector('.form__error');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setTimeout(cb, 100);
};

export const scrollToLastError = () => {
  const cb = () => {
    const elements = document.querySelectorAll('.form__error');
    if (!isEmpty(elements)) {
      elements[elements.length - 1].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setTimeout(cb, 100);
};

export const getLocalDate = (date: string, time: string) => {
  return moment.utc(`${date} ${time}`).local().format(DATE_FORMAT.YYYY_MM_DD_HH_mm_ss).split(' ');
};

const hintDefaultWidth = 200;
const hintDefaultSpacing = 5;

export const handleHintPosition = (event: React.MouseEvent) => {
  const hintWidthWithSpacing = hintDefaultWidth + hintDefaultSpacing;
  const cell = event.currentTarget as HTMLElement;
  const rect = cell.getBoundingClientRect();
  const isOpenHintOnRightSide = rect.right + hintWidthWithSpacing >= document.documentElement.clientWidth;

  const top = document.documentElement.scrollTop + rect.top;
  const bottom = document.documentElement.clientHeight - top - rect.height;
  const contentPlacmentIsBottom = top < document.documentElement.clientHeight / 2;

  const left = isOpenHintOnRightSide ? 0 : rect.right + hintDefaultSpacing;
  const right = isOpenHintOnRightSide ? document.documentElement.clientWidth - rect.left + hintDefaultSpacing : 0;

  return {
    ...(left && { left: left + 'px' }),
    ...(right && { right: right + 'px' }),
    ...(contentPlacmentIsBottom && { top: top + 'px' }),
    ...(!contentPlacmentIsBottom && { bottom: bottom + 'px' }),
  };
};

export const getExperience = (date: string) => {
  const dateStart = date ? moment(date) : moment();
  const diffY = moment().diff(dateStart, 'y');
  const diffM = moment().diff(dateStart.add(diffY, 'year'), 'M');

  return intl.formatMessage(messages.experienceCountLabel, {
    hasYear: Boolean(diffY) ? 'yes' : 'no',
    monthCount: diffM,
    yearCount: diffY,
  });
};

export const getPhotoData = async (data: { file: File | undefined; cropSetting: PhotoCropSetting }, url: string) => {
  const imageData = new FormData();

  imageData.append('cropSetting', new Blob([JSON.stringify(data.cropSetting)], { type: 'application/json' }));

  if (data.file) {
    imageData.append('file', data.file);
  } else {
    const imageExt = new URL(url).pathname.split('.')[1];
    const image = await fetch(url);
    const blob = await image.blob();
    imageData.append('file', new File([blob], `image.${imageExt}`));
  }

  return imageData;
};

export const urlify = (text: string) => {
  const kLINK_DETECTION_REGEX = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;

  return text.replace(kLINK_DETECTION_REGEX, function (url: any) {
    return '<a target="_blank" class="custom-menu-link" href="' + url + '">' + url + '</a>';
  });
};

export const getBaseCurrency = (currencies: CurrencyType[]) => {
  return currencies.find(currency => currency.isBaseCurrency);
};

export const getMonthOption = (item: string, index: number, isDisabled = false) => {
  return {
    label: item,
    value: index.toString(),
    isDisabled,
  };
};

export const getFormattedDate = (year: string | null, month: string | null) => {
  const monthIndex = (Number(month) + 1).toString();
  const monthValue = monthIndex.length === 1 ? `0${monthIndex}` : monthIndex;
  return month && year ? `${year}-${monthValue}-01` : '';
};

export const getYears = (addYearCount = 1, length = 2, customStart = moment()) => {
  const year = moment(customStart).add(addYearCount, 'year').toDate().getFullYear();
  return Array.from({ length }, (v, i) => (year - i).toString());
};
