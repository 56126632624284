import { defineMessages } from 'react-intl';
import { deleteButton, editButton, nameColumn, newButton, personalLabel, typeLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  nameColumn,
  editButton,
  deleteButton,
  newButton,
  typeLabel,
  personalLabel,
  pageTitle: {
    id: 'finance.expense.types.title.expense.types',
    defaultMessage: 'Expense Types',
  },
  companyLabel: {
    id: 'finance.expense.types.label.company',
    defaultMessage: 'Company',
  },
  expenseTitle: {
    id: 'finance.expense.types.label.expense.group.expense',
    defaultMessage: 'Expense Group / Expense',
  },
});
