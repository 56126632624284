import React from 'react';
import { useIntl } from 'react-intl';

import Select from '../../Select';
import messages from '../messages';
import Icon from '../../Icon';

type ModalDraggableSettingsProps = {
  item: any;
  dragHandleProps: any;
  data: any;
  type: 'EXPENSE' | 'INCOME';
  errors: any;
  handleTypeChange: (data: any, index: number) => void;
  types: any[];
  hasError: any;
  removeType: (index: number) => any;
};

export const Item = ({
  item,
  dragHandleProps,
  data,
  type,
  errors,
  handleTypeChange,
  types,
  hasError,
  removeType,
}: ModalDraggableSettingsProps) => {
  const intl = useIntl();

  const index = data.findIndex((it: any) => it.value === item.value);

  const { onMouseDown, onTouchStart } = dragHandleProps;

  return (
    <div className="form__group-wrapper form__group-wrapper--with-gap cash-flow-modal-draggable" key={index}>
      {data.length > 1 ? (
        <button
          className="cash-flow-modal-draggable__button"
          type={'button'}
          onTouchStart={e => {
            e.preventDefault();
            onTouchStart(e);
          }}
          onMouseDown={e => {
            onMouseDown(e);
          }}
        >
          <Icon iconName={'drag-indicator'} externalClass={'cash-flow-drag'} />
        </button>
      ) : null}
      <Select
        isSearchable
        externalClass={`cash-flow-select ${data.length > 1 ? 'cash-flow-select__cropped' : ''}`}
        label={intl.formatMessage(messages[type === 'EXPENSE' ? 'expenseTypeLabel' : 'incomeTypeLabel'])}
        options={types}
        value={item}
        errorMessage={errors?.types?.[index]?.name}
        handleChange={data => handleTypeChange(data, index)}
        hasError={hasError(`types[${index}].name`)}
      />
      <button className="form__btn-clean-inputs" type={'button'} onClick={removeType(index)}>
        <Icon iconName={'trash'} externalClass={'form__btn-clean'} />
      </button>
    </div>
  );
};
