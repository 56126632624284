import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFormik } from 'formik';
import get from 'lodash-es/get';
import * as yup from 'yup';

import Button from '../../Button';
import Modal from '../../Modal';
import messages from '../messages';
import Input from '../../Input';
import { EProfitLossTitles } from '../../../pages/ProfitLossReport';

type ModalEditProfitLossSettingsProps = {
  onCloseRequest: () => void;
  editSetting: (data: { params: string; callback: () => void }) => void;
  isOpen: boolean;
  title: EProfitLossTitles;
  name: any;
};

function ModalEditProfitLossNameSettings({
  onCloseRequest,
  editSetting,
  isOpen,
  title,
  name,
}: ModalEditProfitLossSettingsProps) {
  const intl = useIntl();

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: '',
    },
    validateOnChange: false,
    validationSchema: yup.object().shape({
      name: yup.string().trim().required(intl.formatMessage(messages.required)).max(150, 'Maximum 150 characters'),
    }),
    onSubmit: values => {
      return editSetting({ params: values.name, callback: onCloseRequest });
    },
  });

  useEffect(() => {
    if (name) {
      setFieldValue('name', name);
    }
  }, [name]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages[title])}
        size="small"
        classNameModal={'offices-balance-modal center'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages[title])}
                defaultValue={values?.name || ''}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
          </div>
          <div className="form__buttons display-center">
            <Button
              color="gray"
              externalClass="button--modal button--cancel"
              onClick={onCloseRequest}
              type="button"
              block
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass="button--modal" type="submit" disabled={!values.name.length} block>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalEditProfitLossNameSettings;
