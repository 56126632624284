import { isEmpty, omit } from 'lodash-es';
import { AnyAction } from 'redux';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from '../api/finance.api';
import * as ActionTypes from '../constants/finance.constants';
import { VIEW_INCOMES_EXPENSES_REPORT, VIEW_SALARY, VIEW_TRANSACTION } from '../constants/policies.constants';
import {
  EmployeeHours,
  FinancePlan,
  ImportTransaction,
  OfficeBalanceClass,
  PlannedExpenseClass,
  Salary,
  Transaction,
} from '../enums/finance/finance.enum';
import * as paramsTypes from '../enums/params/finance.params';
import * as cashflowTypes from '../enums/params/cashflow.params';
import { UserPolicy } from '../enums/policies.enum';
import { EmployeeHoursPlanLoadType, SalaryType } from '../types/finance';
import { getFormattedDate } from '../utils';
import { getFormattedImportTransaction, getFormattedTransaction } from '../utils/finance.utils';
import { getQueryParams } from '../utils/params.utils';
import { getUserPolicies } from '../utils/policies.utils';

function* getIncomeTypesList() {
  const params: paramsTypes.IncomeTypesParams = yield select((state: RootState) => state.finance.incomeTypesParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getIncomeTypesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_INCOME_TYPES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_INCOME_TYPES_LIST_FAILURE, payload: error });
  }
}

function* getIncomeTypesFullList({ payload }: AnyAction) {
  const params: paramsTypes.IncomeTypesParams = {
    ...(yield select((state: RootState) => state.finance.incomeTypesParams)),
    size: 1000,
  };
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getIncomeTypesFullList, data);

    if (success) {
      payload.callback(results);
    }
  } catch (error) {}
}

function* createIncomeType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createIncomeType, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.POST_INCOME_TYPE_SUCCESS,
      });
      payload.callback();
      yield call(getIncomeTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_INCOME_TYPE_FAILURE, payload: error });
  }
}

function* editIncomeType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editIncomeType, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_INCOME_TYPE_SUCCESS,
      });
      payload.callback();
      yield call(getIncomeTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_INCOME_TYPE_FAILURE, payload: error });
  }
}

function* deleteIncomeType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteIncomeType, payload.id);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_INCOME_TYPE_SUCCESS,
      });
      payload.callback();
      yield call(getIncomeTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_INCOME_TYPE_FAILURE, payload: error });
  }
}

function* getCurrenciesList() {
  const params: paramsTypes.CurrenciesParams = yield select((state: RootState) => state.finance.currenciesParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getCurrenciesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_CURRENCIES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CURRENCIES_LIST_FAILURE, payload: error });
  }
}

function* createCurrency({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createCurrency, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.POST_CURRENCY_SUCCESS,
      });
      payload.callback();
      yield call(getCurrenciesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_CURRENCY_FAILURE, payload: error });
  }
}

function* editCurrency({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editCurrency, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_CURRENCY_SUCCESS,
      });
      payload.callback();
      yield call(getCurrenciesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CURRENCY_FAILURE, payload: error });
  }
}

function* deleteCurrency({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteCurrency, payload.id);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_CURRENCY_SUCCESS,
      });
      payload.callback();
      yield call(getCurrenciesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CURRENCY_FAILURE, payload: error });
  }
}

function* getClientsList() {
  const params: paramsTypes.ClientsParams = yield select((state: RootState) => state.finance.clientsParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getClientsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_CLIENTS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CLIENTS_LIST_FAILURE, payload: error });
  }
}

function* createClient({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createClient, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.POST_CLIENT_SUCCESS,
      });
      payload.callback();
      yield call(getClientsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_CLIENT_FAILURE, payload: error });
  }
}

function* editClient({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editClient, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_CLIENT_SUCCESS,
      });
      payload.callback();
      yield call(getClientsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CLIENT_FAILURE, payload: error });
  }
}

function* deleteClient({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteClient, payload.id);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_CLIENT_SUCCESS,
      });
      payload.callback();
      yield call(getClientsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CLIENT_FAILURE, payload: error });
  }
}

function* changeClientStatus({ payload }: AnyAction) {
  const { success } = yield call(api.changeClientStatus, payload.id, payload.isActive);

  if (success) {
    yield call(getClientsList);
  }
}

function* getSuppliersList() {
  const params: paramsTypes.SuppliersParams = yield select((state: RootState) => state.finance.suppliersParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getSuppliersList, data);
    if (success) {
      yield put({
        type: ActionTypes.GET_SUPPLIERS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SUPPLIERS_LIST_FAILURE, payload: error });
  }
}

function* createSupplier({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createSupplier, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.POST_SUPPLIER_SUCCESS,
      });
      payload.callback();
      yield call(getSuppliersList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_SUPPLIER_FAILURE, payload: error });
  }
}

function* editSupplier({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editSupplier, payload.data);
    if (success) {
      yield put({
        type: ActionTypes.PUT_SUPPLIER_SUCCESS,
      });
      payload.callback();
      yield call(getSuppliersList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_SUPPLIER_FAILURE, payload: error });
  }
}

function* deleteSupplier({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteSupplier, payload.id);
    if (success) {
      yield put({
        type: ActionTypes.DELETE_SUPPLIER_SUCCESS,
      });
      payload.callback();
      yield call(getSuppliersList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_SUPPLIER_FAILURE, payload: error });
  }
}

function* getProjectTypesList() {
  const params: paramsTypes.ProjectTypesParams = yield select((state: RootState) => state.finance.projectTypesParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getProjectTypesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROJECT_TYPES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROJECT_TYPES_LIST_FAILURE, payload: error });
  }
}

function* createProjectType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createProjectType, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.POST_PROJECT_TYPE_SUCCESS,
      });
      payload.callback();
      yield call(getProjectTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_PROJECT_TYPE_FAILURE, payload: error });
  }
}

function* editProjectType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editProjectType, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROJECT_TYPE_SUCCESS,
      });
      payload.callback();
      yield call(getProjectTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROJECT_TYPE_FAILURE, payload: error });
  }
}

function* deleteProjectType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteProjectType, payload.id);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_PROJECT_TYPE_SUCCESS,
      });
      payload.callback();
      yield call(getProjectTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_PROJECT_TYPE_FAILURE, payload: error });
  }
}

function* getExpenseTypesList() {
  const params: paramsTypes.ExpenseTypesParams = yield select((state: RootState) => state.finance.expenseTypesParams);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getExpenseTypesList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_EXPENSE_TYPES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EXPENSE_TYPES_LIST_FAILURE, payload: error });
  }
}

function* getExpenseTypesFullList({ payload }: AnyAction) {
  const params: paramsTypes.ExpenseTypesParams = {
    ...(yield select((state: RootState) => state.finance.expenseTypesParams)),
    size: 1000,
  };
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getExpenseTypesFullList, data);

    if (success) {
      payload.callback(results);
    }
  } catch (error) {}
}

function* createExpenseType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createExpenseType, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.POST_EXPENSE_TYPE_SUCCESS,
      });
      payload.callback();
      yield call(getExpenseTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_EXPENSE_TYPE_FAILURE, payload: error });
  }
}

function* editExpenseType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editExpenseType, payload.data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_EXPENSE_TYPE_SUCCESS,
      });
      payload.callback();
      yield call(getExpenseTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_EXPENSE_TYPE_FAILURE, payload: error });
  }
}

function* deleteExpenseType({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteExpenseType, payload.id);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_EXPENSE_TYPE_SUCCESS,
      });
      payload.callback();
      yield call(getExpenseTypesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_EXPENSE_TYPE_FAILURE, payload: error });
  }
}

function* getSalariesList() {
  const params: paramsTypes.SalariesParams = yield select((state: RootState) => state.finance.salariesParams);
  let officeIds: string[] = params.officeIds;

  if (!params.officeIds.length) {
    const policies: UserPolicy[] = yield select((state: RootState) => state.policies.authUserPolicies);
    const policyOfficiesIds: string[] | undefined = yield (policies || getUserPolicies())?.find(
      policy => policy.policy.name === VIEW_SALARY,
    )?.officeIds;

    officeIds = policyOfficiesIds || [];
  }

  try {
    const { success, results } = yield call(api.getSalariesList, { ...params, officeIds });

    if (success) {
      yield put({
        type: ActionTypes.GET_SALARIES_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SALARIES_LIST_FAILURE, payload: error });
  }
}

function* getSalary({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getSalary, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_SALARY_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SALARY_FAILURE, payload: error });
  }
}

function* editSalary({ payload }: AnyAction) {
  try {
    const salary: Salary = payload.data;
    const formattedData: SalaryType = omit(
      {
        ...salary,
        pay: salary.pay.float,
        tax: salary.tax.float,
        startDate: getFormattedDate(salary.startYear, salary.startMonth),
        endDate: getFormattedDate(salary.endYear, salary.endMonth),
      },
      ['startYear', 'startMonth', 'endYear', 'endMonth'],
    );

    const { success } = yield call(api.editSalary, formattedData);

    if (success) {
      yield put({
        type: ActionTypes.PUT_SALARY_SUCCESS,
      });
      payload.callback();
      yield call(getSalariesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_SALARY_FAILURE, payload: error });
  }
}

function* deleteSalary({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteSalary, payload);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_SALARY_SUCCESS,
      });
      payload.callback();
      yield call(getSalariesList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_SALARY_FAILURE, payload: error });
  }
}

function* getProjectsList() {
  const params: paramsTypes.FinanceProjectsParams = yield select((state: RootState) => state.finance.projectParams);

  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getProjectsList, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_FINANCE_PROJECTS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FINANCE_PROJECTS_LIST_FAILURE, payload: error });
  }
}

function* createProject({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.createProject, payload.data, payload.forceUpdate);

    if (success) {
      yield put({
        type: ActionTypes.POST_FINANCE_PROJECT_SUCCESS,
      });
      payload.callback();
      yield call(getProjectsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_FINANCE_PROJECT_FAILURE, payload: error });
  }
}

function* editProject({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editProject, payload.data, payload.forceUpdate);

    if (success) {
      yield put({
        type: ActionTypes.PUT_FINANCE_PROJECT_SUCCESS,
      });
      payload.callback();
      yield call(getProjectsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_FINANCE_PROJECT_FAILURE, payload: error });
  }
}

function* deleteProject({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteProject, payload.id);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_FINANCE_PROJECT_SUCCESS,
      });
      payload.callback();
      yield call(getProjectsList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_FINANCE_PROJECT_FAILURE, payload: error });
  }
}

function* changeProjectStatus({ payload }: AnyAction) {
  const { success } = yield call(api.changeProjectStatus, payload.id, payload.isActive);

  if (success) {
    yield call(getProjectsList);
  }
}

function* getTransactionsList() {
  const params: paramsTypes.TransactionsParams = yield select((state: RootState) => state.finance.transactionsParams);
  const data = getQueryParams(params);

  let officeIds: string[] = params.officeIds;

  if (!data.officeIds.length) {
    const policies: UserPolicy[] = yield select((state: RootState) => state.policies.authUserPolicies);
    const policyOfficiesIds: string[] | undefined = yield (policies || getUserPolicies())?.find(
      policy => policy.policy.name === VIEW_TRANSACTION,
    )?.officeIds;

    officeIds = policyOfficiesIds || [];
  }

  const totalParams = omit(data, ['page', 'size']);
  try {
    const { success, results } = yield call(api.getTransactionsList, { ...data, officeIds });
    const { success: totalSuccess, results: total } = yield call(api.getTransactionsTotal, totalParams);

    if (success && totalSuccess) {
      yield put({
        type: ActionTypes.GET_TRANSACTIONS_LIST_SUCCESS,
        payload: {
          transactions: results,
          total,
        },
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_TRANSACTIONS_LIST_FAILURE, payload: error });
  }
}

function* createTransactions({ payload }: AnyAction) {
  try {
    const formattedData: any = getFormattedTransaction(payload.data);
    const { success } = yield call(api.createTransactions, formattedData);

    if (success) {
      yield put({
        type: ActionTypes.POST_TRANSACTION_SUCCESS,
      });
      payload.callback();
      yield put({
        type: ActionTypes.GET_TRANSACTIONS_LIST_REQUEST,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_TRANSACTION_FAILURE, payload: error });
  }
}

function* batchCreateTransactions({ payload }: AnyAction) {
  try {
    const transactions: ImportTransaction[] = payload.data;
    const importTransactions = transactions
      .filter(item => item.importTransaction)
      .map(item => getFormattedImportTransaction(item));

    const { success } = yield call(api.batchCreateTransactions, { transactions: importTransactions });

    if (success) {
      yield put({
        type: ActionTypes.IMPORT_TRANSACTIONS_SUCCESS,
      });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.IMPORT_TRANSACTIONS_FAILURE, payload: error });
  }
}

function* editTransaction({ payload }: AnyAction) {
  try {
    const transaction: Transaction = payload.data;
    const formattedData: any = {
      ...transaction,
      amount: transaction.amount.float,
      rate: transaction.rate.float,
      unifiedAmount: transaction.unifiedAmount.float,
    };
    const { success } = yield call(api.editTransaction, formattedData);

    if (success) {
      yield put({
        type: ActionTypes.PUT_TRANSACTION_SUCCESS,
      });
      payload.callback();
      yield put({
        type: ActionTypes.GET_TRANSACTIONS_LIST_REQUEST,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_TRANSACTION_FAILURE, payload: error });
  }
}

function* deleteTransaction({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deleteTransaction, payload.id);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_TRANSACTION_SUCCESS,
      });
      payload.callback();
      yield put({
        type: ActionTypes.GET_TRANSACTIONS_LIST_REQUEST,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_TRANSACTION_FAILURE, payload: error });
  }
}

function* getPlansList() {
  const params: paramsTypes.FinancePlanParams = yield select((state: RootState) => state.finance.planParams);

  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getPlans, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_FINANCE_PLANS_LIST_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FINANCE_PLANS_LIST_FAILURE, payload: error });
  }
}

function* getPlan({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getCurrentPlan, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_FINANCE_PLAN_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FINANCE_PLAN_FAILURE, payload: error });
  }
}

function* createPlan({ payload }: AnyAction) {
  try {
    const plan: FinancePlan = payload.data;
    const formattedData: any = {
      ...plan,
      currencyWorkHourRate: plan.currencyWorkHourRate.float,
      baseRate: plan.baseRate.float,
      currencyRate: plan.currencyRate.float,
      month: getFormattedDate(plan.year, plan.month),
    };
    delete formattedData.plannedIncome;
    delete formattedData.year;

    const { success, results } = yield call(api.createPlan, formattedData);

    if (success) {
      yield put({
        type: ActionTypes.POST_FINANCE_PLAN_SUCCESS,
      });

      payload.callback(results.id);
      yield call(getPlansList);
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_FINANCE_PLAN_FAILURE, payload: error });
  }
}

function* editPlan({ payload }: AnyAction) {
  try {
    const plan: FinancePlan = payload.data;
    const formattedData: any = {
      ...plan,
      currencyWorkHourRate: plan.currencyWorkHourRate.float,
      baseRate: plan.baseRate.float,
      currencyRate: plan.currencyRate.float,
      plannedIncome: plan.plannedIncome.float,
      employees: plan.employees.map(item => ({ ...item, rate: item.rate.float })),
      month: getFormattedDate(plan.year, plan.month),
    };
    delete formattedData.year;

    const { success } = yield call(api.editPlan, formattedData);

    if (success) {
      yield put({
        type: ActionTypes.PUT_FINANCE_PLAN_SUCCESS,
      });
      payload.callback();

      if (!payload.noGetPlans) {
        yield call(getPlansList);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_FINANCE_PLAN_FAILURE, payload: error });
  }
}

function* clonePlan({ payload }: AnyAction) {
  try {
    const plan: FinancePlan = payload.data;
    const formattedData: any = {
      ...plan,
      currencyWorkHourRate: plan.currencyWorkHourRate.float,
      baseRate: plan.baseRate.float,
      currencyRate: plan.currencyRate.float,
      plannedIncome: plan.plannedIncome.float,
      employees: plan.employees.map(item => ({ ...item, rate: item.rate.float })),
      month: getFormattedDate(plan.year, plan.month),
    };
    delete formattedData.year;

    const { results: checkedCloneData } = payload.isConfirmModal
      ? { results: [] }
      : yield call(api.checkClonePlan, plan.id, formattedData.month);
    if (!isEmpty(checkedCloneData)) {
      yield put({
        type: ActionTypes.SET_CLONE_FINANCE_PLAN_CHECKED_INFO,
        payload: { info: checkedCloneData, clonnedPlan: formattedData },
      });
    } else {
      const { success, results } = yield call(api.clonePlan, formattedData);
      if (success) {
        yield put({
          type: ActionTypes.CLONE_FINANCE_PLAN_SUCCESS,
        });
        payload.callback(results.id);

        if (!payload.noGetPlans) {
          yield call(getPlansList);
        }
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.CLONE_FINANCE_PLAN_FAILURE, payload: error });
    payload.closeConfirmModal();
  }
}

function* deletePlan({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deletePlan, payload.id);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_FINANCE_PLAN_SUCCESS,
      });
      payload.callback();

      if (!payload.noGetPlans) {
        yield call(getPlansList);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_FINANCE_PLAN_FAILURE, payload: error });
  }
}

function* getPlanEmployees({ payload }: AnyAction) {
  const params: paramsTypes.FinancePlanEmployeesParams = yield select(
    (state: RootState) => state.finance.planEmployeesParams,
  );

  const planId: string = yield select((state: RootState) => state.finance.currentPlanData?.id);
  const data = getQueryParams(params);

  try {
    const { success, results } = yield call(api.getPlanEmployees, payload?.planId || planId, data);

    if (success) {
      yield put({
        type: ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_FAILURE, payload: error });
  }
}

function* getEmployeeHours({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getEmployeeHours, payload.id, payload.month);

    if (success) {
      yield put({
        type: ActionTypes.GET_EMPLOYEE_HOURS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMPLOYEE_HOURS_FAILURE, payload: error });
  }
}

function* createPlanEmployee({ payload }: AnyAction) {
  try {
    const employeeHours: EmployeeHours & { currentLoad: EmployeeHoursPlanLoadType } = payload.data;
    const formattedData: any = {
      ...omit(employeeHours, ['currentLoad', 'busyWorkHours']),
      planLoads: [...employeeHours.planLoads, employeeHours.currentLoad].map(item => ({
        ...omit(item, ['financePlan', 'workHoursActual']),
        rate: item.rate.float,
        rateFinalized: item.rateFinalized ? item.rateFinalized.float : null,
        workHoursFinalized: item.workHoursFinalized ? item.workHoursFinalized.float : null,
        workHours: item.workHours.float,
        hoursPercent: item.hoursPercent.float,
      })),
    };

    const { success } = yield call(api.createPlanEmployee, payload.id, formattedData);

    if (success) {
      yield put({
        type: ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_SUCCESS,
      });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_FAILURE, payload: error });
  }
}

function* editPlanEmployee({ payload }: AnyAction) {
  try {
    const employeeHours: EmployeeHours & { currentLoad: EmployeeHoursPlanLoadType } = payload.data;
    const formattedData: any = {
      ...omit(employeeHours, 'currentLoad'),
      planLoads: [...employeeHours.planLoads, employeeHours.currentLoad].map(item => ({
        ...omit(item, ['financePlan', 'workHoursActual']),
        rate: item.rate.float,
        rateFinalized: item.rateFinalized ? item.rateFinalized.float : null,
        workHours: item.workHours.float,
        workHoursFinalized: item.workHoursFinalized ? item.workHoursFinalized.float : null,
        hoursPercent: item.hoursPercent.float,
      })),
    };

    const { success } = yield call(api.editPlanEmployee, payload.id, formattedData);

    if (success) {
      yield put({
        type: ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_SUCCESS,
      });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_FAILURE, payload: error });
  }
}

function* editPlanEmployeeFinalize({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.editPlanEmployeeFinalize, payload.id);

    if (success) {
      payload.callback();
    }
  } catch (error) {}
}

function* deletePlanEmployee({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deletePlanEmployee, payload.planId, payload.employeeId);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_SUCCESS,
      });
      payload.callback();
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_FAILURE, payload: error });
  }
}

function* changePlanMonth({ payload }: AnyAction) {
  try {
    const { month, cb } = payload;
    const currentPlan: FinancePlan = yield select((state: RootState) => state.finance.currentPlanData);
    const params = getQueryParams(
      new paramsTypes.FinancePlanParams({
        projectIds: [currentPlan?.financeProjectId],
        clientIds: [currentPlan?.clientId],
        month,
      }),
    );

    const { results } = yield call(api.getPlans, params);
    const planId = !isEmpty(results.content) ? results.content[0].id : null;
    if (planId) {
      cb && cb(planId);
    } else {
      cb && cb();
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FINANCE_PLAN_FAILURE, payload: error });
  }
}

function* getIncomesExpensesReport() {
  const params: paramsTypes.IncomesExpensesReportParams = yield select(
    (state: RootState) => state.finance.incomesExpensesReportParams,
  );
  let officeIds: string[] = params.officeIds;

  if (!params.officeIds.length) {
    const policies: UserPolicy[] = yield select((state: RootState) => state.policies.authUserPolicies);
    const policyOfficiesIds: string[] | undefined = yield (policies || getUserPolicies())?.find(
      policy => policy.policy.name === VIEW_INCOMES_EXPENSES_REPORT,
    )?.officeIds;

    officeIds = policyOfficiesIds || [];
  }

  try {
    const { success, results } = yield call(api.getIncomesExpensesReport, { ...params, officeIds });

    if (success) {
      yield put({
        type: ActionTypes.GET_INCOMES_EXPENSES_REPORT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_INCOMES_EXPENSES_REPORT_FAILURE, payload: error });
  }
}

function* importResources({ payload }: AnyAction) {
  try {
    const { financePlanId, projectGroupId, month, year } = payload.data;
    const formattedMonth = getFormattedDate(year, month);
    const { results: checkedImportResourcesData } = payload.isConfirmModal
      ? { results: [] }
      : yield call(api.checkImportResources, financePlanId, { month: formattedMonth, projectGroupId });

    if (!isEmpty(checkedImportResourcesData)) {
      yield put({
        type: ActionTypes.SET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO,
        payload: checkedImportResourcesData,
      });
    } else {
      const { success, results } = yield call(api.importResources, financePlanId, formattedMonth, projectGroupId);
      if (success) {
        yield put({ type: ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_SUCCESS, payload: results });
        payload.callback();
        yield put({
          type: ActionTypes.GET_FINANCE_PLAN_REQUEST,
          payload: financePlanId,
        });
        yield put({ type: ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_REQUEST, payload: { planId: financePlanId } });
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_FAILURE, payload: error });
    payload.closeConfirmModal();
  }
}

function* getDatesOfExistingPlans({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getDatesOfExistingPlans, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_DATES_OF_EXISTING_PLANS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DATES_OF_EXISTING_PLANS_FAILURE, payload: error });
  }
}

function* getOfficesBalance() {
  const params: paramsTypes.OfficesBalanceParams = yield select(
    (state: RootState) => state.finance.officesBalanceParams,
  );

  try {
    const { success, results } = yield call(api.getOfficesBalanceList, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_OFFICES_BALANCE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_OFFICES_BALANCE_FAILURE, payload: error });
  }
}

function* getCashFlowReports() {
  const params: cashflowTypes.CashFlowReportParams = yield select(
    (state: RootState) => state.finance.cashFlowReportParams,
  );

  try {
    const { success, results } = yield call(api.getCashFlowReportList, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_CASH_FLOW_REPORT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CASH_FLOW_REPORT_FAILURE, payload: error });
  }
}

function* getCashFlowExpenseSetting({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getCashFlowReportExpenseSetting, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_CASH_FLOW_SETTING_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CASH_FLOW_SETTING_FAILURE, payload: error });
  }
}

function* editCashFlowExpenseSetting({ payload }: AnyAction) {
  try {
    const { data, blockType } = payload.params;

    const { success } = yield call(api.putCashFlowReportExpenseSetting, blockType, data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_CASH_FLOW_SETTING_SUCCESS,
      });
      payload.callback();
      yield call(getCashFlowReports);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CASH_FLOW_SETTING_FAILURE, payload: error });
  }
}

function* getCashFlowIncomeSetting({ payload }: AnyAction) {
  try {
    const { success, results } = yield call(api.getCashFlowReportIncomeSetting, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_CASH_FLOW_SETTING_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CASH_FLOW_SETTING_FAILURE, payload: error });
  }
}

function* editCashFlowIncomeSetting({ payload }: AnyAction) {
  try {
    const { data, blockType } = payload.params;

    const { success } = yield call(api.putCashFlowReportIncomeSetting, blockType, data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_CASH_FLOW_SETTING_SUCCESS,
      });
      payload.callback();
      yield call(getCashFlowReports);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CASH_FLOW_SETTING_FAILURE, payload: error });
  }
}

function* getCashFlowTotalSetting() {
  try {
    const { success, results } = yield call(api.getCashFlowReportTotalSetting);

    if (success) {
      yield put({
        type: ActionTypes.GET_CASH_FLOW_TOTAL_SETTING_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CASH_FLOW_TOTAL_SETTING_SUCCESS, payload: error });
  }
}

function* editCashFlowTotalSetting({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.putCashFlowReportTotalSetting, payload.params);

    if (success) {
      yield put({
        type: ActionTypes.PUT_CASH_FLOW_TOTAL_SETTING_SUCCESS,
      });
      payload.callback();
      yield call(getCashFlowTotalSetting);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CASH_FLOW_TOTAL_SETTING_FAILURE, payload: error });
  }
}

function* getProfitLoss() {
  const params: paramsTypes.OfficesBalanceParams = yield select((state: RootState) => state.finance.profitLossParams);

  try {
    const { success, results } = yield call(api.getProfitLossList, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_ERROR, payload: error });
  }
}

function* getProfitLossCostProject({ payload }: any) {
  try {
    const { success, results } = yield call(api.getProfitLossCostOfProject, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_COST_PROJECT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_COST_PROJECT_ERROR, payload: error });
  }
}

function* editProfitLossCostProject({ payload }: AnyAction) {
  try {
    const { data, blockType } = payload.params;

    const { success } = yield call(api.putProfitLossCostOfProject, blockType, data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROFIT_LOSS_COST_PROJECT_SUCCESS,
      });
      payload.callback();
      yield call(getProfitLossCostProject, { payload: blockType });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROFIT_LOSS_COST_PROJECT_ERROR, payload: error });
  }
}

function* getProfitLossFinancialExpense({ payload }: any) {
  try {
    const { success, results } = yield call(api.getProfitLossFinancialExpense, payload);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_FINANCIAL_EXPENSE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_FINANCIAL_EXPENSE_ERROR, payload: error });
  }
}

function* editProfitLossFinancialExpense({ payload }: AnyAction) {
  try {
    const { data, blockType } = payload.params;

    const { success } = yield call(api.putProfitLossFinancialExpense, blockType, data);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_EXPENSE_SUCCESS,
      });
      payload.callback();
      yield call(getProfitLossFinancialExpense, { payload: blockType });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_EXPENSE_ERROR, payload: error });
  }
}

function* getProfitLossFinancialRevenue() {
  try {
    const { success, results } = yield call(api.getProfitLossFinancialRevenue);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_FINANCIAL_REVENUE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_FINANCIAL_REVENUE_ERROR, payload: error });
  }
}

function* editProfitLossFinancialRevenue({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.putProfitLossFinancialRevenue, payload.params);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_REVENUE_SUCCESS,
      });
      payload.callback();
      yield call(getProfitLossFinancialRevenue);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_REVENUE_ERROR, payload: error });
  }
}

function* getProfitLossTotalGoods() {
  try {
    const { success, results } = yield call(api.getProfitLossTotalGoods);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_TOTAL_GOODS_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_TOTAL_GOODS_ERROR, payload: error });
  }
}

function* editProfitLossTotalGoods({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.putProfitLossTotalGoods, payload.params);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROFIT_LOSS_TOTAL_GOODS_SUCCESS,
      });
      payload.callback();
      yield call(getProfitLossTotalGoods);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROFIT_LOSS_TOTAL_GOODS_ERROR, payload: error });
  }
}

function* getProfitLossRevenuesExpense() {
  try {
    const { success, results } = yield call(api.getProfitLossRevenuesExpense);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_REVENUE_EXPENSE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_REVENUE_EXPENSE_ERROR, payload: error });
  }
}

function* editProfitLossRevenuesExpense({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.putProfitLossRevenuesExpense, payload.params);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROFIT_LOSS_REVENUE_EXPENSE_SUCCESS,
      });
      payload.callback();
      yield call(getProfitLossRevenuesExpense);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROFIT_LOSS_REVENUE_EXPENSE_ERROR, payload: error });
  }
}

function* getProfitLossGrossProfit() {
  try {
    const { success, results } = yield call(api.getProfitLossGrossProfit);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_GROSS_PROFIT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_GROSS_PROFIT_ERROR, payload: error });
  }
}

function* editProfitLossGrossProfit({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.putProfitLossGrossProfit, payload.params);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROFIT_LOSS_GROSS_PROFIT_SUCCESS,
      });
      payload.callback();
      yield call(getProfitLossGrossProfit);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROFIT_LOSS_GROSS_PROFIT_ERROR, payload: error });
  }
}

function* getProfitLossNetIncome() {
  try {
    const { success, results } = yield call(api.getProfitLossNetIncome);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_NET_INCOME_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_NET_INCOME_ERROR, payload: error });
  }
}

function* editProfitLossNetIncome({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.putProfitLossNetIncome, payload.params);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROFIT_LOSS_NET_INCOME_SUCCESS,
      });
      payload.callback();
      yield call(getProfitLossNetIncome);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROFIT_LOSS_NET_INCOME_ERROR, payload: error });
  }
}

function* getProfitLossOperatingIncome() {
  try {
    const { success, results } = yield call(api.getProfitLossOperatingIncome);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_OPERATING_INCOME_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_OPERATING_INCOME_ERROR, payload: error });
  }
}

function* editProfitLossOperatingIncome({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.putProfitLossOperatingIncome, payload.params);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROFIT_LOSS_OPERATING_INCOME_SUCCESS,
      });
      payload.callback();
      yield call(getProfitLossOperatingIncome);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROFIT_LOSS_OPERATING_INCOME_ERROR, payload: error });
  }
}

function* getProfitLossRevenueClient() {
  try {
    const { success, results } = yield call(api.getProfitLossRevenueClientList);

    if (success) {
      yield put({
        type: ActionTypes.GET_PROFIT_LOSS_REVENUE_CLIENT_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFIT_LOSS_REVENUE_CLIENT_ERROR, payload: error });
  }
}

function* editProfitLossRevenueClient({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.putProfitLossRevenueClient, payload.params);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PROFIT_LOSS_REVENUE_CLIENT_SUCCESS,
      });
      payload.callback();
      yield call(getProfitLossRevenueClient);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PROFIT_LOSS_REVENUE_CLIENT_ERROR, payload: error });
  }
}

function* getCashFlowBalanceSetting() {
  try {
    const { success, results } = yield call(api.getCashFlowReportBalanceSetting);

    if (success) {
      yield put({
        type: ActionTypes.GET_CASH_FLOW_BALANCE_SETTING_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CASH_FLOW_BALANCE_SETTING_FAILURE, payload: error });
  }
}

function* editCashFlowBalanceSetting({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.putCashFlowReportBalanceSetting, payload.params);

    if (success) {
      yield put({
        type: ActionTypes.PUT_CASH_FLOW_BALANCE_SETTING_SUCCESS,
      });
      payload.callback();
      yield call(getCashFlowBalanceSetting);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_CASH_FLOW_BALANCE_SETTING_SUCCESS, payload: error });
  }
}

function* editOfficeBalance({ payload }: AnyAction) {
  try {
    const officeBalance: OfficeBalanceClass = payload.data;

    const { success } = yield call(api.editOfficeBalance, officeBalance);

    if (success) {
      yield put({
        type: ActionTypes.PUT_OFFICE_BALANCE_SUCCESS,
      });
      payload.callback();
      yield call(getOfficesBalance);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_OFFICE_BALANCE_FAILURE, payload: error });
  }
}

function* getPlannedExpense() {
  const params: paramsTypes.PlannedExpenseParams = yield select(
    (state: RootState) => state.finance.plannedExpenseParams,
  );

  try {
    const { success, results } = yield call(api.getPlannedExpense, params);

    if (success) {
      yield put({
        type: ActionTypes.GET_PLANNED_EXPENSE_SUCCESS,
        payload: results,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PLANNED_EXPENSE_FAILURE, payload: error });
  }
}

function* editPlannedExpense({ payload }: AnyAction) {
  try {
    const plannedExpense: PlannedExpenseClass = payload.data;
    const formattedData: any = omit(
      {
        ...plannedExpense,
        amount: plannedExpense.amount.float,
        startDate: getFormattedDate(plannedExpense.startYear, plannedExpense.startMonth),
        endDate: getFormattedDate(plannedExpense.endYear, plannedExpense.endMonth),
      },
      ['startYear', 'startMonth', 'endYear', 'endMonth'],
    );

    const { success } = yield call(api.editPlannedExpense, formattedData);

    if (success) {
      yield put({
        type: ActionTypes.PUT_PLANNED_EXPENSE_SUCCESS,
      });
      payload.callback();
      yield call(getPlannedExpense);
    }
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PLANNED_EXPENSE_FAILURE, payload: error });
  }
}

function* deletePlannedExpense({ payload }: AnyAction) {
  try {
    const { success } = yield call(api.deletePlannedExpense, payload.id, payload.deleteFutureExpenses);

    if (success) {
      yield put({
        type: ActionTypes.DELETE_PLANNED_EXPENSE_SUCCESS,
      });
      payload.callback();
      yield call(getPlannedExpense);
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_PLANNED_EXPENSE_FAILURE, payload: error });
  }
}

export default function* financeSaga() {
  yield all([
    takeEvery([ActionTypes.GET_INCOME_TYPES_LIST_REQUEST, ActionTypes.SET_INCOME_TYPES_PARAMS], getIncomeTypesList),
    takeEvery([ActionTypes.GET_INCOME_TYPES_FULL_LIST], getIncomeTypesFullList),
    takeEvery(ActionTypes.POST_INCOME_TYPE_REQUEST, createIncomeType),
    takeEvery(ActionTypes.PUT_INCOME_TYPE_REQUEST, editIncomeType),
    takeEvery(ActionTypes.DELETE_INCOME_TYPE_REQUEST, deleteIncomeType),
    takeEvery([ActionTypes.GET_CURRENCIES_LIST_REQUEST, ActionTypes.SET_CURRENCIES_PARAMS], getCurrenciesList),
    takeEvery(ActionTypes.POST_CURRENCY_REQUEST, createCurrency),
    takeEvery(ActionTypes.PUT_CURRENCY_REQUEST, editCurrency),
    takeEvery(ActionTypes.DELETE_CURRENCY_REQUEST, deleteCurrency),
    takeEvery([ActionTypes.GET_SUPPLIERS_LIST_REQUEST, ActionTypes.SET_SUPPLIERS_PARAMS], getSuppliersList),
    takeEvery(ActionTypes.POST_SUPPLIER_REQUEST, createSupplier),
    takeEvery(ActionTypes.PUT_SUPPLIER_REQUEST, editSupplier),
    takeEvery(ActionTypes.DELETE_SUPPLIER_REQUEST, deleteSupplier),
    takeEvery([ActionTypes.GET_PROJECT_TYPES_LIST_REQUEST, ActionTypes.SET_PROJECT_TYPES_PARAMS], getProjectTypesList),
    takeEvery(ActionTypes.POST_PROJECT_TYPE_REQUEST, createProjectType),
    takeEvery(ActionTypes.PUT_PROJECT_TYPE_REQUEST, editProjectType),
    takeEvery(ActionTypes.DELETE_PROJECT_TYPE_REQUEST, deleteProjectType),
    takeEvery([ActionTypes.GET_EXPENSE_TYPES_LIST_REQUEST, ActionTypes.SET_EXPENSE_TYPES_PARAMS], getExpenseTypesList),
    takeEvery([ActionTypes.GET_EXPENSE_TYPES_FULL_LIST], getExpenseTypesFullList),
    takeEvery(ActionTypes.POST_EXPENSE_TYPE_REQUEST, createExpenseType),
    takeEvery(ActionTypes.PUT_EXPENSE_TYPE_REQUEST, editExpenseType),
    takeEvery(ActionTypes.DELETE_EXPENSE_TYPE_REQUEST, deleteExpenseType),
    takeEvery([ActionTypes.GET_CLIENTS_LIST_REQUEST, ActionTypes.SET_CLIENTS_PARAMS], getClientsList),
    takeEvery(ActionTypes.POST_CLIENT_REQUEST, createClient),
    takeEvery(ActionTypes.PUT_CLIENT_REQUEST, editClient),
    takeEvery(ActionTypes.DELETE_CLIENT_REQUEST, deleteClient),
    takeEvery(ActionTypes.CHANGE_CLIENT_STATUS, changeClientStatus),
    takeEvery(ActionTypes.SET_SALARIES_PARAMS, getSalariesList),
    takeEvery(ActionTypes.GET_SALARY_REQUEST, getSalary),
    takeEvery(ActionTypes.PUT_SALARY_REQUEST, editSalary),
    takeEvery(ActionTypes.DELETE_SALARY_REQUEST, deleteSalary),
    takeEvery(
      [ActionTypes.GET_FINANCE_PROJECTS_LIST_REQUEST, ActionTypes.SET_FINANCE_PROJECTS_PARAMS],
      getProjectsList,
    ),
    takeEvery(ActionTypes.POST_FINANCE_PROJECT_REQUEST, createProject),
    takeEvery(ActionTypes.PUT_FINANCE_PROJECT_REQUEST, editProject),
    takeEvery(ActionTypes.DELETE_FINANCE_PROJECT_REQUEST, deleteProject),
    takeEvery(ActionTypes.CHANGE_FINANCE_PROJECT_STATUS, changeProjectStatus),
    takeEvery([ActionTypes.GET_FINANCE_PLANS_LIST_REQUEST, ActionTypes.SET_FINANCE_PLANS_PARAMS], getPlansList),
    takeEvery(ActionTypes.GET_FINANCE_PLAN_REQUEST, getPlan),
    takeEvery(ActionTypes.POST_FINANCE_PLAN_REQUEST, createPlan),
    takeEvery(ActionTypes.PUT_FINANCE_PLAN_REQUEST, editPlan),
    takeEvery(ActionTypes.CLONE_FINANCE_PLAN_REQUEST, clonePlan),
    takeEvery(ActionTypes.DELETE_FINANCE_PLAN_REQUEST, deletePlan),
    takeEvery(
      [ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_REQUEST, ActionTypes.SET_FINANCE_PLAN_EMPLOYEES_PARAMS],
      getPlanEmployees,
    ),
    takeEvery(ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_REQUEST, createPlanEmployee),
    takeEvery(ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_REQUEST, editPlanEmployee),
    takeEvery(ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_FINALIZE_REQUEST, editPlanEmployeeFinalize),
    takeEvery(ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_REQUEST, deletePlanEmployee),
    takeLatest(ActionTypes.SET_FINANCE_PLAN_MONTH, changePlanMonth),
    takeEvery(ActionTypes.GET_EMPLOYEE_HOURS_REQUEST, getEmployeeHours),
    takeEvery(
      [ActionTypes.SET_INCOMES_EXPENSES_REPORT_PARAMS, ActionTypes.GET_INCOMES_EXPENSES_REPORT_REQUEST],
      getIncomesExpensesReport,
    ),
    takeEvery([ActionTypes.SET_CASH_FLOW_REPORT_PARAMS], getCashFlowReports),
    takeEvery([ActionTypes.GET_CASH_FLOW_BALANCE_SETTING_REQUEST], getCashFlowTotalSetting),
    takeEvery([ActionTypes.PUT_CASH_FLOW_TOTAL_SETTING_REQUEST], editCashFlowTotalSetting),
    takeEvery([ActionTypes.GET_CASH_FLOW_TOTAL_SETTING_REQUEST], getCashFlowBalanceSetting),

    takeEvery([ActionTypes.GET_PROFIT_LOSS], getProfitLoss),
    takeEvery([ActionTypes.PUT_PROFIT_LOSS_COST_PROJECT], editProfitLossCostProject),
    takeEvery([ActionTypes.GET_PROFIT_LOSS_COST_PROJECT], getProfitLossCostProject),
    takeEvery([ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_EXPENSE], editProfitLossFinancialExpense),
    takeEvery([ActionTypes.GET_PROFIT_LOSS_FINANCIAL_EXPENSE], getProfitLossFinancialExpense),
    takeEvery([ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_REVENUE], editProfitLossFinancialRevenue),
    takeEvery([ActionTypes.GET_PROFIT_LOSS_FINANCIAL_REVENUE], getProfitLossFinancialRevenue),
    takeEvery([ActionTypes.PUT_PROFIT_LOSS_NET_INCOME], editProfitLossNetIncome),
    takeEvery([ActionTypes.GET_PROFIT_LOSS_NET_INCOME], getProfitLossNetIncome),
    takeEvery([ActionTypes.PUT_PROFIT_LOSS_OPERATING_INCOME], editProfitLossOperatingIncome),
    takeEvery([ActionTypes.GET_PROFIT_LOSS_OPERATING_INCOME], getProfitLossOperatingIncome),
    takeEvery([ActionTypes.PUT_PROFIT_LOSS_GROSS_PROFIT], editProfitLossGrossProfit),
    takeEvery([ActionTypes.GET_PROFIT_LOSS_GROSS_PROFIT], getProfitLossGrossProfit),
    takeEvery([ActionTypes.PUT_PROFIT_LOSS_REVENUE_CLIENT], editProfitLossRevenueClient),
    takeEvery([ActionTypes.GET_PROFIT_LOSS_REVENUE_CLIENT], getProfitLossRevenueClient),
    takeEvery([ActionTypes.PUT_PROFIT_LOSS_REVENUE_EXPENSE], editProfitLossRevenuesExpense),
    takeEvery([ActionTypes.GET_PROFIT_LOSS_REVENUE_EXPENSE], getProfitLossRevenuesExpense),
    takeEvery([ActionTypes.PUT_PROFIT_LOSS_TOTAL_GOODS], editProfitLossTotalGoods),
    takeEvery([ActionTypes.GET_PROFIT_LOSS_TOTAL_GOODS], getProfitLossTotalGoods),

    takeEvery([ActionTypes.PUT_CASH_FLOW_BALANCE_SETTING_REQUEST], editCashFlowBalanceSetting),
    takeEvery([ActionTypes.GET_CASH_FLOW_EXPENSE_SETTING_REQUEST], getCashFlowExpenseSetting),
    takeEvery([ActionTypes.GET_CASH_FLOW_INCOME_SETTING_REQUEST], getCashFlowIncomeSetting),
    takeEvery([ActionTypes.PUT_CASH_FLOW_EXPENSE_SETTING_REQUEST], editCashFlowExpenseSetting),
    takeEvery([ActionTypes.PUT_CASH_FLOW_INCOME_SETTING_REQUEST], editCashFlowIncomeSetting),
    takeEvery([ActionTypes.SET_OFFICES_BALANCE_PARAMS], getOfficesBalance),
    takeEvery(ActionTypes.PUT_OFFICE_BALANCE_REQUEST, editOfficeBalance),
    takeEvery([ActionTypes.GET_TRANSACTIONS_LIST_REQUEST, ActionTypes.SET_TRANSACTIONS_PARAMS], getTransactionsList),
    takeEvery(ActionTypes.POST_TRANSACTION_REQUEST, createTransactions),
    takeEvery(ActionTypes.IMPORT_TRANSACTIONS_REQUEST, batchCreateTransactions),
    takeEvery(ActionTypes.PUT_TRANSACTION_REQUEST, editTransaction),
    takeEvery(ActionTypes.DELETE_TRANSACTION_REQUEST, deleteTransaction),
    takeEvery(ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_REQUEST, importResources),
    takeEvery(ActionTypes.GET_DATES_OF_EXISTING_PLANS_REQUEST, getDatesOfExistingPlans),
    takeEvery(ActionTypes.SET_PLANNED_EXPENSE_PARAMS, getPlannedExpense),
    takeEvery(ActionTypes.PUT_PLANNED_EXPENSE_REQUEST, editPlannedExpense),
    takeEvery(ActionTypes.DELETE_PLANNED_EXPENSE_REQUEST, deletePlannedExpense),
  ]);
}
