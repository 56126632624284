import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFormik } from 'formik';
import get from 'lodash-es/get';

import { RejectValueErrors } from '../../../enums/error.enum';
import { EXPENSE_TYPE_SCHEMA } from '../../../enums/finance/finance.enum';
import { ExpenseType } from '../../../types/finance';
import { scrollToError } from '../../../utils';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import Icon from '../../Icon';
import Input from '../../Input';
import Modal from '../../Modal';
import Select from '../../Select';
import messages from '../messages';

type ModalEditExpenseTypeProps = {
  onCloseRequest: () => void;
  editExpenseType: (data: { data: ExpenseType; callback: () => void }) => void;
  expenseType: ExpenseType;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
};

function ModalEditExpenseType({
  onCloseRequest,
  editExpenseType,
  expenseType,
  error,
  isLoading,
  isOpen,
}: ModalEditExpenseTypeProps) {
  const intl = useIntl();

  const expenseTypeOptions = [
    {
      label: intl.formatMessage(messages.personalLabel),
      value: true,
    },
    {
      label: intl.formatMessage(messages.companyLabel),
      value: false,
    },
  ];

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: expenseType,
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: EXPENSE_TYPE_SCHEMA,
    onSubmit: data => {
      return editExpenseType({ data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleTypeChange = useCallback((index, el) => {
    setFieldValue(`subExpenseTypes[${index}].isPersonal`, el.value);
  }, []);

  const addNewSubExpenseType = useCallback(() => {
    setFieldValue('subExpenseTypes', [...values.subExpenseTypes, { id: '', name: '', isPersonal: false }]);
  }, [values]);

  const removeSubExpenseType = useCallback(
    index => () => {
      values.subExpenseTypes.splice(index, 1);
      setFieldValue('subExpenseTypes', values.subExpenseTypes);
    },
    [values],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.editExpenseTypeTitle)}
        size={'small'}
        classNameModal="center"
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages.expenseTypeLabel)}
                defaultValue={values?.name || ''}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
            {values.subExpenseTypes.map((subType, index) => (
              <div className="form__group-wrapper form__group-wrapper--with-gap" key={`[${index}]`}>
                <Input
                  id={`subExpenseTypes[${index}].name`}
                  name={`subExpenseTypes[${index}].name`}
                  label={intl.formatMessage(messages.subExpenseTypeLabel)}
                  defaultValue={values.subExpenseTypes[index].name}
                  onChange={handleChange}
                  hasError={hasError(`subExpenseTypes[${index}].name`)}
                  // @ts-ignore
                  errorMessage={errors?.subExpenseTypes?.[index]?.name}
                />
                <div className="form__input-block form__input-block--half">
                  <Select
                    name={`subExpenseTypes[${index}].isPersonal`}
                    options={expenseTypeOptions}
                    handleChange={e => handleTypeChange(index, e)}
                    label={intl.formatMessage(messages.typeLabel)}
                    value={expenseTypeOptions.find(item => item.value === values.subExpenseTypes[index].isPersonal)}
                  />
                </div>
                <button className="form__btn-clean-inputs" type={'button'} onClick={removeSubExpenseType(index)}>
                  <Icon iconName={'trash'} externalClass={'form__btn-clean'} />
                </button>
              </div>
            ))}
            <button className="form__btn-add-group" onClick={addNewSubExpenseType} type={'button'}>
              <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
              <FormattedMessage {...messages.addButton} />
            </button>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons">
            <Button
              color={'gray'}
              externalClass={'button--modal half--button button--cancel'}
              onClick={onCloseRequest}
              type={'button'}
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button
              externalClass={'button--modal half--button'}
              type={'submit'}
              loading={isLoading}
              disabled={isLoading}
            >
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalEditExpenseType;
