import React, { useMemo, useCallback, useContext } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { DATE_FORMAT } from '../../constants/date.constants';
import { DepthLevels } from '../../constants/tables.constants';
import {
  MonthReportParts,
  PlannedExpenseOffice,
  PlannedExpenseReportItem,
  PlannedSubExpense,
} from '../../enums/finance/finance.enum';
import { CurrencyType, ExpenseType, SubExpenseType } from '../../types/finance';
import { getTableCell } from '../../utils/table.utils';
import { formatValue } from 'react-currency-input-field';
import messages from './messages';
import Dropdown from '../../components/Dropdown';
import PoliciesContext from '../../PoliciesContext';
import Icon from '../../components/Icon';
import { checkPolicies } from '../../utils/policies.utils';
import { DELETE_PLANNED_EXPENSE, UPDATE_PLANNED_EXPENSE } from '../../constants/policies.constants';

export const useDataForTable = (
  tableData: any,
  baseCurrency: CurrencyType | undefined,
  setOfficeMonthClicked: (
    data: {
      office: { id: string; name: string; isDeleted: boolean };
      monthData: MonthReportParts;
      expenseType: ExpenseType;
      subExpenseType: SubExpenseType;
    } | null,
  ) => void,
  openNewPlannedExpenseModal: () => void,
  openEditPlannedExpenseModal: () => void,
  openDeletePlannedExpenseModal: () => void,
) => {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);
  const updatePolicy = useMemo(() => policies.find(policy => policy.policy.name === UPDATE_PLANNED_EXPENSE), [
    policies,
  ]);
  const deletePolicy = useMemo(() => policies.find(policy => policy.policy.name === DELETE_PLANNED_EXPENSE), [
    policies,
  ]);

  const getTableActions: any = useCallback(
    (
      month: MonthReportParts,
      office: { id: string; name: string; isDeleted: boolean },
      expenseType: ExpenseType,
      subExpenseType: SubExpenseType,
    ) =>
      month.amount.value === null
        ? [
            ...(checkPolicies([UPDATE_PLANNED_EXPENSE], policies) &&
            (!updatePolicy?.isOfficeSpecific || updatePolicy?.officeIds.some(id => id === office?.id))
              ? [
                  {
                    label: (
                      <>
                        <Icon iconName={'plus'} externalClass={'dropdown__list-item__icon'} />
                        <FormattedMessage {...messages.newButton} />
                      </>
                    ),
                    itemClassName: 'dropdown__list-item__button',
                    handler: () => {
                      setOfficeMonthClicked({ office, monthData: month, expenseType, subExpenseType });
                      openNewPlannedExpenseModal();
                    },
                  },
                ]
              : []),
          ]
        : [
            ...(checkPolicies([UPDATE_PLANNED_EXPENSE], policies) &&
            (!updatePolicy?.isOfficeSpecific || updatePolicy?.officeIds.some(id => id === office?.id))
              ? [
                  {
                    label: (
                      <>
                        <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                        <FormattedMessage {...messages.editButton} />
                      </>
                    ),
                    itemClassName: 'dropdown__list-item__button',
                    handler: () => {
                      setOfficeMonthClicked({ office, monthData: month, expenseType, subExpenseType });
                      openEditPlannedExpenseModal();
                    },
                  },
                ]
              : []),
            ...(checkPolicies([DELETE_PLANNED_EXPENSE], policies) &&
            (!deletePolicy?.isOfficeSpecific || deletePolicy?.officeIds.some(id => id === office?.id))
              ? [
                  {
                    label: (
                      <>
                        <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
                        <FormattedMessage {...messages.deleteButton} />
                      </>
                    ),
                    itemClassName: 'dropdown__list-item__button',
                    handler: () => {
                      setOfficeMonthClicked({ office, monthData: month, expenseType, subExpenseType });
                      openDeletePlannedExpenseModal();
                    },
                  },
                ]
              : []),
          ],
    [policies, updatePolicy, deletePolicy],
  );

  const tableColumns = useMemo(() => {
    if (tableData?.expenseReportParts?.length) {
      const employeeColumns = [
        {
          id: 'name',
          Header: intl.formatMessage(messages.expensesColumn),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: PlannedExpenseReportItem) =>
                  row.totalItem ? <FormattedMessage {...messages.totalRow} /> : row.expenseType.name,
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: PlannedSubExpense) => row.subExpenseType.name,
              },
              {
                depth: DepthLevels.THIRD,
                content: (row: PlannedExpenseOffice) => row.office.name,
              },
            ]),
        },
      ];

      const monthColumns = tableData?.expenseReportParts[0]?.monthReportParts?.map(
        (data: MonthReportParts, monthIndex: number) => ({
          id: `month-${monthIndex}`,
          Header: (
            <div className="day-display">
              {moment(data.month).format(DATE_FORMAT.MMMM)}
              {(monthIndex === 0 || moment(data.month).startOf('year').isSame(data.month)) && (
                <div className={classNames('year-display')}>{moment(data.month).format(DATE_FORMAT.YYYY)}</div>
              )}
            </div>
          ),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: PlannedExpenseReportItem) => (
                  <span>
                    {formatValue({
                      value: row?.monthReportParts[monthIndex]?.amount.value.toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </span>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: PlannedSubExpense) => (
                  <span>
                    {formatValue({
                      value: row?.monthReportParts[monthIndex]?.amount.value.toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </span>
                ),
              },
              {
                depth: DepthLevels.THIRD,
                content: (row: PlannedExpenseOffice) => (
                  <Dropdown
                    dropdownClass="dropdown--no-bg"
                    dropdownToggle={
                      <span>
                        {row?.monthReportParts[monthIndex]?.amount.value ? (
                          <>
                            {formatValue({
                              value: row?.monthReportParts[monthIndex]?.amount.value?.toString(),
                            })}
                            &nbsp;
                            {baseCurrency?.name}
                          </>
                        ) : (
                          '-'
                        )}
                      </span>
                    }
                    dropdownList={getTableActions(
                      row.monthReportParts[monthIndex],
                      row.office,
                      row.expenseType,
                      row.subExpenseType,
                      row,
                    )}
                    stopPropagation
                  />
                ),
              },
            ]),
        }),
      );

      const totalColumn = [
        {
          id: `total`,
          Header: intl.formatMessage(messages.totalRow),
          Cell: ({ row }: any) =>
            getTableCell(row, [
              {
                depth: DepthLevels.FIRST,
                content: (row: PlannedExpenseReportItem) => (
                  <span>
                    {formatValue({
                      value: row.total.value.toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </span>
                ),
              },
              {
                depth: DepthLevels.SECOND,
                content: (row: PlannedExpenseOffice) => (
                  <span>
                    {formatValue({
                      value: row.total.value.toString(),
                    })}
                    &nbsp;
                    {baseCurrency?.name}
                  </span>
                ),
              },
            ]),
        },
      ];

      return [...employeeColumns, ...monthColumns, ...totalColumn];
    }
    return [];
  }, [tableData]);

  const tableHeaderItems = [
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
    {
      name: '',
      className: ' header_row',
      colspan: tableData?.expenseReportParts[0]?.monthReportParts?.length,
      sortName: '',
    },
    { name: '', className: 'table__head-column header_row', colspan: 1, sortName: '' },
  ];

  return {
    tableColumns,
    tableHeaderItems,
  };
};
