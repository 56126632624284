import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DraggableList from 'react-draggable-list';

import { useFormik } from 'formik';
import get from 'lodash-es/get';

import { RejectValueErrors } from '../../../enums/error.enum';
import { CASH_FLOW_REPORT_SCHEMA, CashFlowSettingFormValues } from '../../../enums/finance/finance.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import Modal from '../../Modal';
import messages from '../messages';
import { ECashFlowTitle } from '../Table/CashFlowTable';
import { CashFlowSettingClass } from '../../../enums/params/cashflow.params';
import Icon from '../../Icon';
import Input from '../../Input';
import Checkbox from '../../Checkbox';
import { Item } from './ModalDraggable';

type ModalEditCashFlowSettingsProps = {
  getExpenseList: (data: { callback: (results: any) => void }) => void;
  getIncomeList: (data: { callback: (results: any) => void }) => void;
  onCloseRequest: () => void;
  editCashFlowSetting: (data: {
    params: { data: CashFlowSettingClass; blockType: ECashFlowTitle };
    callback: () => void;
  }) => void;
  resetSettings: () => void;
  isOpen: boolean;
  data: any;
  loading: boolean;
  error: string | RejectValueErrors[] | null;
  blockType: ECashFlowTitle;
};

function ModalEditCashFlowSettings({
  onCloseRequest,
  editCashFlowSetting,
  resetSettings,
  error,
  data,
  loading,
  isOpen,
  blockType,
  getExpenseList,
  getIncomeList,
}: ModalEditCashFlowSettingsProps) {
  const intl = useIntl();

  const containerRef = useRef(null);

  const onListChange = (newList: any) => {
    setFieldValue('types', newList);
  };

  const [types, setTypes] = useState([]);

  const type =
    blockType === ECashFlowTitle.FIRST_EXPENSE_BLOCK || blockType === ECashFlowTitle.SECOND_EXPENSE_BLOCK
      ? 'EXPENSE'
      : 'INCOME';

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: new CashFlowSettingFormValues({
      name: '',
      showEmptyRecords: false,
      types: [],
    }),
    validateOnChange: false,
    validationSchema: CASH_FLOW_REPORT_SCHEMA,
    onSubmit: values => {
      const data = new CashFlowSettingClass({
        name: values.name,
        showEmptyRecords: values.showEmptyRecords,
        [type === 'INCOME' ? 'cashFlowIncomeTypeBlockSettings' : 'cashFlowExpenseTypeBlockSettings']: values.types.map(
          (item, index) => ({
            [type === 'INCOME' ? 'incomeTypeId' : 'expenseTypeId']: item.value,
            positionNumber: index,
          }),
        ),
      });

      return editCashFlowSetting({ params: { data, blockType }, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    if (type === 'INCOME') {
      getIncomeList({
        callback: results => {
          setTypes(
            results.content.map((item: any) => ({
              value: item.id,
              label: item.name + (item.isDeleted ? ' (deleted)' : ''),
            })),
          );
        },
      });
    } else {
      getExpenseList({
        callback: results => {
          setTypes(
            results.content.map((item: any) => ({
              value: item.id,
              label: item.name + (item.isDeleted ? ' (deleted)' : ''),
            })),
          );
        },
      });
    }
    return () => {
      resetSettings();
    };
  }, []);

  useEffect(() => {
    if (data) {
      setFieldValue('name', data.name);
      setFieldValue('showEmptyRecords', data.showEmptyRecords);
      setFieldValue(
        'types',
        data[type === 'EXPENSE' ? 'expenseTypes' : 'incomeTypes'].map((type: any, index: number) => ({
          value: type.id,
          label: type.name,
          position: index,
        })),
      );
    }
  }, [data]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleTypeChange = (data: any, index: number) => {
    setFieldValue(
      'types',
      values.types.map((type, typeIndex) => (typeIndex === index ? { ...type, ...data } : type)),
    );
  };

  const addNewType = useCallback(() => {
    setFieldValue('types', [...values.types, { position: values.types.length + 1 }]);
  }, [values]);

  const removeType = useCallback(
    index => () => {
      values.types.splice(index, 1);
      setFieldValue('types', values.types);
    },
    [values],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages[type === 'EXPENSE' ? 'expenseModalTitle' : 'incomeModalTitle'])}
        size="small"
        classNameModal={'cash-flow-report-modal center'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages[type === 'EXPENSE' ? 'expenseTypeName' : 'incomeTypeName'])}
                defaultValue={values?.name || ''}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
            <div className="form__input-block">
              <Checkbox
                externalClass="form__checkbox-capitalazie-label"
                onChange={handleChange}
                id="showEmptyRecords"
                name="showEmptyRecords"
                label={intl.formatMessage(messages.showEmptyRecords)}
                checked={values.showEmptyRecords}
                checkedValue={values.showEmptyRecords}
              />
            </div>

            <div ref={containerRef} className="cash-flow-draggable-container">
              <DraggableList
                itemKey="position"
                //@ts-ignore
                template={temp =>
                  Item({
                    item: temp.item,
                    dragHandleProps: temp.dragHandleProps,
                    data: values.types,
                    type,
                    errors,
                    handleTypeChange,
                    types,
                    hasError,
                    removeType,
                  })
                }
                list={values.types}
                onMoveEnd={(newList: any) => onListChange(newList)}
                container={() => containerRef.current}
              />
            </div>

            <button className="form__btn-add-group" onClick={addNewType} type={'button'}>
              <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
              <FormattedMessage {...messages.addButton} />
            </button>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons display-center">
            <Button
              color="gray"
              externalClass="button--modal button--cancel"
              onClick={onCloseRequest}
              type="button"
              block
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass="button--modal" type="submit" loading={loading} disabled={loading} block>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalEditCashFlowSettings;
