import React, { useCallback, useEffect } from 'react';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFormik } from 'formik';
import get from 'lodash-es/get';

import { RejectValueErrors } from '../../../enums/error.enum';
import {
  OfficeBalanceClass,
  OfficeBalanceMonthType,
  OfficeBalanceType,
  OFFICES_BALANCE_SCHEMA,
} from '../../../enums/finance/finance.enum';
import { CurrencyType } from '../../../types/finance';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import Button from '../../Button';
import CurrencyInput from '../../CurrencyInput';
import ErrorMessage from '../../ErrorMessage';
import Modal from '../../Modal';
import messages from '../messages';

type ModalEditOfficeBalanceProps = {
  onCloseRequest: () => void;
  editOfficeBalance: (data: { data: OfficeBalanceClass; callback: () => void }) => void;
  error: string | RejectValueErrors[] | null;
  isLoading: boolean;
  isOpen: boolean;
  officeMonthClicked: {
    office: OfficeBalanceType;
    month: OfficeBalanceMonthType;
  } | null;
  baseCurrency: CurrencyType | undefined;
};

function ModalEditOfficeBalance({
  onCloseRequest,
  editOfficeBalance,
  error,
  isLoading,
  isOpen,
  officeMonthClicked,
  baseCurrency,
}: ModalEditOfficeBalanceProps) {
  const intl = useIntl();

  const { values, errors, touched, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: new OfficeBalanceClass({
      officeId: officeMonthClicked?.office.officeId,
      customBalance: officeMonthClicked?.month.customBalance || officeMonthClicked?.month.automaticBalance,
      balanceDate: officeMonthClicked?.month.balanceDate,
      balanceType: officeMonthClicked?.office.type,
    }),
    validateOnChange: false,
    validationSchema: OFFICES_BALANCE_SCHEMA,
    onSubmit: data => {
      return editOfficeBalance({ data, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    if (officeMonthClicked) {
      setFieldValue(`officeId`, officeMonthClicked.office.officeId);
      setFieldValue(`balanceDate`, officeMonthClicked.month.balanceDate);
      setFieldValue(
        `customBalance`,
        officeMonthClicked.month.customBalance || officeMonthClicked.month.automaticBalance,
      );
      setFieldValue(`balanceType`, officeMonthClicked.office.type);
    }
  }, [officeMonthClicked]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleBalanceChange = useCallback((value: CurrencyInputOnChangeValues) => {
    setFieldValue('customBalance', value.value);
  }, []);

  const resetAutomaticBalance = useCallback(() => {
    setFieldValue('customBalance', officeMonthClicked?.month.automaticBalance);
    setFieldValue('actionType', 'RESET');
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages.newOfficeBalanceTitle)}
        size="small"
        classNameModal={'offices-balance-modal center'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block office-block">{officeMonthClicked?.office.office.name || ''}</div>
            <div className="modal__wrapper">
              <div className="office-input-block form__input-block--half">
                <CurrencyInput
                  label={intl.formatMessage(messages.newValue)}
                  name="customBalance"
                  //@ts-ignore
                  value={values?.customBalance}
                  onChange={handleBalanceChange}
                  hasError={hasError('customBalance') || hasError('customBalance.float')}
                  //@ts-ignore
                  errorMessage={errors?.customBalance?.float || errors?.customBalance}
                  suffix={` ${baseCurrency?.name || ''}`}
                />
                <Button
                  externalClass="reset-button"
                  type="button"
                  isAccentText={true}
                  onClick={resetAutomaticBalance}
                  loading={isLoading}
                >
                  <FormattedMessage {...messages.reset} />
                </Button>
              </div>
              <CurrencyInput
                label={intl.formatMessage(messages.automaticalluCalculatedValue)}
                name="customBalance"
                disabled={true}
                wrapperClass="form__input-block--half"
                //@ts-ignore
                value={officeMonthClicked?.month.automaticBalance || 0}
                suffix={` ${baseCurrency?.name || ''}`}
              />
            </div>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons display-center">
            <Button
              color="gray"
              externalClass="button--modal button--cancel"
              onClick={onCloseRequest}
              type="button"
              block
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass="button--modal" type="submit" loading={isLoading} disabled={isLoading} block>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalEditOfficeBalance;
