import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import { CurrencyType, IncomeType } from '../../../types/finance';
import Button from '../../Button';
import Icon from '../../Icon';
// import ModalEditCashFlowSettings from '../Modals/ModalEditCashFlowSettings';
// import { getMonths } from './utils';
import { EProfitLossTitles } from '../../../pages/ProfitLossReport';
import ModalEditFinancialRevenue from '../Modal/FinancialRevenueModal';
import { getReportMonths } from '../../../utils/finance.utils';
import { useDataForTable } from './useDataForTable';
import HierarchicalTable from '../../HierarchicalTable';
import { useTableData } from '../../../utils/hooks.utils';

type ProfitLossTableProps = {
  title: EProfitLossTitles;
  tableData: any;
  months: any[];
  errors: string | null;
  isLoading: boolean;
  baseCurrency: CurrencyType | undefined;
  settingsData: any;
  incomes: any;
  settingsLoading: boolean;
  settingsError: string | null;
  getIncomeList: () => void;
  getSettings: () => void;
  putSettings: (data: any) => void;
  onCloseSettings: () => void;
};

const FinancialRevenueTable = ({
  title,
  tableData,
  months,
  baseCurrency,
  errors,
  isLoading,
  settingsData,
  incomes,
  settingsError,
  settingsLoading,
  getIncomeList,
  getSettings,
  putSettings,
  onCloseSettings,
}: ProfitLossTableProps) => {
  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const convertedData = useMemo(() => {
    if (tableData) {
      const types = tableData
        ?.map((data: any) => data.incomeType)
        .reduce((item: any, o: any) => {
          if (!item.some((obj: any) => obj.id === o.id)) {
            item.push(o);
          }

          return item;
        }, []);

      if (types?.length) {
        const tableTypes = types.map((type: IncomeType) => {
          const filteredData = tableData?.filter((item: any) => item.incomeTypeId === type.id);

          const offices = filteredData
            ?.map((data: any) => data.office)
            .reduce((item: any, o: any) => {
              if (!item.some((obj: any) => obj?.id === o?.id)) {
                item.push(o);
              }

              return item;
            }, []);

          return {
            id: type.id,
            name: type.name,
            total: filteredData
              .map((it: any) => Number(it.reportAmount))
              .reduce((partialSum: any, a: any) => partialSum + a, 0),
            months: getReportMonths(filteredData, months, 'reportDate', 'reportAmount'),
            subTypes: offices
              .filter((rec: any) => !!rec)
              .map((recipient: any) => {
                const cuttedOffices = filteredData.filter((office: any) => office.officeId === recipient.id);

                return {
                  id: recipient.id,
                  name: recipient.name,
                  total: cuttedOffices
                    .map((it: any) => Number(it.reportAmount))
                    .reduce((partialSum: any, a: any) => partialSum + a, 0),
                  months: getReportMonths(cuttedOffices, months, 'reportDate', 'reportAmount'),
                };
              }),
          };
        });

        tableTypes?.push({
          totalItem: true,
          total: tableData
            .map((it: any) => Number(it.reportAmount))
            .reduce((partialSum: any, a: any) => partialSum + a, 0),
          months: getReportMonths(tableData, months, 'reportDate', 'reportAmount'),
        });

        return tableTypes;
      }
    }
    return [];
  }, [tableData]);

  const openSetting = () => {
    getSettings();
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: any) => {
    putSettings(params);
  };

  const { tableColumns, tableHeaderItems } = useDataForTable(convertedData, baseCurrency, 'incomeColumn');

  return (
    <>
      <div className="cash-flow-report__table-head">
        <h2 className="cash-flow__block-title">
          {settingsData?.name?.length ? settingsData.name : <FormattedMessage {...messages[title]} />}
        </h2>
        <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
          <Icon iconName="pencil" externalClass="button__icon" />
        </Button>
      </div>
      <div className="page__scrollable-table-wrapper cash-flow-report">
        <div className="page__scrollable-table-wrapper__inner cash-flow-report-wrapper">
          <HierarchicalTable
            tableData={useTableData(convertedData, ['subTypes'])}
            tableColumns={tableColumns}
            loading={isLoading}
            error={errors}
            tableHeaderItems={tableHeaderItems}
          />
        </div>
      </div>
      {openSettingsModal && (
        <ModalEditFinancialRevenue
          isOpen={openSettingsModal}
          getIncomeList={getIncomeList}
          incomes={incomes}
          onCloseRequest={closeSetting}
          editSetting={editSetting}
          loading={settingsLoading}
          error={settingsError}
          data={settingsData}
        />
      )}
    </>
  );
};

export default FinancialRevenueTable;
