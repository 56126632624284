import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';

import * as financeActions from '../../actions/finance.actions';
import AccessChecker from '../../components/AccessChecker';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import ExpenseTypesFilters from '../../components/ExpenseTypes/Filters/ExpenseTypesFilters';
import ModalDeleteExpenseType from '../../components/ExpenseTypes/Modals/ModalDeleteExpenseType';
import ModalEditExpenseType from '../../components/ExpenseTypes/Modals/ModalEditExpenseType';
import ModalNewExpenseType from '../../components/ExpenseTypes/Modals/ModalNewExpenseType';
import HierarchicalTable from '../../components/HierarchicalTable';
import Icon from '../../components/Icon';
import { DELETE_EXPENSE_TYPE, UPDATE_EXPENSE_TYPE } from '../../constants/policies.constants';
import { DepthLevels } from '../../constants/tables.constants';
import { ExpenseTypesParams } from '../../enums/params/finance.params';
import { ExpenseType } from '../../types/finance';
import { useTableData } from '../../utils/hooks.utils';
import { getTableCell } from '../../utils/table.utils';
import messages from './messages';

function ExpenseTypes({
  tableData,
  isLoading,
  errors,
  getExpenseTypesList,
  createExpenseType,
  editExpenseType,
  deleteExpenseType,
  resetState,
  resetErrors,
  setExpenseTypesParams,
  sortParams,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewExpenseTypeIsOpen, setModalNewExpenseTypeIsOpen] = useState(false);
  const [modalEditExpenseTypeIsOpen, setModalEditExpenseTypeIsOpen] = useState(false);
  const [modalDeleteExpenseTypeIsOpen, setModalDeleteExpenseTypeIsOpen] = useState(false);
  const [expenseTypeClicked, setExpenseTypeClicked] = useState<ExpenseType>({
    name: '',
    id: '',
    subExpenseTypes: [
      {
        id: '',
        name: '',
        isPersonal: false,
      },
    ],
  });

  const handleSort = useCallback((sortBy, direction) => setExpenseTypesParams({ sortBy, direction }), []);

  useEffect(() => {
    getExpenseTypesList();
    return () => {
      resetState();
    };
  }, []);

  const openNewExpenseTypeModal = useCallback(() => {
    setModalNewExpenseTypeIsOpen(true);
  }, []);

  const closeNewExpenseTypeeModal = useCallback(() => {
    setModalNewExpenseTypeIsOpen(false);
    resetErrors();
  }, []);

  const closeEditExpenseModal = useCallback(() => {
    setModalEditExpenseTypeIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteExpenseModal = useCallback(() => {
    setModalDeleteExpenseTypeIsOpen(false);
    resetErrors();
  }, []);

  const openExpenseTypeModal = useCallback((expenseType: ExpenseType) => {
    setExpenseTypeClicked(expenseType);
  }, []);

  const tableActions: any = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ExpenseType) => {
          setExpenseTypeClicked(row);
          setModalEditExpenseTypeIsOpen(true);
        },
        verifiablePolicies: [UPDATE_EXPENSE_TYPE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ExpenseType) => {
          setExpenseTypeClicked(row);
          setModalDeleteExpenseTypeIsOpen(true);
        },
        verifiablePolicies: [DELETE_EXPENSE_TYPE],
      },
    ],
    [],
  );

  const tableColumns = useMemo(
    () => [
      {
        id: 'expenseTitle',
        Header: intl.formatMessage(messages.expenseTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ExpenseType) => <span onClick={() => openExpenseTypeModal(row)}>{row.name}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: { name: string }) => (
                <div className={'table__data-wrapper'}>
                  <span>{row.name}</span>
                </div>
              ),
            },
          ]),
      },
      {
        id: 'companyLabel',
        Header: intl.formatMessage(messages.companyLabel),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.SECOND,
              content: (row: { isPersonal: boolean }) => (
                <div className={'table__data-wrapper'}>
                  <span>
                    {row.isPersonal
                      ? intl.formatMessage(messages.personalLabel)
                      : intl.formatMessage(messages.companyLabel)}
                  </span>
                </div>
              ),
            },
          ]),
      },
      {
        Header: '',
        id: 'dropdown',
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ExpenseType, index: number) => (
                <div className="table__dropdown-wrapper">
                  <Dropdown
                    dropdownClass="dropdown--no-bg"
                    dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                    dropdownList={tableActions}
                    dropdownInfo={row}
                    dropdownIndex={index}
                    stopPropagation
                  />
                </div>
              ),
            },
          ]),
      },
    ],
    [tableActions],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_EXPENSE_TYPE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewExpenseTypeModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <ExpenseTypesFilters values={sortParams} handleSort={handleSort} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content active_employees_page">
        <div className="page__wrapper">
          <HierarchicalTable
            tableData={useTableData(tableData, ['subExpenseTypes'])}
            tableColumns={tableColumns}
            loading={isLoading.getExpenseTypesList}
            error={errors.expenseTypesError}
            externalClass="table--bordered-top table--half"
          />
        </div>
      </div>
      {modalNewExpenseTypeIsOpen && (
        <ModalNewExpenseType
          isOpen
          onCloseRequest={closeNewExpenseTypeeModal}
          createExpenseType={createExpenseType}
          error={errors.expenseTypesError}
          isLoading={isLoading.createExpenseType}
        />
      )}
      {modalEditExpenseTypeIsOpen && (
        <ModalEditExpenseType
          isOpen
          onCloseRequest={closeEditExpenseModal}
          editExpenseType={editExpenseType}
          isLoading={isLoading.editExpenseType}
          error={errors.expenseTypesError}
          expenseType={expenseTypeClicked}
        />
      )}
      {modalDeleteExpenseTypeIsOpen && (
        <ModalDeleteExpenseType
          isOpen
          onCloseRequest={closeDeleteExpenseModal}
          onDeleteRequest={deleteExpenseType}
          isLoading={isLoading.deleteExpenseType}
          error={errors.expenseTypesError}
          expenseType={expenseTypeClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance }: RootState) => ({
  errors: finance.errors,
  isLoading: finance.loading,
  tableData: finance.expenseTypesListData?.content,
  sortParams: finance.expenseTypesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getExpenseTypesList: () => dispatch(financeActions.getExpenseTypesList()),
  createExpenseType: (data: { data: ExpenseType; callback: () => void }) =>
    dispatch(financeActions.createExpenseType(data)),
  editExpenseType: (data: { data: ExpenseType; callback: () => void }) =>
    dispatch(financeActions.editExpenseType(data)),
  deleteExpenseType: (data: { id: string; callback: () => void }) => dispatch(financeActions.deleteExpenseType(data)),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  setExpenseTypesParams: (params: Partial<ExpenseTypesParams>) =>
    dispatch(financeActions.setExpenseTypesParams(params)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ExpenseTypes);
