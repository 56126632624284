import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import get from 'lodash-es/get';
import Button from '../../Button';
import Modal from '../../Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { RejectValueErrors } from '../../../enums/error.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import ErrorMessage from '../../ErrorMessage';
import {
  CurrencyFormatter,
  EmployeeHours,
  FinancePlan,
  FinancePlanUser,
  PLAN_USER_SCHEMA,
} from '../../../enums/finance/finance.enum';
import Select from '../../Select';
import { formatValue } from 'react-currency-input-field';
import { CurrencyType, EmployeeHoursPlanLoadType } from '../../../types/finance';
import CurrencyInput from '../../CurrencyInput';
import { isNil } from 'lodash-es';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import CustomLoader from '../../Loader';
import classNames from 'classnames';
import { scrollToError } from '../../../utils';

type ModalEditEmployeeProps = {
  onCloseRequest: () => void;
  updateUser: (data: EmployeeHours & { currentLoad: EmployeeHoursPlanLoadType }) => void;
  error: string | RejectValueErrors[] | null;
  baseCurrency: CurrencyType | undefined;
  isLoading: boolean;
  loadingUserHours: boolean;
  isOpen: boolean;
  getEmployeeHours: (id: string, month: string) => void;
  userHours: EmployeeHours;
  planId: string;
  userHoursError: string;
  planUser: FinancePlanUser;
  currentPlan: FinancePlan;
};

function ModalEditEmployee({
  onCloseRequest,
  updateUser,
  error,
  isLoading,
  baseCurrency,
  loadingUserHours,
  isOpen,
  getEmployeeHours,
  userHours,
  userHoursError,
  planUser,
  currentPlan,
  planId,
}: ModalEditEmployeeProps) {
  const intl = useIntl();

  const [plannedIncome, setPlannedIncome] = useState('0');
  const [finalizedIncome, setFinalizedIncome] = useState('0');
  const [changed, setChanged] = useState({ rateFinalized: false, workHoursFinalized: false });
  const [freeHoursPercent, setFreeHoursPercent] = useState(100);

  const { values, errors, touched, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      ...userHours,
      planLoads: userHours.planLoads.filter(el => el.financePlanId !== planId),
      currentLoad: userHours.planLoads.find(el => el.financePlanId === planId) || {
        rate: new CurrencyFormatter(planUser.rate),
        rateFinalized: new CurrencyFormatter({
          float: Number(planUser.rateFinalized),
          value: planUser.rateFinalized || '',
        }),
        workHours: new CurrencyFormatter({
          float: Number(planUser.workHours),
          value: planUser.workHours ? planUser.workHours : '',
        }),
        hoursPercent: new CurrencyFormatter({ float: planUser.hoursPercent, value: planUser.hoursPercent.toString() }),
        financePlanId: currentPlan.id,
        financePlan: currentPlan,
        workHoursActual: new CurrencyFormatter({
          float: Number(planUser.workHoursActual),
          value: planUser.workHoursActual ? planUser.workHoursActual : '',
        }),
        workHoursFinalized: new CurrencyFormatter({
          float: Number(planUser.workHoursFinalized),
          value: planUser.workHoursFinalized ? planUser.workHoursFinalized : '',
        }),
      },
    },
    enableReinitialize: true,
    validate: scrollToError,
    validateOnChange: false,
    validationSchema: PLAN_USER_SCHEMA,
    onSubmit: data => {
      const changedLoad = data.currentLoad;
      if (!changed.workHoursFinalized && !changedLoad.workHoursFinalized?.value?.length) {
        delete changedLoad.workHoursFinalized;
      }

      if (!changed.rateFinalized && !changedLoad.rateFinalized?.value?.length) {
        if (!changed.workHoursFinalized && !changedLoad.workHoursFinalized?.value?.length) {
          delete changedLoad.rateFinalized;
        } else {
          changedLoad.rateFinalized = changedLoad.rate;
        }
      }

      return updateUser({ ...data, currentLoad: changedLoad });
    },
  });

  useEffect(() => {
    const plannedIncome = ((values.currentLoad.workHours.float || 0) * (values.currentLoad.rate.float || 0)).toFixed(2);
    setPlannedIncome(isNaN(Number(plannedIncome)) ? '0' : plannedIncome);
  }, [values.currentLoad.workHours, values.currentLoad.rate]);

  useEffect(() => {
    const finalizedIncome = (
      (values.currentLoad.workHoursFinalized?.float || 0) *
      (values.currentLoad.rateFinalized?.float || values.currentLoad.rate?.float || 0)
    ).toFixed(2);

    setFinalizedIncome(isNaN(Number(finalizedIncome)) ? '0' : finalizedIncome);
  }, [values.currentLoad.workHoursFinalized, values.currentLoad.rateFinalized, values.currentLoad.rate]);

  useEffect(() => {
    const percentOfOtherProjects = values.planLoads.reduce((acc, curr) => acc + (curr.hoursPercent.float || 0), 0);
    const busyWorkHours = (
      ((percentOfOtherProjects + (values.currentLoad.hoursPercent.float || 0)) * +userHours.allWorkHours) /
      100
    ).toFixed(2);
    setFreeHoursPercent(
      Number.parseFloat(
        (100 - (values.currentLoad.hoursPercent.float || 0) - percentOfOtherProjects).toFixed(2).toString(),
      ),
    );
    setFieldValue('busyWorkHours', busyWorkHours);
  }, [values.currentLoad.hoursPercent, values.planLoads, userHours.allWorkHours]);

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    if (currentPlan.date && planUser.userId) getEmployeeHours(planUser.userId, currentPlan.date);
  }, [currentPlan.date, planUser.userId]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleChangeWorkHours = useCallback(
    (inputValue: CurrencyInputOnChangeValues) => {
      const value = inputValue.float;
      if (!isNil(value)) {
        const maxWorkHours =
          ((freeHoursPercent + (values.currentLoad.hoursPercent.float || 0)) * +userHours.allWorkHours) / 100;
        const isCurrentValueAvailable = value <= maxWorkHours;
        const newValue = Number((isCurrentValueAvailable ? value : maxWorkHours).toFixed(2));
        const formatter = new CurrencyFormatter({
          float: newValue,
          value: isCurrentValueAvailable ? inputValue.value : maxWorkHours.toFixed(2),
        });
        setFieldValue('currentLoad.workHours', formatter);
        const hoursPercent = ((newValue * 100) / +userHours.allWorkHours).toFixed(2);
        setFieldValue(
          'currentLoad.hoursPercent',
          new CurrencyFormatter({
            float: Number(hoursPercent),
            value: hoursPercent,
          }),
        );
      } else {
        setFieldValue(
          'currentLoad.workHours',
          new CurrencyFormatter({
            float: null,
          }),
        );
        setFieldValue(
          'currentLoad.hoursPercent',
          new CurrencyFormatter({
            float: null,
          }),
        );
      }
    },
    [userHours.allWorkHours, values.currentLoad.hoursPercent, freeHoursPercent],
  );

  const handleChangeWorkFinalizedHours = (inputValue: CurrencyInputOnChangeValues) => {
    const value = inputValue.float;
    if (!isNil(value)) {
      const formatter = new CurrencyFormatter({
        float: inputValue.float,
        value: inputValue.value,
      });
      setChanged(prev => ({ ...prev, workHoursFinalized: true }));
      setFieldValue('currentLoad.workHoursFinalized', formatter);
    } else {
      setChanged(prev => ({ ...prev, workHoursFinalized: false }));
      setFieldValue(
        'currentLoad.workHoursFinalized',
        new CurrencyFormatter({
          float: null,
        }),
      );
    }
  };

  const workOnOtherProjectPercent = useMemo(
    () => values.planLoads.reduce((acc, curr) => acc + Number(curr.hoursPercent.float), 0).toFixed(2),
    [values.planLoads],
  );

  const workOnOtherProjectWorkHours = ((+workOnOtherProjectPercent * +userHours.allWorkHours) / 100).toFixed(2);

  const getHoursValue = (
    currentPercent: number,
    inputValue: CurrencyInputOnChangeValues,
    defaultInputValue?: number,
  ) => {
    const value = inputValue.float || defaultInputValue;
    if (!isNil(value)) {
      const maxPercent = freeHoursPercent + currentPercent;
      const isCurrentValueAvailable = value <= maxPercent;
      const newValue = Number((isCurrentValueAvailable ? value : maxPercent).toFixed(2));
      const formatter = new CurrencyFormatter({
        float: newValue,
        value: isCurrentValueAvailable ? inputValue.value : maxPercent.toFixed(2),
      });
      const workHoursValue = ((newValue * +userHours.allWorkHours) / 100).toFixed(2);
      const workHours = new CurrencyFormatter({
        float: Number(workHoursValue),
        value: workHoursValue,
      });
      return { formatter, workHours };
    } else {
      return { formatter: new CurrencyFormatter({ float: null }), workHours: new CurrencyFormatter({ float: null }) };
    }
  };

  const handleChangePercent = useCallback(
    (inputValue: CurrencyInputOnChangeValues) => {
      const { formatter, workHours } = getHoursValue(values.currentLoad.hoursPercent.float || 0, inputValue);
      setFieldValue('currentLoad.hoursPercent', formatter);
      setFieldValue('currentLoad.workHours', workHours);
    },
    [values, freeHoursPercent],
  );

  const handleChangeRate = useCallback(item => {
    const rate = new CurrencyFormatter({
      ...item,
      float: item.float,
    });
    setFieldValue('currentLoad.rate', rate);
  }, []);

  const handleChangeRateFinalized = useCallback(
    item => {
      const rate = new CurrencyFormatter({
        ...item,
        float: item.float,
      });

      setChanged(prev => ({ ...prev, rateFinalized: true }));
      setFieldValue('currentLoad.rateFinalized', rate);
    },
    [changed],
  );

  const handleChangePercenOtherPlans = useCallback(
    (inputValue: CurrencyInputOnChangeValues, index?: number) => {
      if (!isNil(index)) {
        const currentPercent = values.planLoads[index].hoursPercent.float || 0;
        const { formatter, workHours } = getHoursValue(currentPercent, inputValue, 0);
        setFieldValue(`planLoads[${index}.hoursPercent`, formatter);
        setFieldValue(`planLoads[${index}.workHours`, workHours);
      }
    },
    [freeHoursPercent, values.planLoads],
  );

  const availableLabelClassName = classNames('available-label form__inputs-subwrapper', {
    'hidden-label': !freeHoursPercent,
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onCloseRequest} title={intl.formatMessage(messages.editMemberTitle)}>
      <form className="modal__form edit-user-plan__modal form" onSubmit={handleSubmit}>
        <div className="form__inputs-wrapper">
          <div className="form__input-block">
            <Select
              label={intl.formatMessage(messages.memberLabel)}
              options={[]}
              hasError={hasError('userId')}
              errorMessage={errors?.userId}
              value={{
                value: values.userId,
                label: values.user.fullName,
              }}
              isDisabled
            />
            {!loadingUserHours ? (
              <>
                <div className="user-hours-wrapper">
                  <div className="user-hours-wrapper-flex">
                    <div className="user-hours-block">
                      <div className="user-hours-title">
                        <FormattedMessage {...messages.regulatoryHoursLabel} />
                      </div>
                      <div>{userHours?.allWorkHours && `${userHours?.allWorkHours}h`}</div>
                    </div>
                    <div className="user-hours-block">
                      <div className="user-hours-title">
                        <FormattedMessage {...messages.hoursOnOtherProjectsLabel} />
                      </div>
                      <div>
                        {userHours?.busyWorkHours && `${workOnOtherProjectWorkHours}h/${workOnOtherProjectPercent}%`}
                      </div>
                    </div>
                  </div>
                  <ErrorMessage>{userHoursError}</ErrorMessage>
                </div>
                <div className="form__inputs-subwrapper">
                  <CurrencyInput
                    name="rate"
                    label={intl.formatMessage(messages.hourPriceLabel)}
                    value={values.currentLoad.rate.value}
                    onChange={handleChangeRate}
                    //@ts-ignore
                    errorMessage={errors?.currentLoad?.rate?.float || errors?.rate}
                    hasError={hasError('rate')}
                    wrapperClass="form__input-block--third"
                    suffix={` ${baseCurrency?.name}`}
                  />
                  <CurrencyInput
                    name={'workHours'}
                    label={intl.formatMessage(messages.plannedHoursLabel)}
                    value={values.currentLoad.workHours.value}
                    decimalsLimit={2}
                    hasError={hasError('workHours') || hasError('currentLoad.workHours.float')}
                    //@ts-ignore
                    errorMessage={errors.workHours || errors?.currentLoad?.workHours?.float}
                    onChange={handleChangeWorkHours}
                    wrapperClass="form__input-block--third"
                  />
                  <CurrencyInput
                    name={'hoursPercent'}
                    label={intl.formatMessage(messages.percentOfRegulatoryColumn)}
                    value={values.currentLoad.hoursPercent.value}
                    decimalsLimit={2}
                    hasError={hasError('hoursPercent') || hasError('currentLoad.hoursPercent.float')}
                    //@ts-ignore
                    errorMessage={errors.hoursPercent || errors?.currentLoad?.hoursPercent?.float}
                    onChange={handleChangePercent}
                    wrapperClass="form__input-block--third"
                  />
                </div>
                <div className="user-hours-wrapper-flex">
                  <div className="user-hours-block">
                    <div className="user-hours-title">
                      <FormattedMessage {...messages.plannedIncomeLabel} />
                    </div>
                    <div>
                      {`${
                        formatValue({
                          value: plannedIncome?.toString(),
                        }) || 0
                      } ${baseCurrency?.name}`}
                    </div>
                  </div>
                </div>
                <div className="other-project-wrapper">
                  {values.planLoads.map((planLoad, index) => {
                    return (
                      <div key={planLoad.financePlanId} className="form__inputs-subwrapper">
                        <div className="form__input-block form__input-block--third other-project-name">
                          {planLoad.financePlan.financeProject.name}
                        </div>
                        <CurrencyInput
                          id={`planLoads[${index}.hoursPercent]`}
                          index={index}
                          name={`planLoads[${index}.hoursPercent]`}
                          label={intl.formatMessage(messages.percentOfRegulatoryColumn)}
                          value={planLoad.hoursPercent.value}
                          decimalsLimit={2}
                          hasError={hasError(`hoursPercent`)}
                          //@ts-ignore
                          errorMessage={errors.hoursPercent}
                          onChange={handleChangePercenOtherPlans}
                          wrapperClass="form__input-block--third"
                        />
                      </div>
                    );
                  })}
                  {
                    <div className={availableLabelClassName}>
                      <div className="form__input-block form__input-block--third">
                        <FormattedMessage {...messages.availableLabel} />
                      </div>
                      <div className="form__input-block form__input-block--third">{freeHoursPercent}%</div>
                    </div>
                  }
                </div>
                <div className="modal-finalized-title">{intl.formatMessage(messages.finalizedTitle)}</div>
                <div className="form__inputs-subwrapper">
                  <CurrencyInput
                    name="rateFinalized"
                    label={intl.formatMessage(messages.hourPriceLabel)}
                    value={
                      values.currentLoad.rateFinalized?.value?.length || changed.rateFinalized
                        ? values.currentLoad.rateFinalized?.value
                        : values.currentLoad.rate.value
                    }
                    onChange={handleChangeRateFinalized}
                    //@ts-ignore
                    errorMessage={errors?.currentLoad?.rateFinalized?.float || errors?.rateFinalized}
                    hasError={hasError('rateFinalized')}
                    wrapperClass="form__input-block--third"
                    suffix={` ${baseCurrency?.name}`}
                  />
                  <CurrencyInput
                    name={'workHoursActual'}
                    label={intl.formatMessage(messages.actualHoursColumn)}
                    value={values.currentLoad.workHoursActual.value}
                    decimalsLimit={2}
                    hasError={hasError('workHoursActual') || hasError('currentLoad.workHoursActual.float')}
                    //@ts-ignore
                    errorMessage={errors.workHoursActual || errors?.currentLoad?.workHoursActual?.float}
                    disabled={true}
                    wrapperClass="form__input-block--third"
                  />
                  <CurrencyInput
                    name={'workHoursFinalized'}
                    label={intl.formatMessage(messages.finalizeHours)}
                    value={
                      values.currentLoad.workHoursFinalized?.value?.length || changed.workHoursFinalized
                        ? values.currentLoad.workHoursFinalized?.value
                        : values.currentLoad.workHoursActual.value
                    }
                    decimalsLimit={2}
                    hasError={hasError('workHoursFinalized') || hasError('currentLoad.workHoursFinalized.float')}
                    //@ts-ignore
                    errorMessage={errors.workHoursFinalized || errors?.currentLoad?.workHoursFinalized?.float}
                    onChange={handleChangeWorkFinalizedHours}
                    wrapperClass="form__input-block--third"
                  />
                </div>
                <div className="user-hours-wrapper-flex">
                  <div className="user-hours-block">
                    <div className="user-hours-title">
                      <FormattedMessage {...messages.finalizedIncome} />
                    </div>
                    <div>
                      {`${
                        formatValue({
                          value: finalizedIncome?.toString(),
                        }) || 0
                      } ${baseCurrency?.name}`}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <CustomLoader />
            )}
          </div>
        </div>
        <ErrorMessage>{error}</ErrorMessage>
        <div className="form__buttons">
          <Button
            color={'gray'}
            externalClass={'button--modal button--cancel'}
            type={'button'}
            onClick={onCloseRequest}
          >
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            externalClass={'button--modal'}
            type={'submit'}
            loading={isLoading}
            disabled={isLoading || loadingUserHours}
          >
            <FormattedMessage {...messages.saveButton} />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalEditEmployee;
